import React, { useEffect, useState } from "react";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {
  Table,
  Card,
  CardBody,
  Button
} from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import config from '../../../../config/index';
import ReactPaginate from "react-paginate";
import { Toast, showError, showSuccess } from "../../../../shared/components/Toast/Toast";

var pending_page = [];

const PendingList = (props) => {
  const [pendingList, setPendingList] = useState([]);
  let history = useHistory();
  let userInfo = localStorage.getItem("token");
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // ===============================================================

  const [PendingUserID, setPendingUserID] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);


  useEffect(() => {
    viewTable()
  }, [])


  const viewTable = () => {
    try {
      var confi = {
        method: 'get',
        url: `${config.apiBaseUrl}get/pending_list`,
      };
      axios(confi)
        .then((response) => {
          const endOffset = itemOffset + itemsPerPage;
          setPendingList(response?.data.Data?.slice(itemOffset, endOffset));
          setPageCount(Math.ceil(response?.data.Data.length / itemsPerPage))
          setPendingUserID(response?.data?.Data[0]?.id);
          pending_page = response?.data.Data
        })
    } catch (error) {
      console.log(error);
    }
  }



  const chakeStatus = (e) => {

    if (e.target.checked === true) {
      var data = JSON.stringify({
        "id": e.target.id
      });
      var con = {
        method: 'post',
        url: `${config.apiBaseUrl}post/enable_user`,
        // url: 'http://192.168.2.131:7000/api/post/enable_user',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + userInfo
        },
        data: data
      };
      axios(con)
        .then((response) => {
          // console.log(response.data)
          if (response.data.status === 1) {
            viewTable()
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    } else if (e.target.checked === false) {
      var Userdata = JSON.stringify({
        "id": e.target.id
      });
      var conf = {
        method: 'post',
        url: `${config.apiBaseUrl}/post/disable_user`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + userInfo
        },
        data: Userdata
      };
      axios(conf)
        .then((response) => {
          if (response.data.status === 0) {
            viewTable()
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }

  const handleRoles = (val) => {
    // =================================================
    try {
      var data = JSON.stringify({
        "id": val?.id
      });

      var conf = {
        method: 'post',
        url: `${config.apiBaseUrl}post/update_user_approve`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios(conf)
        .then((response) => {
          history.push({
            pathname: '/user_management/create',
            state: { val },
            search: "?" + new URLSearchParams({ id: val.id }).toString()
          })
        })
    } catch (error) {
      console.log(error);
    }

    // =================================================

  }

  // console.log("List",pendingList);
  // ===================================================

  // const [pageNumber, setPageNumber] = useState(0);
  // const usersPerPage = 10;
  // const pagesVisited = pageNumber * usersPerPage;
  // const pageCount = Math.ceil(pendingList.length / usersPerPage);

  // const displayUsers = pendingList.slice(pagesVisited, pagesVisited + usersPerPage)
  // // .map((pendingList) => {
  // //   return (
  // //     <>
  // //       {pendingList?.length === 0 ? <>

  // //         <tbody>
  // //           <tr>
  // //             <td colSpan="9" style={{ textAlign: "center" }}><h2>No Data Found</h2></td>
  // //           </tr>
  // //         </tbody>

  // //       </> : pendingList?.length && pendingList.map((val, index) =>
  // //         <tbody key={index}>
  // //           <tr>
  // //             <td style={{ textAlign: "center", width: "10%" }}>{val.first_name}</td>
  // //             <td style={{ textAlign: "center", width: "10%" }}>{val.last_name}</td>
  // //             <td style={{ textAlign: "center", width: "14%" }}>{val.email}</td>
  // //             <td style={{ textAlign: "center", width: "12%" }}>{val.mobile}</td>
  // //             <td style={{ textAlign: "center", width: "16%" }}>
  // //               <Checkbox color={val.is_active === 1 ? 'success' : 'default'} defaultChecked={val.is_active === 1 ? true : false} id={val.id} onChange={chakeStatus} />
  // //               {val.is_active === 1 ? <><span style={{ color: "green" }}>Active</span></> : <><span style={{ color: "red" }}>Disabled</span></>}
  // //             </td>
  // //             <td style={{ width: "32%" }}>
  // //               <Button outline color="success" className="my-auto" size="sm" onClick={() => handleRoles(val)} >Approve & Assing Roles</Button>
  // //             </td>
  // //             <td style={{ textAlign: "center" }}>
  // //               <DeleteIcon color="red" size={24} />
  // //             </td>
  // //           </tr>
  // //         </tbody>
  // //       )}
  // //     </>
  // //   );
  // // }
  // // );

  // const changePage = ({ selected }) => {
  //   setPageNumber(selected);
  // };

  // Get current posts

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setPendingList(pending_page.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(pending_page.length / itemsPerPage))
  }, [itemOffset, itemsPerPage]);

  const changePage = (data) => {
    const newOffset = (data.selected * itemsPerPage) % pending_page.length;
    setItemOffset(newOffset);
  };





  return (
    <>
      <div className="btn-group--icons d-flex align-items-center">
        <IconButton aria-label="backward">
          <ArrowLeftIcon onClick={() => history.goBack()} />
        </IconButton>
        <h3 className="ml-2 font-weight-bold">{`USER MANAGEMENT > PENDING REQUEST`}</h3>
      </div>
      <div>
        <Card>
          <CardBody>
            <Table responsive hover className="table--bordered">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>First Name</th>
                  <th style={{ textAlign: "center" }}>Last Name</th>
                  <th style={{ textAlign: "center" }}>Email ID</th>
                  <th style={{ textAlign: "center" }}>Mobile No.</th>
                  {/* <th style={{ textAlign: "center" }}>Status</th> */}

                  {/* <th style={{ textAlign: "end" }}>Actions</th> */}
                  <th></th>
                </tr>
              </thead>
              {pendingList?.length == 0 ? <>

                <tbody>
                  <tr>
                    <td colSpan="9" style={{ textAlign: "center" }}><h2>No Data Found</h2></td>
                  </tr>
                </tbody>

              </> : pendingList?.length && pendingList?.map((val, index) =>
                <tbody key={index}>
                  <tr>
                    <td style={{ textAlign: "center", width: "10%" }}>{val?.first_name}</td>
                    <td style={{ textAlign: "center", width: "10%" }}>{val?.last_name}</td>
                    <td style={{ textAlign: "center", width: "14%" }}>{val?.email}</td>
                    <td style={{ textAlign: "center", width: "12%" }}>{val?.mobile}</td>
                    {/* <td style={{ textAlign: "center", width: "16%" }}>
                      <Checkbox color={val.is_active === 1 ? 'success' : 'default'} defaultChecked={val.is_active === 1 ? true : false} id={val.id} onChange={chakeStatus} />
                      {val.is_active === 1 ? <><span style={{ color: "green" }}>Active</span></> : <><span style={{ color: "red" }}>Disabled</span></>}
                    </td> */}
                    <td style={{ width: "32%", textAlign: "left" }}>
                      <Button outline color="success" className="my-auto" size="sm" onClick={() => handleRoles(val)} >Approve & Assing Roles</Button>
                    </td>
                    {/* <td style={{ textAlign: "left" }}>
                      <DeleteIcon color="red" size={24} />
                    </td> */}
                  </tr>
                </tbody>
              )}
              {/* {displayUsers} */}
            </Table>
          </CardBody>

        </Card>
        {/* ================================== */}

        <ReactPaginate
          previousLabel={"<<"}
          nextLabel={">>"}
          pageCount={pageCount}
          onPageChange={changePage}
          containerClassName={"pagination justify-content-center"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-link"}
          previousLinkClassName={"page-item"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
        />
        {/* ==================================== */}

      </div>
      <Toast />
    </>
  );
};

export default PendingList;
