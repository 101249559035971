import React, { useState } from 'react';
import {
    Nav, NavItem, NavLink, TabContent, TabPane,
    Card, CardBody, Row, Container
} from 'reactstrap';
import classnames from 'classnames';
import NewCard from './NewCard';
import "./ProposalList.css";
import AllProposalCard from './AllProposalCard';
import RejectedCard from './RejectedCard';

const ProposalTabs = () => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };



    const returnContent = () => {
        return (
            <Container className="containerr">
                <Row style={{ width: "100%", margin:"-24px auto" }}>
                { activeTab === "1" ?   
                   <AllProposalCard/>  :  activeTab === "2" ? <NewCard/> : activeTab === "3" ? <RejectedCard/> : null}
                </Row>
            </Container>
        )

    }

    return (
        <div className="tabs__wrap">
            <Nav tabs>
                <NavItem className="Nav_item">
                    <NavLink
                        className={classnames({ active: activeTab === '1' })}
                        onClick={() => 
                            toggle('1')
                            
                        }
                    >
                       ALL
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '2' })}
                        onClick={() => toggle('2')}
                    >
                        NEW
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '3' })}
                        onClick={() => toggle('3')}
                    >
                        REJECTED
                    </NavLink>
                </NavItem>
                {/* <NavItem>
                    <NavLink
                        className={classnames({ active: activeTab === '4' })}
                        onClick={() => toggle('4')}
                    >
                        ALL
                    </NavLink>
                </NavItem> */}
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Card>
                        <CardBody>
                            {returnContent()}

                        </CardBody>
                    </Card>
                </TabPane>
                <TabPane tabId="2">
                    <Card>
                        <CardBody>
                            {returnContent()}
                        </CardBody>
                    </Card>
                </TabPane>
                <TabPane tabId="3">
                    <Card>
                        <CardBody>
                            {returnContent()}
                        </CardBody>
                    </Card>
                </TabPane>
                <TabPane tabId="4">
                    <Card>
                        <CardBody>
                            {returnContent()}
                        </CardBody>
                    </Card>
                </TabPane>
                <TabPane tabId="5">
                    <Card>
                        <CardBody>
                            {returnContent()}
                        </CardBody>
                    </Card>
                </TabPane>
                <TabPane tabId="6">
                <Card>
                    <CardBody>
                        {returnContent()}
                    </CardBody>
                </Card>
            </TabPane>
            <TabPane tabId="7">
            <Card>
                <CardBody>
                    {returnContent()}
                </CardBody>
            </Card>
        </TabPane>
        <TabPane tabId="8">
        <Card>
            <CardBody>
                {returnContent()}
            </CardBody>
        </Card>
    </TabPane>
            </TabContent>
        </div>
    );
};

export default ProposalTabs;
