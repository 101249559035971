import React, { useState } from 'react';
import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import { UserProps, AuthOProps } from '@/shared/prop-types/ReducerProps';
import { hookAuth0 } from '@/shared/components/auth/withAuth0';
import TopbarMenuLink from './TopbarMenuLink';
import jwt_decode from "jwt-decode";
import UserData from '../../../config/UserInfo';
import DefaultUserImg from "../../../assets/userimg.png";

const Ava = `${process.env.PUBLIC_URL}/img/ava.png`;

const TopbarProfile = ({ auth0, user }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  let userInfo = localStorage.getItem("token");
  var JWTtoken = userInfo;
  var decoded = jwt_decode(JWTtoken);
  let userFullInfo = (decoded);
  let allInfo = [userFullInfo];
  let data = allInfo[0].id

  // console.log("user:-",UserData);

  const toggleProfile = () => {
    setIsCollapsed(!isCollapsed);
  };

  const logout = () => {
    // localStorage.removeItem('easydev');

    localStorage.clear();

  };

  // console.log(localStorage.getItem());
  var getimage = JSON.parse(localStorage.getItem('authuser'));
  console.log("Image========>", getimage?.Image);



  return (
    <div className="topbar__profile">
      <button className="topbar__avatar" type="button" onClick={toggleProfile}>
        <img
          className="topbar__avatar-img"
          // src={(auth0.user && auth0.user.picture) || (user && user.avatar) || Ava}
          src={getimage?.Image ? getimage?.Image : DefaultUserImg}
          alt="avatar"
        />
        <p className="topbar__avatar-name">

          {/* auth0.loading ? 'Loading...' : ("User Name") || ("User Name") */}
          {data?.first_name} {data?.last_name}
        </p>
        <DownIcon className="topbar__icon" />
      </button>
      {isCollapsed && (
        <button
          className="topbar__back"
          type="button"
          aria-label="profile button"
          onClick={toggleProfile}
        />
      )}
      <Collapse isOpen={isCollapsed} className="topbar__menu-wrap">
        <div className="topbar__menu">
          {/*  <TopbarMenuLink
            title="My Profile"
            icon="user"
            path="/account/profile"
            onClick={toggleProfile}
          />
          <TopbarMenuLink
            title="Calendar"
            icon="calendar-full"
            path="/default_pages/calendar"
            onClick={toggleProfile}
          />
          <TopbarMenuLink
            title="Tasks"
            icon="list"
            path="/todo"
            onClick={toggleProfile}
          />
          <TopbarMenuLink
            title="Inbox"
            icon="inbox"
            path="/mail"
            onClick={toggleProfile}
          />
          <div className="topbar__menu-divider" />
          <TopbarMenuLink
            title="Account Settings"
            icon="cog"
            path="/account/profile"
            onClick={toggleProfile}
          />
          <TopbarMenuLink
            title="Lock Screen"
            icon="lock"
            path="/lock_screen"
            onClick={toggleProfile}
          />
          {auth0.isAuthenticated && (
            <TopbarMenuLink
              title="Log Out Auth0"
              icon="exit"
              path="/login"
              onClick={auth0.logout}
            />
          )}*/}
          <TopbarMenuLink
            title="Log Out"
            icon="exit"
            path="/login"
            onClick={logout}
          />
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  user: UserProps,
  auth0: AuthOProps.isRequired,
};

TopbarProfile.defaultProps = {
  user: {},
};

export default hookAuth0(TopbarProfile);
