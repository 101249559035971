import React, { useContext } from 'react';
import { Card, CardBody } from 'reactstrap';
import { Announcement } from "../../../../containers/App/App";
// import io from "socket.io-client";
// import config from '../../../../config/index';

// let socket;

const Announcements = () => {
    const announcementData = useContext(Announcement);
    console.log("notification===>", announcementData);
    // const [notification, setNotification] = useState('');

    // const ENDPOINT = config.ENDPOINT;
    // socket = io(ENDPOINT);
    // console.log(ENDPOINT);


    // useEffect(() => {
    //     // socket = io(ENDPOINT);
    //     // console.log(socket);
    //     socket.on("keymessage", (payload) => {
    //         // console.log("notification===>");
    //         // console.log("notification===>");
    //         payload.map((items) => {
    //             // console.log("notification", items);
    //             let date = items.start_date ? new Date(items.start_date) : ""
    //             if (date !== "") {
    //                 let StartDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
    //                 items['StartDate'] = StartDate;
    //             }
    //             let end = items.end_date ? new Date(items.end_date) : "";
    //             if (end !== "") {
    //                 let EndDate = ((end.getMonth() > 8) ? (end.getMonth() + 1) : ('0' + (end.getMonth() + 1))) + '/' + ((end.getDate() > 9) ? end.getDate() : ('0' + end.getDate())) + '/' + end.getFullYear()
    //                 items['EndDate'] = EndDate;
    //             }
    //         })
    //         setNotification(payload.reverse())
    //         // console.log("notification", payload);
    //         // console.log("notification");
    //     });
    // }, [])


    return (
        <>
            <Card>
                <CardBody className="dashboard__card">
                    <label className="font-weight-bold" >ANNOUNCEMENTS</label>
                    {announcementData.length ? announcementData.map((val, index) =>
                        <div className="border-bottom">
                            <div>
                                {val?.title_description}
                            </div>
                            <div className="d-flex justify-content-between laign-items-center">
                                <div className="d-flex" >
                                    <div className="chat__contact-avatar" style={{ marginLeft: "0" }}>
                                        <img src="https://previews.aspirity.com/easydev/img/11.png" alt="ava" />
                                    </div>
                                    <div className="text-muted" style={{ alignItems: "center", justifyContent: "center" }}>{val?.name}</div>
                                </div>
                                <span className="text-muted d-flex flex-column ">
                                    <span>{val.StartDate}</span>
                                    <span>{val.EndDate}</span>
                                </span>
                            </div>
                        </div>
                    ) : null}
                </CardBody>
            </Card>
        </>
    )
}

export default Announcements;