import React, { useEffect, useState } from "react";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {
  Table,
  Card,
  CardBody,
  Button,
  Col
} from "reactstrap";
import DeleteIcon from "mdi-react/DeleteIcon";
import Checkbox from '@mui/material/Checkbox';
//import PendingList from "./PendingList";
import IconButton from "@material-ui/core/IconButton";
import config from '../../../../config/index';


const UserList = ({ match }) => {
  let history = useHistory();
  const [userInfo, setUserInfo] = useState([]);


  useEffect(() => {
    viewTable()
  }, []);


  const viewTable = () => {
    try {
      var confi = {
        method: 'get',
        url: `${config.apiBaseUrl}get/mangement_approved_list`
      };

      axios(confi)
        .then(async (response) => {
          response.data.Data.map((items) => {
            let roles_id_array = items.role_name ? items.role_name.split(',') : [];
            let date = items.last_login ? new Date(items.last_login) : "";
            let dt = new Date(items.last_login);
            let time = (dt.toLocaleTimeString([], { hour12: true, hour: '2-digit', minute: '2-digit' }));
            let mainDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
            items['Date'] = mainDate
            items['time'] = time
            let mainAcronym = roles_id_array.map(element => {
              var str = element;
              var matches = str.match(/\b(\w)/g);
              var acronym = matches.join('')
              return acronym
            });
            //console.log(mainAcronym.join('/'))
            items['roles_id'] = mainAcronym.join('/')
          });
          console.log("info", response.data.Data)
          await setUserInfo(response.data.Data)
        })
    } catch (error) {
      console.log(error);
    }
  }

  // console.log("checked", checked);

  const gotoView = (role) => {
    console.log("role", role)
    history.push({
      pathname: '/user_management/view_user',
      state: { role },
      search: "?" + new URLSearchParams({ id: role }).toString()
    })
  }

  const chakeStatus = (e) => {


    if (e.target.checked === true) {
      var data = JSON.stringify({
        "id": e.target.id
      });
      var con = {
        method: 'post',
        url: `${config.apiBaseUrl}post/enable_user`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem('token')
        },
        data: data
      };
      axios(con)
        .then((response) => {
          if (response.data.status === 1) {
            viewTable()
          }
        })
        .catch(function (error) {
          console.log(error);
        });

    } else if (e.target.checked === false) {
      let data = JSON.stringify({
        "id": e.target.id
      });
      var conf = {
        method: 'post',
        url: `${config.apiBaseUrl}/post/disable_user`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + localStorage.getItem('token')
        },
        data: data
      };
      axios(conf)
        .then((response) => {
          if (response.data.status === 0) {
            viewTable()
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }


  return (
    <>

      <div className="d-flex" style={{ alignItems: "flex-start" }}>
        <Col className="btn-group--icons d-flex align-items-center mr-auto p-2" sm={8}>
          <IconButton aria-label="backward">
            <ArrowLeftIcon onClick={() => history.goBack()} />
          </IconButton>
          <h3 className="ml-2 font-weight-bold" >USER MANAGEMENT</h3>
        </Col>

        <Col className="p-2" style={{ display: "flex" }} sm={4}>
          <Button
            style={{ marginRight: "5%" }}
            size="sm"
            outline
            color="success"
            onClick={() => history.push("/user_management/create")}
          >
            Add New User
          </Button>
          <Button
            size="sm"
            outline
            color="success"
            onClick={() => history.push("/user_management/pending")}
          >
            Pending request
          </Button>
        </Col>
      </div>
      <div>
        <Card>
          <CardBody>
            <Table responsive hover className="table--bordered">
              <thead>
                <tr>
                  <th style={{ textAlign: "center", width: "12%" }}>First Name</th>
                  <th style={{ textAlign: "center", width: "12%" }}>Last Name</th>
                  <th style={{ textAlign: "center", width: "16%" }}>Email ID</th>
                  <th style={{ textAlign: "center", width: "12%" }}>Roles Assigned</th>
                  <th style={{ textAlign: "center", width: "12%" }}>Status</th>
                  <th style={{ textAlign: "center", width: "16%" }}>Last Login</th>
                  {/* <th style={{ textAlign: "end", width: "36%" }}>Actions</th> */}
                  <th></th>
                </tr>
              </thead>
              {userInfo?.length === 0 ? <>

                <tbody>
                  <tr>
                    <td colSpan="9" key="no_data" style={{ textAlign: "center" }}><h2>No Data Found</h2></td>
                  </tr>
                </tbody>

              </> : userInfo?.length && userInfo?.map((val, index) =>
                <tbody key={index}>
                  <tr>
                    <td style={{ textAlign: "center" }}>{val?.first_name}</td>
                    <td style={{ textAlign: "center" }}>{val?.last_name}</td>
                    <td style={{ textAlign: "center" }}>{val?.email}</td>
                    <td style={{ textAlign: "center" }}>{val?.roles_id}</td>
                    <td style={{ textAlign: "center" }}>
                      <Checkbox
                        color={val?.is_active === 1 ? 'success' : 'default'}
                        defaultChecked={val?.is_active === 1 ? true : false}
                        id={val?.id}
                        onChange={chakeStatus}
                      />
                      {
                        val.is_active === 1 ?
                          <><span style={{ color: "green" }}>Active</span></> :
                          <><span style={{ color: "red" }}>Disabled</span></>
                      }
                    </td>
                    <td style={{ textAlign: "center" }}>
                      <span>{val?.Date}</span><br></br>
                      <span>{val?.time}</span>
                    </td>
                    <td>
                      <Button outline color="success" className="my-auto" size="sm" onClick={() => gotoView(val.id)} >view more</Button>
                    </td>
                    {/* <td style={{ textAlign: "start" }}>
                      <DeleteIcon color="red" size={24} />
                    </td> */}
                  </tr>
                </tbody>
              )}
            </Table>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default UserList;
