import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import {
    Table,
    Card,
    CardBody,
    Col, Input
} from "reactstrap";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import config from '../../../../config/index';
import { Announcement } from "../../../../containers/App/App";

const DachBordAnnoun = () => {
    let history = useHistory();
    const announceData = useContext(Announcement);
    const [announcementData, setAnnouncementData] = useState(announceData);
    const Auth_Token = localStorage.getItem("token");
    // console.log(Auth_Token)
    // const [searchAnnocement, setSearchAnnocement] = useState("");

    // useEffect(() => {
    //     var data = '';

    //     var confi = {
    //         method: 'get',
    //         url: `${config.apiBaseUrl}/get/announcement`,
    //         // url: 'https://192.168.2.183:3000/api/get/announcement',
    //         headers: {},
    //         data: data
    //     };

    //     axios(confi)
    //         .then(async (response) => {
    //             await setAnnouncementData(response.data.Data);
    //             await console.log("announcementData", announcementData);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }, [])


    const searchAnnocementApi = (e) => {
        var confi = {
            method: 'get',
            url: `${config.apiBaseUrl}/get/announcement/${e.target.value}`,
            headers: {
                'Authorization': 'Bearer ' + Auth_Token
            }
        };

        axios(confi)
            .then((response) => {
                console.log(response.data);
                if (response.data.status == 0) {
                    setAnnouncementData(announceData)
                } else {
                    setAnnouncementData(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    return (
        <>
            <div>
                <div className="d-flex" style={{ alignItems: "flex-start" }}>
                    <Col className="btn-group--icons d-flex align-items-center mr-auto p-2" sm={8}>
                        <IconButton aria-label="backward">
                            <ArrowLeftIcon onClick={() => history.goBack()} />
                        </IconButton>
                        <h3 className="ml-2 font-weight-bold" >ANNOUNCEMENT</h3>
                    </Col>

                    {/*
 <Col className="p-2" style={{ display: "flex" }} sm={4}>
      <Button
        style={{ marginRight: "5%" }}
        size="sm"
        outline
        color="success"
        onClick={() => history.push("/user_management/create")}
      >
        Add New User
      </Button>
      <Button
        size="sm"
        outline
        color="success"
        onClick={() => history.push("/user_management/pending")}
      >
        Pending request
      </Button>
    </Col>
*/}
                </div>
                <div>
                    <Card>
                        <CardBody>
                            <span style={{ display: "flex", alignItems: "center" }}>
                                <h4>Search Announcement :</h4>
                                <Input type="text" style={{ width: "20%", marginLeft: "10px" }} onChange={(e) => searchAnnocementApi(e)} />
                            </span>
                            <Table responsive hover className="table--bordered" style={{ marginTop: "20px" }}>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", width: "5%" }}>Sr. No</th>
                                        <th style={{ textAlign: "center", width: "25%" }}>Name</th>
                                        <th style={{ textAlign: "left", width: "30%" }}>Announcement</th>
                                    </tr>
                                </thead>
                                {announcementData?.length ? announcementData?.map((val, index) =>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>{index + 1}</td>
                                            <td style={{ textAlign: "center" }}>{val.name}</td>
                                            <td style={{ textAlign: "left" }}>{val.title_description}</td>
                                        </tr>
                                    </tbody>
                                )
                                    :
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "left" }}><h4>No Announcement</h4></td>
                                        </tr>
                                    </tbody>
                                }
                            </Table>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </>
    )
}

export default DachBordAnnoun;