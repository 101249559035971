import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, CardBody, Card, Row, Label, Button } from 'reactstrap';
import { useHistory, useLocation } from "react-router-dom";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from '@mui/material/Checkbox';
import config from '../../../../../config/index';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Toast, showError, showSuccess } from "../../../../../shared/components/Toast/Toast";

const SelectRoles = () => {
    let history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const ProjectId = params.get('Project_id');
    let userInfo = JSON.parse(localStorage.getItem("authuser"));
    const [activeUserListData, setActiveUserListData] = useState([]);
    const [userListByRole, setUserListByRole] = useState([]);
    const [roleName, setRoleName] = useState("");
    const [selectedRoles, setSelelctedRoles] = useState([]);
    const [roleId, setRoleId] = useState("")
    useEffect(() => {
        getActiveUserList();
        getUserlistByRole(1);
    }, [])

    const getActiveUserList = async () => {
        try {
            var data = {
                method: 'get',
                url: `${config.apiBaseUrl}/get/active_role_list`,
                headers: {}
            };
            await axios(data)
                .then(function (response) {
                    setRoleName(response.data.Data[0].role_name)
                    setRoleId(response.data.Data[0].role_id)
                    setActiveUserListData(response.data.Data)
                })
        } catch (error) {
            console.log(error);
        };
    }

    const getUserlistByRole = async (roleId) => {
        try {
            var confi = {
                method: 'get',
                url: `${config.apiBaseUrl}/get/user_list_by_role/${roleId}`,
                headers: {}
            };
            await axios(confi)
                .then(function (response) {
                    setUserListByRole(response.data.Data)
                    console.log(response.data.Data)
                })
        } catch (error) {
            console.log(error);
        };
    }


    const handleListByrole = (e, roleId, roleName) => {
        e.preventDefault()
        getUserlistByRole(roleId)
        setRoleName(roleName)
        setRoleId(roleId)
    }

    const handleCheckBox = (e) => {
        if (e.target.checked === true) {
            selectedRoles.push(e.target.value);
        }
        if (e.target.checked === false) {
            setSelelctedRoles(selectedRoles.filter(item => item !== e.target.value))
        }
    }



    const onSave = (e) => {
        e.preventDefault()
        try {
            var data = JSON.stringify({
                "project_id": parseInt(ProjectId),
                "role_id": roleId,
                "imprint_id": 0,
                "user_id": selectedRoles,
                "create_by": userInfo.user_id,
                "is_active": 0,
                "is_completed": 0
            });
            var con = {
                method: 'post',
                url: `${config.apiBaseUrl}post/createproject_roles`,
                headers: {
                    'Authorization': "Bearer " + userInfo.token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            axios(con)
                .then(function (response) {
                    if (response.data.status === 1) {
                        showSuccess('Roles Added Successfully', toast);
                    }
                })
        } catch (error) {
            console.log(error);
        }
    }

    const onSumbit = () => {
        setTimeout(() => history.push({
            pathname: '/projects',
        }), 100);
    }


    return (
        <>
            <div className="btn-group--icons d-flex align-items-center">
                <IconButton aria-label="backward">
                    <ArrowLeftIcon onClick={() => history.goBack()} />
                </IconButton>
                <h4 className="ml-2 font-weight-bold">SELECT ROLES</h4>
            </div>
            <Row>
                {/*left card*/}
                <Col className='mt-4' md={3} sm={12} style={{ height: "742px" }}>
                    <Card className="grid">
                        <CardBody className='overflow-auto' style={{ textAlign: "start" }}>
                            {
                                activeUserListData?.map((val, index) => {
                                    return (
                                        < span key={index} className='d-flex justify-content-between' onClick={(e) => handleListByrole(e, val.role_id, val.role_name)} >
                                            <p>{val.role_name}</p>
                                            <i className="fas fa-angle-right mt-3 pt-1 mr-3"></i>
                                        </span>
                                    )
                                })
                            }
                        </CardBody>
                    </Card>
                </Col>
                {/* Right Card */}
                <Col md={9} sm={12} style={{ height: "742px" }}>
                    <h4 className='font-weight-bold'>Users List</h4>
                    <Card className="grid">
                        <CardBody className='overflow-auto' style={{ padding: "3%", textAlign: "start" }}>
                            <h3>{roleName}</h3>
                            {
                                userListByRole?.map((val, index) => {
                                    return (
                                        <div key={index} className='mt-3 mx-4 '>
                                            <Label>
                                                <Checkbox className='checkUser' name="isCurrent" label="Current" color="default" id="checkUser" value={val.id} onClick={e => handleCheckBox(e)} />
                                                {val.first_name} {val.last_name}
                                            </Label>
                                        </div>
                                    )
                                })
                            }
                            <div className='d-flex d-flex justify-content-end'>
                                <Button className='mb-3 mr-3' color="success" onClick={(e) => onSave(e)}>Save</Button>
                                <Button className='mb-3' color="success" outline onClick={(e) => onSumbit(e)}>Submit</Button>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Toast />
        </>
    )
}

export default SelectRoles;