import React from "react";
import ProposalTabs from "./ProposalTabs.jsx";
import { useHistory } from "react-router-dom";
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';

const ProposalList = () => {


    let history = useHistory();

    const backBtnHandle = (e) => {
        e.preventDefault();
        history.goBack();
    }

    return (
        <>
            <div className="btn-group--icons">
                <ArrowLeftIcon style={{ width: "25px" }} onClick={backBtnHandle} />
                <span className="ml-2 font-weight-bold" style={{ color: " #3D3D3D", width: "138px", height: "32px" }}>PROPOSALS</span>
            </div>
            <div className="tabs tabs--justify tabs--bordered-bottom">
                <ProposalTabs />
            </div>
        </>
    )

}

export default ProposalList;