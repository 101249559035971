import React, { useState, useEffect } from 'react';
import {
    Card, CardBody,
    CardTitle, Col, Badge
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import config from '../../../../config/index';
import { toast } from 'react-toastify';
import { Toast, showSuccess } from '../../../../shared/components/Toast/Toast';

const TemplateCustomizedCard = () => {
    const [data, setData] = useState([])
    let userInfo = localStorage.getItem("token");

    const [templateName, setTemplateName] = useState([]);


    useEffect(() => {
        getAllTepmList()
    }, []);

    const getAllTepmList = () => {
        try {
            var confi = {
                method: 'get',
                url: (`${config.apiBaseUrl}get/templetesall`),
                headers: {
                    'Authorization': "Bearer " + userInfo
                }
            };

            axios(confi)
                .then(async response => {
                    setData(await response.data.data);
                    setTemplateName(response?.data?.data[0]?.template_name);
                })
        } catch (error) {
            console.log(error);
        };
    }


    const handleDisable = async (id) => {
        if (id) {
            var data = JSON.stringify({
                "template_id": id
            });

            var con = {
                method: 'post',
                url: (`${config.apiBaseUrl}post/update_templete_ai`),
                headers: {
                    'Authorization': "Bearer " + userInfo,
                    'Content-Type': 'application/json'
                },
                data: data
            };

            await axios(con)
                .then(function (response) {
                    console.log(response.data);
                    if (response.data.message === "success") {
                        getAllTepmList()
                        showSuccess('Task Flow Diseble Successfully', toast);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }




    return (
        <>{data?.length >= 1 && data?.map((val, index) =>
            <Col sm="3" key={index} style={{ marginTop: "5%" }}>
                <Card style={{ backgroundColor: "#FFFFFF", paddingBottom: "5px" }}>
                    <CardBody style={{ backgroundColor: "#FFFFFF" }}>
                        <div className='d-flex justify-content-end'><Badge color="danger" pill style={{ cursor: 'pointer' }} onClick={() => handleDisable(val.template_id)}> Disable</Badge></div>
                        <NavLink to={"edit/"+val.template_id}>
  
<div className='d-flex justify-content-end mt-3'><Badge color="success" pill style={{ cursor: 'pointer' }} > Edit</Badge></div></NavLink>
                        <div className="d-flex justify-content-between mb-1">
                        </div>
                        <CardTitle tag="h5" className="font-weight-bold">{val?.template_data[0]?.label}</CardTitle>
                        <div className="d-flex flex-column mb-2">
                            <label className="text-muted mb-0"> {val?.template_name}</label>
                        </div>
                        <div className="d-flex flex-column mb-2" style={{ marginTop: "10%" }}>
                            {val?.template_data?.length >= 1 && val?.template_data?.map((val, index) =>
                                <label className="text-muted mb-0" style={{ fontWeight: "bold" }}>{val?.type}</label>
                            )}
                        </div>
                    </CardBody>
                </Card>
            </Col>
        )}
            <Toast />
        </>
    )
}

export default TemplateCustomizedCard;
