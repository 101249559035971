import React from "react";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
//import CreateNewteamplates from '../../../Dashboards/App/Templates/CreateNewteamplates'
import CreateNewTeamplates from "./CreateNewTeamplates";

const TemplateCreate = () => {
  let history = useHistory();

  // var items = [{
  //   key: 'Header',
  //   name: 'Header Text',
  //   icon: 'fa fa-header',
  //   static: true,
  //   content: 'Placeholder Text...'
  // },
  // {
  //   key: 'Paragraph',
  //   name: 'Paragraph',
  //   static: true,
  //   icon: 'fa fa-paragraph',
  //   content: 'Placeholder Text...'
  // }];

  return (
    <>
      <div className="btn-group--icons d-flex align-items-center">
        <IconButton aria-label="backward">
          <ArrowLeftIcon onClick={() => history.goBack()} />
        </IconButton>
        <h4 className="ml-2 font-weight-bold">CREATE NEW TASK</h4>
      </div>
      <Card>
        <CardBody>
          <CreateNewTeamplates />
        </CardBody>
      </Card>
    </>
  );
};

export default TemplateCreate;
