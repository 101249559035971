import React from 'react';
import { Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import AlertCircleOutlineIcon from 'mdi-react/AlertCircleOutlineIcon';

const TimeModal = ({ isOpen, stopTimer, submitClicked }) => {
    return (
        <>
            <Modal className="model-content" isOpen={isOpen}>
                <div className="modal-content" style={{ display: "flex", alignItems: "flex-end" }}>
                    <CloseCircleIcon style={{ color: "#8D9CA6" }} onClick={stopTimer} />
                </div>
                <ModalHeader style={{ borderBottom: "0", display: "contents" }}>
                <AlertCircleOutlineIcon style={{height:"20%", width:"20%"}}/>
                    <h3>Are you sure</h3>
                </ModalHeader>
                <ModalBody>
                    <label>Do you really want to stop timer? This process cannot be undone</label>
                </ModalBody>
                <div>
                    <Button style={{ width: "20%", marginRight: "3%" }} color="danger" size="sm" onClick={stopTimer}>Cancel</Button>{' '}
                    <Button style={{ width: "20%", marginRight: "3%" }} outline color="secondary" size="sm" onClick={submitClicked} >Confirm</Button>{' '}
                </div>
            </Modal>
        </>
    )
}

export default TimeModal
