import React, { useState, useEffect } from 'react';
import { Card, CardBody, Col, Button, ButtonToolbar } from 'reactstrap';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { toast } from 'react-toastify';
import config from '../../../../../config/index'
import { Toast, showSuccess } from '../../../../../shared/components/Toast/Toast';
import 'react-toastify/dist/ReactToastify.css';


const Remark = ({ proposal_id }) => {
  const history = useHistory();
  const [remark, setReamrk] = useState("");
  const [remarkErr, setRemarkError] = useState('');
  let userInfo = localStorage.getItem("token");


  const createProgect = () => {
    if (remark) {
      setRemarkError("")
    } else {
      setRemarkError("Remark is required!")
    }
    if (remark) {
      try {
        var data = JSON.stringify({
          "proposal_id": proposal_id,
          "remark": remark
        });
        var confi = {
          method: 'post',
          url: `${config.apiBaseUrl}post/remark`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + userInfo
          },
          data: data
        };
        axios(confi)
          .then(responce => {
            if (responce.data.status === 201) {
              showSuccess('Reamrk Add', toast);
              setTimeout(() => { history.push("/project_create_new_project") }, 1000);
            }
          })
      } catch (error) {
        console.log(error);
      };
    }
  }


  return (
    <Col md={12} lg={6}>
      <Card>
        <CardBody>
          <div className="typography-card">
            <div className="typograph">
              <div className="Remark">
                <h5>REMARKS</h5>
                <textarea className="form-control" rows="3" onChange={(e) => setReamrk(e.target.value)} />
                <span className='text-danger'>{remarkErr}</span>
              </div>
              <ButtonToolbar>
                <Button bsSize="sm" color="success" onClick={createProgect}>Create a new Project</Button>
                <Button bsSize="sm" color="danger">Reject</Button>
              </ButtonToolbar>
            </div>
          </div>
        </CardBody>
      </Card>
      <Toast />
    </Col>
  )
}

export default Remark