import React, { Component, createRef } from 'react';
import axios from 'axios';
import $ from "jquery";
import { withRouter } from "react-router-dom";
// import UserData from '../../../../config/UserInfo';
import config from '../../../../config/index';
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import IconButton from "@material-ui/core/IconButton";
import {
    Container,
    Row,
    Col,
    ButtonToolbar,
    Button,
    Card,
    CardBody,
    FormGroup,
    CardText,
    CardImg,
    Label,
    Input
} from "reactstrap";
import { toast } from 'react-toastify';
import { Toast, showSuccess } from '../../../../shared/components/Toast/Toast';
import profilelogo from '../../../../assets/profilelogo.png';
//import UserData from '../../../../config/UserInfo';

let userInfo = localStorage.getItem("token");


window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

export class EditTaskClass extends Component {
    constructor(props) {
        super(props)
        this.state = {
            taskFlowName: '',
            time: '',
            template_id: this.props.location.state?.template,
            templateData: this.props.location.state?.templateData,
            fetchData :''
        };
        this.handelSubmit = this.handelSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.go_Back = this.go_Back.bind(this);
        this.handelTime = this.handelTime.bind(this);
    }

    fb = createRef();
    formBuilder;




    componentDidMount() {

        //this is the render from json to from 
        var fbRender = document.getElementById('fb-render'),
            formData = JSON.stringify(this.state.templateData)

        var formRenderOpts = {
            formData,
            dataType: 'json'
        };
        this.formBuilder = $(fbRender).formRender(formRenderOpts);

    }

    handleChange(e) {
        this.setState({ taskFlowName: e.target.value });
    };

    handelTime(e) {
        this.setState({ time: e.target.value });
    };


    handelSubmit = (e) => {
        e.preventDefault();
        const { history } = this.props;
        const result = this.formBuilder.options.formData;

        var data = JSON.stringify({
            "task_flow_name": this.state.taskFlowName,
            "created_by": "1",
            "template_id": this.state.template_id,
            "taskflow_form": result
                // {
                //     "task_title": taskTitle,
                //     "discription": description,
                //     "remark": remarks
                // }
            ,
            "estimated_time": this.state.time
        });

        var confi = {
            method: 'post',
            url: (`${config.apiBaseUrl}post/task_name`),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + userInfo
            },
            data: data
        };
        
        axios(confi).then((response) => {
               
                this.setState({ fetchData : response.data },
                    showSuccess('Task Flow Added Successfully',toast));
                setTimeout(() =>  history.push({
                    pathname: '/new_task_flows',
                    state: { fetchData: response.data }
                }), 1000);
                // history.push({
                //     pathname: '/new_task_flows',
                //     state: { fetchData: response.data }
                // })

            })
            .catch(function (error) {
                console.log(error);
            });

    };


    go_Back(e) {
        e.preventDefault();
        this.props.history.goBack();
    }


    render() {
        return (
            <>
                <div className="btn-group--icons d-flex align-items-center">
                    <IconButton aria-label="backward">
                        <ArrowLeftIcon onClick={this.go_Back} />
                    </IconButton>
                    <h3 className="ml-2 font-weight-bold">ADD NEW TASK FLOW</h3>
                </div>
                <Container>
                    <Row>
                        <Col md={3} sm={12}>
                            <div>
                                <Label for="exampleEmail">Task Flow Name</Label>
                                <Input size="sm" type="text" placeholder="" required value={this.state.taskFlowName} onChange={(e) => { this.handleChange(e) }} />
                            </div>
                            <Card style={{ marginTop: "15px" }}>
                                <CardBody>
                                    {/* <Input size="sm" type="text" placeholder="Enter Text" /> */}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md={9} sm={12}>
                            <Card>
                                <CardBody style={{ padding: "3%", textAlign: "start" }}>
                                    <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="radio1" value={false} defaultChecked />
                                                Default
                                            </Label>
                                        </FormGroup>
                                    </div>




                                    <div className="d-flex bd-highlight" style={{ marginTop: "5%" }}>
                                        <div className="flex-grow-1 bd-highlight">

                                        </div>
                                        <div className="bd-highlight">
                                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                <label style={{ marginRight: "2%" }}>Estimated time: </label>
                                                <Input type="number" style={{ width: "18%", alignItems: "center" }} value={this.state.time} onChange={(e) => { this.handelTime(e) }} />
                                                <label style={{ marginLeft: "2%" }}> Hours</label>
                                            </div>
                                           {/* <label style={{ display: "flex", justifyContent: "end", alignItems: "center", marginRight: "40%" }}>Total Time:</label>*/}
                                        </div>
                                    </div>

                                    {/*
                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <CardText style={{ marginBottom: "15px", marginTop: "2%" }}><strong> Description</strong></CardText>
                                </div>
                                <Input type="textarea" placeholder="Enter Text" name="text" id="exampleText" onChange={(e) => setDescription(e.target.value)} />
                                <CardText tag="h5" style={{ marginTop: '10px' }} ><strong>Remarks</strong></CardText>
                                <Input type="textarea" placeholder="Enter Text" style={{ marginTop: "3%" }} rows="3" onChange={(e) => setRemarks(e.target.value)} />
                                
    */}

                                    <form id="fb-render"></form>

                                    <h5 style={{ marginTop: "5%" }}>Worked By</h5>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ marginTop: "1%", display: "flex", justifyContent: "start" }}>
                                            <CardImg size="sm" src={profilelogo} style={{ width: '53px', height: "53px", borderRadius: "50%", marginTop: '2%' }} />
                                            <div>
                                                <CardText style={{ padding: "0" }}><h4>first Name</h4></CardText>
                                                <label className="text-muted">role</label><br></br>
                                                <label style={{ margin: "0" }}>10/08/2021<label style={{ marginLeft: "15px" }}>12.00PM</label></label>
                                            </div>
                                        </div>
                                        <div style={{ display: "inline-flex", width: "auto", position: "relative", float: "right" }}>
                                            <Button style={{ marginRight: "5px", width: "38%", height: "39%" }} color="secondary" size="sm">start timer</Button>
                                            <h3 style={{ width: "25%" }}>00 : 00</h3>
                                            <Button style={{ marginLeft: "5px", width: "38%", height: "39%" }} color="secondary" size="sm">stop timer</Button>
                                        </div>
                                    </div>




                                    {
                                        this.state.taskFlowName === "" ? <ButtonToolbar style={{ display: "inline-flex", width: "auto", position: "relative", float: "right" }}>
                                            <Button color="success" disabled >Save</Button>
                                        </ButtonToolbar> : <ButtonToolbar style={{ display: "inline-flex", width: "auto", position: "relative", float: "right" }}>
                                            <Button color="success" onClick={this.handelSubmit} >Save</Button>
                                        </ButtonToolbar>
                                    }

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Toast />
            </>
        )
    }
}

export default withRouter(EditTaskClass);
