import React, { useState } from 'react';
import { Row, Button } from 'reactstrap';
import OverAllProgress from './OverAllProgress';
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { useHistory, useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import IssueModal from './IssueModal';

const IndexLittelLamb = (props) => {
  let user_Info = localStorage.getItem("authuser");
  const uData =  JSON.parse(window.localStorage.getItem('authuser'));
  let userData = JSON.parse(user_Info);
  const [modal, setModal] = useState(false);
  // const Id = props.location.state?.projectId
  // const proId = props.location.state?.proId
  // const startDate = props.location.state?.sDate
  // const endDate = props.location.state?.eDate
  const sDate = props.location.state?.startD
  const eDate = props.location.state?.endD

  let history = useHistory();
  let location = useLocation();
  let queryString = new URLSearchParams(location.search);
  let proId = queryString.get("id");
  let startDate = queryString.get("search_s")
  const endDate = queryString.get("search_e")
  let projectName = queryString.get("search_name");


  const gotoCreateNewPage = () => {
    history.push({
      pathname: "/project_create_new_project",
      search: '?' + new URLSearchParams({ _name: projectName }).toString(),
      state: { projectName }
    });
  }

  const goToKanban = () => {
    history.push({
      pathname: "/kanban_view",
      search: '?' + new URLSearchParams({ _id: proId }).toString(),
    })
  }

  const newTemplate = () => {
    setModal(!modal);
  }

  return (
    <>
      {modal === true ? <IssueModal style={{ borderRadius: "1%" }} isOpen={modal} newTemplate={newTemplate} proId={proId} user={uData.user_id} /> : null}
      <div className="btn-group--icons d-flex align-items-center" style={{ justifyContent: "space-between" }}>
        <div className="btn-group--icons d-flex align-items-center">
          <IconButton aria-label="backward">
            <ArrowLeftIcon onClick={() => history.goBack()} />
          </IconButton>
          <h4 className="ml-2 font-weight-bold">PROJECTS LITTLE LAB</h4>
        </div>
        <div style={{ marginRight: "3%" }}>
          <Button className="p-2" style={{ marginRight: "10px" }} onClick={newTemplate}>Trigger issue</Button>
          <Button className="p-2 " color="success" outline size="sm" onClick={gotoCreateNewPage} >Add New Title</Button>
          <Button className="p-2 btn-success" style={{ marginLeft: "10px" }} color="secondary" onClick={goToKanban} >Switch to Kanban view</Button>

        </div>
      </div>
      <Row>
        <OverAllProgress proId={proId} startDate={startDate} endDate={endDate} sDate={sDate} eDate={eDate} />
      </Row>
    </>
  )
}

export default IndexLittelLamb;
