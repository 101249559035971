import React from "react";
import { Route, Switch } from "react-router-dom";
import UserList from "./components/UserList";
import PendingList from "./components/PendingList";
import { Container, Row, Col } from "reactstrap";
import AddUser from "./components/AddUser";
import ViewUser from "./components/ViewUser";

const Templates = ({ match }) => {
  //let history = useHistory();
  // console.log(match, "AAAAAAAAAAAAAAAAAA");
  return (
    <Container>
      <Row>
        <Col>
          <Switch>
            <Route path={`${match.url}/pending`} component={PendingList} />
            <Route path={`${match.url}/create`} component={AddUser} />
            <Route path={`${match.url}/view_user`} component={ViewUser} />

            <Route path={`${match.url}`} component={UserList} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

export default Templates;
