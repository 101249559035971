import React from "react";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { useHistory } from "react-router-dom";
import PlusCircleIcon from "mdi-react/PlusCircleIcon";
import IconButton from "@material-ui/core/IconButton";

const TemplateList = () => {
  let history = useHistory();

  return (
    <>
      <div className="btn-group--icons d-flex align-items-center">
        <IconButton aria-label="backward">
          <ArrowLeftIcon onClick={() => history.goBack()} />
        </IconButton>

        <h4 className="ml-2 font-weight-bold">TEMPLATE</h4>
      </div>
      <div className="p-3 mb-4">
        <div
          className="d-flex flex-column  justify-content-center align-items-center h-100"
          style={{ cursor: "pointer" }}
          onClick={() => history.push("/templates/create")}
        >
          {" "}
          <PlusCircleIcon className="text-muted" size={48} />
          <div className="text-muted font-weight-bold mt-2">
            Create New Template
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateList;
