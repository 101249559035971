import React, { useState } from 'react';
import axios from 'axios';
import { NavLink, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import AlternateEmailIcon from 'mdi-react/AlternateEmailIcon';
import AccountCheckIcon from 'mdi-react/AccountCheckIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import { Button, Input } from 'reactstrap';
import normalizePhone from './normalizePhone'
import config from '../../../config/index';
import { toast } from 'react-toastify';
import { Toast, showError, showSuccess, showWarning } from '../../../shared/components/Toast/Toast';
import 'react-toastify/dist/ReactToastify.css';
import ReactPhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css';

const usernameRegex = /^[a-zA-Z ]*$/;
const RegisterForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPass, setShowConfirmPass] = useState(false);
  const [first_name, setFirst_Name] = useState("");
  const [firstnameError, setfirstnameError] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [lastnameError, setlastnameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [mobile, setMobile] = useState("");
  const [mobileError, setMobileError] = useState("");
  const [landline, setLandline] = useState("");
  const [landlineError, setLandLineError] = useState("");
  const [password, setPassword] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [role, setRole] = useState("");
  const [roleError, setRolError] = useState("");
  const [isError, setIserror] = useState("");
  const [auth, setAuth] = useState(false);
  const [passError, setPassError] = useState("")



  const toggleShowPassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const toggleShowconfirmPassword = (e) => {
    e.preventDefault();
    setShowConfirmPass(!showConfirmPass);
  };

  const history = useHistory();

  const validfrom = () => {
    var isValid = true
    if (first_name.length === 0) {
      setfirstnameError("Firstname Should Not Be Blank");
      isValid = false;
    } else if (first_name.length < 3) {
      setfirstnameError("Firstname Should Not Less Than 3 Latter");
      isValid = false;
    } else if (first_name.length > 3) {
      setfirstnameError("");
      isValid = true;
    }
    if (last_name.length === 0) {
      setlastnameError("Lastname Should Not Be Blank");
      isValid = false;
    } else if (last_name.length < 3) {
      setlastnameError("Lastname Should Not Less Than 3 Latter");
      isValid = false;
    } else if (last_name.length > 0) {
      setlastnameError("");
      isValid = true;
    }
    if (!email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
      setEmailError("Email should contain . and @");
      isValid = false;
    }
    else if (email.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
      setEmailError("");
      isValid = true;
    }
    if (role === "") {
      setRolError("Please Select The Role");
      isValid = false;
    } else if (role !== "") {
      setRolError("");
      isValid = true;
    }
    if (mobile.length < 8) {
      setMobileError("Enter Valid Mobile No.");
      isValid = false;
    } else {
      setMobileError("");
      isValid = true;
    }
    if (landline.length < 10) {
      setLandLineError("Enter Valid LandLine No.");
      isValid = false;
    } else {
      setLandLineError("");
      isValid = true;
    }
    if (password.length < 1) {
      setPassError("Password Should Not Be Blank");
    } else if (!password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/)) {
      setPassError("Password must contain at least 8 characters, 1 number, 1 upper and 1 lowercase!");
    }
    else if (password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/)) {
      setPassError("");
    }
    setAuth(isValid)
    return isValid
  }




  const handleSubmit = (event) => {
    event.preventDefault();
    try {
      if (validfrom()) {
        const headers = {
          'Content-Type': 'application/json',
        }
        let data = {
          first_name: first_name,
          last_name: last_name,
          email: email,
          landline: landline,
          mobile: mobile,
          role: role,
          password: password,
        }
        event.preventDefault();
        axios.post(`${config.apiBaseUrl}register`, JSON.stringify(data),
          { headers: headers })
          .then(async responce => {
            if (await responce.data.status === 201 && isError === "") {
              showSuccess('Register successfull', toast);
              setTimeout(() => { history.push("/login") }, 1000);
            } else if (await responce.data.status === 1 && isError === "") {
              showWarning('This Email is already use', toast);
            } else if (isError === "" && isError > 0) {
              showError('Confirm password does not match', toast);
            }
          })
      }
    } catch (error) {
      console.log(error)
      showError(`${error.response.data?.message}`, toast);
    }
  }


  const checkVlidation = (e) => {
    setConfirm_password(e.target.value)
    if (password !== e.target.value) {
      setIserror("Confirm Password Does Not Match");
    } else {
      setIserror("");
    }
  }



  return (
    <form className="form" onSubmit={handleSubmit} autoComplete="off">
      <div className="form__form-group">
        <span className="form__form-group-label">First Name</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            value={first_name}
            onChange={(e) => {
              setFirst_Name(e.target.value);
              if (e.target.value.length === 0) {
                setfirstnameError("Name Should Not Be Blank");
              } else if (e.target.value.length < 2) {
                setfirstnameError("Name Should Not Less Than 3 Letter");
              } else if (!usernameRegex.test(e.target.value)) {
                setfirstnameError("username is invalid");
              } else if (e.target.value.length > 2) {
                setfirstnameError("");
              }
            }}
            name="firstname"
            component="input"
            type="text"
            placeholder="First Name"
            // required  
            className="input-without-border-radius"
          />
        </div>
        {firstnameError !== '' && <span style={{ color: 'red' }}> {firstnameError}</span>}
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Last Name</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            value={last_name}
            onChange={(e) => {
              setLast_Name(e.target.value);
              if (e.target.value.length === 0) {
                setlastnameError("Name Should Not Be Blank");
              } else if (e.target.value.length < 2) {
                setlastnameError("Name Should Not Less Than 3 Letter");
              } else if (!usernameRegex.test(e.target.value)) {
                setlastnameError("username is invalid");
              } else if (e.target.value.length > 2) {
                setlastnameError("");
              }
            }}
            name="lastname"
            component="input"
            type="text"
            placeholder="Last Name"
            // required   //required use for fill this filled tooltip 
            className="input-without-border-radius"
          />
        </div>
        {lastnameError !== '' && <span style={{ color: 'red' }}> {lastnameError}</span>}
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">E-mail</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AlternateEmailIcon />
          </div>
          <Field
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              if (e.target.value.length < 1) {
                setEmailError("Email Should Not Be Blank");
              } else if (!e.target.value.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
                setEmailError("Email should contain . and @");
              }
              else if (e.target.value.match(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/)) {
                setEmailError("");
              }
            }}
            name="email"
            component="input"
            //type="email"
            placeholder="example@mail.com"
            // required
            className="input-without-border-radius"
          />
        </div>
        {emailError !== '' && <span style={{ color: 'red' }}> {emailError}</span>}
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Role</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountCheckIcon />
          </div>
          <Input type="select" value={role} size="sm"
            onChange={(e) => {
              setRole(e.target.value)
              if (role === " ") {
                setRolError("Please Select The Role");
              } else if (role !== " ") {
                setRolError("");
              }
            }}

            name="select" id="roleSelect" className="input-without-border-radius_multi-select text-muted">
            <option value="" selected>Select User Role</option>
            <option value="1">Acquisition Editor</option>
            <option value="2">Developmental Editor</option>
            <option value="3">Managing Editor</option>
            <option value="4">Copy Editor</option>
            <option value="5">Designer</option>
            <option value="6">Production Manager</option>
            <option value="7">Production Editor</option>
          </Input>
        </div>
        {roleError !== '' && <span style={{ color: 'red' }}> {roleError}</span>}
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Mobile No.</span>
        <ReactPhoneInput
          country="us"
          placeholder="Enter Mobile No."
          value={mobile}
          onChange={(e) => {
            setMobile(e)
            if (mobile.length < 8) {
              setMobileError("Enter Valid Mobile No.");
            } else {
              setMobileError("");
            }
          }}
          enableSearch
          disableSearchIcon
        />
        {mobileError !== '' && <span style={{ color: 'red' }}> {mobileError}</span>}
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Home No.</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <PhoneIcon />
          </div>
          <Field
            value={landline}
            onChange={(e) => {
              setLandline(e.target.value)
              if (e.target.value.length < 10) {
                setLandLineError('Enter Valid Landline No.');
              } else {
                setLandLineError("");
              }

            }}
            name="landline"
            component="input"
            normalize={normalizePhone}
            type="phone"
            placeholder="landline No."
            // required
            className="input-without-border-radius"
          />
        </div>
        {landlineError !== '' && <span style={{ color: 'red' }}> {landlineError}</span>}
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            value={password}
            onChange={(e) => {
              setPassword(e.target.value)
              if (e.target.value.length < 1) {
                setPassError("Password Should Not Be Blank");
              } else if (!e.target.value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/)) {
                setPassError("Password must contain at least 8 characters, 1 number, 1 upper and 1 lowercase!");
              }
              else if (e.target.value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,32}$/)) {
                setPassError("");
              }
            }}
            name="password"
            component="input"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            maxLength="16"
            // required
            className="input-without-border-radius"
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword ? ' active' : ''}`}
            onClick={toggleShowPassword}
          ><EyeIcon />
          </button>
        </div>
        <p style={{ color: 'red' }}>{passError}</p>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Confirm Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            value={confirm_password}
            onChange={(e) => checkVlidation(e)}
            name="confirm_password"
            component="input"
            type={showConfirmPass ? 'text' : 'password'}
            placeholder="Confirm Password"
            // required
            className="input-without-border-radius"
          />
          <button
            type="button"
            className={`form__form-group-button${showConfirmPass ? ' active' : ''}`}
            onClick={toggleShowconfirmPassword}
          ><EyeIcon />
          </button>
        </div>
        <p style={{ color: 'red' }}>{isError}</p>
      </div>
      <div className="account__btns register__btns">
        {auth === false
          ? <Button type="submit" color="primary" className="account__btn"> Sign Up</Button> :
          (
            <NavLink className="account__btn btn btn-primary" to="/login">
              Sign Up
            </NavLink>
          )}
      </div>
      <Toast />
    </form>
  );
};

RegisterForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};

RegisterForm.defaultProps = {
  errorMessage: '',
};

export default reduxForm({
  form: 'register_form',
})(RegisterForm);

