import React, { useState, useEffect } from 'react';
import {
    Card, CardBody, Row,
    CardTitle, Col, Container, Button, Badge
} from 'reactstrap';
import axios from 'axios';
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import {
    CircularProgressbar, buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import config from '../../../../../config/index';


const Projects = () => {
    const [projectCard, setProjectCard] = useState('');
    let history = useHistory();
    const gotoCreateNewPage = () => {
        history.push("/project_create_new_project");
    }

    useEffect(() => {
        try {
            var data = '';
            var confi = {
                method: 'get',
                url: `${config.apiBaseUrl}get/project_heading`,
                headers: {},
                data: data
            };
            axios(confi)
                .then(async (response) => {
                    response.data.Data?.map((items) => {
                        let date = items.start_date ? new Date(items.start_date) : "";
                        let edDate = items.end_date ? new Date(items.end_date) : "";
                        let start = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
                        let end = ((edDate.getMonth() > 8) ? (edDate.getMonth() + 1) : ('0' + (edDate.getMonth() + 1))) + '/' + ((edDate.getDate() > 9) ? edDate.getDate() : ('0' + edDate.getDate())) + '/' + edDate.getFullYear()
                        items['startDate'] = start
                        items['endDate'] = end
                    })
                    await setProjectCard(response.data?.Data);
                })
        } catch (error) {
            console.log(error);
        }
    }, [])


    const goToVolume = (data) => {
        let sDate = data.startDate
        let eDate = data.endDate
        let proId = projectCard.task_flow_id
        let name = data.project_name;
        let id = data.project_id
        data.volumes > 1 ? history.push({
            pathname: '/book_volume',
            state: { name, id },
            search: '?' + new URLSearchParams({ id: id }).toString() + "&search" + new URLSearchParams({ _name: name })
        }) : history.push({
            pathname: "/littel_lamb",
            state: { proId, sDate, eDate },
            search: '?' + new URLSearchParams({ id: id }).toString() + '&search' + new URLSearchParams({ _s: sDate }) + '&search' + new URLSearchParams({ _e: eDate }) + "&search" + new URLSearchParams({ _name: name }),
        });
    }

    return (
        <>
            <div className="btn-group--icons d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                <div className="btn-group--icons d-flex align-items-center">
                    <IconButton aria-label="backward">
                        <ArrowLeftIcon onClick={() => history.goBack()} />
                    </IconButton>
                    <h4 className="ml-2 font-weight-bold">PROJECTS</h4>
                </div>
                <div style={{ marginRight: "3%" }}>
                    <Button className="p-2" onClick={gotoCreateNewPage} color="success">Create New Project</Button>
                </div>
            </div>
            <Container className="container" style={{ backgroundColor: "#FFFFFF", marginTop: "2%" }}>
                <Row style={{ width: "100%", margin: "-24px auto" }}>
                    <>{projectCard?.length ? projectCard?.map((val, index) =>
                        <Col key={index} sm="3" style={{ marginTop: "5%" }}>
                            <Card style={{ cursor: "pointer", height: "100%" }} onClick={() => goToVolume(val)}>
                                <CardBody style={{ backgroundColor: "#FFEDED" }}>
                                    <div className="d-flex justify-content-between mb-1">
                                        <div>
                                            <Badge color="danger" className="p-1">{val.imprint}</Badge>
                                        </div>
                                        {/* <Badge color="success" className="p-1">New</Badge>*/}
                                    </div>
                                    <span style={{ color: "#707070" }}>volume : {val.volumes} </span>
                                    <CardTitle tag="h5" className="font-weight-bold">{val.project_name}</CardTitle>
                                    <div className="d-flex">
                                        <div className="p-2">
                                            <div className="d-flex flex-column mb-2">
                                                <label className="text-muted mb-0">Owner</label>
                                                <span className="font-weight-bold"></span><b>{val.owner}</b>
                                            </div>
                                            <div className="d-flex flex-column mb-2">
                                                <label className="text-muted mb-0">Current Task</label>
                                                <span className="font-weight-bold">Check In</span>
                                            </div>
                                        </div>
                                        <div className="ml-auto p-2" style={{ width: 90, height: 80 }}>
                                            <div label="Default">
                                                <CircularProgressbar value={val.total_progress} text={Math.trunc(val.total_progress)} styles={buildStyles({
                                                    pathColor: "#f00",
                                                    trailColor: "#fff",
                                                    textColor: "#f00"
                                                })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="d-flex flex-column">
                                            <label className="text-muted mb-0">Srart Date</label>
                                            <b>{val.startDate}</b>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <label className="text-muted mb-0">End Date</label>
                                            <b>{val.endDate}</b>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    ) : <h4 style={{ padding: "20px" }}>No Data Found</h4>}</>
                </Row>
            </Container>
        </>
    )
}

export default Projects;
