import React from "react";
import { Route, Switch } from "react-router-dom";
import TaskFlowView from "./components/TaskFlowView";
// import TemplateCreate from "./components/TemplateCreate";
import { Container, Row, Col } from "reactstrap";

const TaskFlow = ({ match }) => {
  // let history = useHistory();
  return (
    <Container>
      <Row>
        <Col>
          <Switch>
            {/* <Route path={`${match.url}/create`} component={TemplateCreate} /> */}
            <Route path={`${match.url}`} component={TaskFlowView} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

export default TaskFlow;
