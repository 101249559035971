import React from "react";
import {
  Col,
  ButtonToolbar,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import clsx from 'clsx';
// import DeleteIcon from "mdi-react/DeleteIcon";
// import DatePicker from "react-datepicker";
// import PlusCircleIcon from "mdi-react/PlusCircleIcon";
// import IconButton from "@material-ui/core/IconButton";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/core/styles';
// import StepConnector from '@material-ui/core/StepConnector';
// import TimeLine from './TimeLine';
// import config from '../../../../config/index';

const TaskFlowSteps = ({ taskFlowsStep }) => {


  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#784af4'
    },
    circle: {
      width: 25,
      height: 25,
      borderRadius: '50%',
      border: '1px solid gray',
      backgroundColor: 'white',
    },
    completed: {
      width: 25,
      height: 25,
      color: 'white',
      borderRadius: '50%',
      border: '1px solid gray',
      backgroundColor: 'white',
    },
  });


  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <div className={classes.completed} /> : <div className={classes.circle} />}
      </div>
    );
  }



  return (
    <>
      <Col md={7} sm={12}>
        <Card>
          <CardBody>
            <div style={{ display: 'flex', justifyContent: "space-between" }}>
              <h2 className="page-title">TASK FLOW</h2>
              {/* <ButtonToolbar>
                <Button outline bsSize="sm">Edit</Button>
              </ButtonToolbar> */}
            </div>
            {/* <h4 className="font-weight-bold">Schiffer Default</h4> */}
            <Stepper orientation="vertical" style={{ overflowY: "scroll", height: "60vh" }}>

              {taskFlowsStep?.length >= 1 && taskFlowsStep.map((val, index) =>
                <Step key={index}>
                  <StepLabel StepIconComponent={QontoStepIcon}>{val.Label}</StepLabel>
                </Step>
              )
              }

            </Stepper>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default TaskFlowSteps
