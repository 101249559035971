import React, { useState, useEffect } from 'react';
import {
    Card, CardBody,
    CardTitle, Col, Badge
} from 'reactstrap';
import axios from 'axios';
import { useHistory } from "react-router-dom";
import config from '../../../../../config/index';

const RejectedCard = () => {
    const [newCardData, setNewCardData] = useState([])
    let userInfo = localStorage.getItem("token");

    const history = useHistory();
    const showProposal = (proposal_id) => {
        history.push({
            pathname: '/proposal_details',
            search: "?" + new URLSearchParams({ proposal_id: proposal_id }).toString(),
            state: { proposal_id }
        })
    }

    useEffect(() => {
        try {
            var confi = {
                method: 'get',
                url: `${config.apiBaseUrl}get/rejectedlist`,
                headers: {
                    'Authorization': "Bearer " + userInfo
                }
            };
            axios(confi)
                .then(response => setNewCardData(response.data?.data))
        } catch (error) {
            console.log(error);
        };
    }, []);

    const praposal_data_val = newCardData?.map((res) => {
        let val = JSON.parse(res.praposal_data)
        let date = res.created_date ? new Date(res.created_date) : "";
        let mainDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
        val["date"] = mainDate;
        val["time"] = res.time;
        val["proposal_id"] = res.proposal_id
        return val
    })


    return (
        <>{praposal_data_val?.length > 0 ? praposal_data_val?.map((val, index) =>
            <Col key={index} sm="3" style={{ marginTop: "5%" }}>
                <Card style={{ backgroundColor: "#FFEDED", cursor: "pointer" }} onClick={() => showProposal(val.proposal_id)}>
                    <CardBody style={{ backgroundColor: "#FFEDED", height: "33%" }}>
                        <div className="d-flex justify-content-between mb-1">
                            <div>
                                <span className="mr-1">{val?.imprint_style}</span>
                            </div>
                            <Badge color="danger" className="p-1">Rejected</Badge>
                        </div>
                        <CardTitle tag="h5" className="font-weight-bold">Sacred Actions</CardTitle>
                        <div className="d-flex flex-column mb-2">
                            <label className="text-muted mb-0">Author</label>
                            <span className="font-weight-bold"></span><b>{val?.name}</b>
                        </div>
                        <div className="d-flex flex-column mb-2">
                            <label className="text-muted mb-0">Current Status</label>
                            <span className="font-weight-bold">Check In</span>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column">
                                <label className="text-muted mb-0">Date</label>
                                <b>{val?.date}</b>
                            </div>
                            <div className="d-flex flex-column">
                                <label className="text-muted mb-0">Time</label>
                                <b>{val?.time}</b>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        )
            :
            <h4 style={{ paddingTop: "20px" }}>No Data Found</h4>}</>
    )
}

export default RejectedCard;
