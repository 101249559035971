import React, { useState, useEffect } from 'react';
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import {
    Form,
    Container,
    Button,
    CardBody,
    FormGroup,
    Label,
    Col,
    Row,
    CardImg
} from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import Checkbox from '@mui/material/Checkbox';
import profilelogo from '../../../../assets/profilelogo.png'
import config from '../../../../config/index';

const ViewUser = (props) => {
    const [userData, setUserData] = useState("");
    const [userProject, setUserProject] = useState("");
    const [userTask, setUserTask] = useState("");
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const userId = params.get('id');
    let history = useHistory();
    let userInfo = localStorage.getItem("token");

    useEffect(() => {
        AllData()
    }, [])


    const AllData = () => {
        try {
            var data = JSON.stringify({
                "id": userId
            });
            var confi = {
                method: 'post',
                url: `${config.apiBaseUrl}post/user_information`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + userInfo

                },
                data: data
            };
            axios(confi)
                .then(async (response) => {
                    response.data.Data.map((items) => {
                        let roles_id_array = items.role_name ? items.role_name.split(',') : [];
                        items['roleArry'] = roles_id_array
                    })
                    await setUserTask(response?.data.Tasks)
                    setUserProject(response?.data.Projects)
                    setUserData(response?.data.Data);
                    console.log(response?.data.Data);
                })
        } catch (error) {
            console.log(error);
        }
    }


    const chakeStatus = (e) => {
        if (e.target.checked === true) {
            try {
                var data = JSON.stringify({
                    "id": e.target.id
                });
                var con = {
                    method: 'post',
                    url: `${config.apiBaseUrl}post/enable_user`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + userInfo
                    },
                    data: data
                };
                axios(con)
                    .then((response) => {
                        if (response.data.status === 1) {
                            AllData()
                        }
                    })
            } catch (error) {
                console.log(error);
            }

        } else if (e.target.checked === false) {
            try {
                let data = JSON.stringify({
                    "id": e.target.id
                });
                var conf = {
                    method: 'post',
                    url: `${config.apiBaseUrl}/post/disable_user`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + userInfo
                    },
                    data: data
                };
                axios(conf)
                    .then((response) => {
                        if (response.data.status === 0) {
                            AllData()
                        }
                    })
            } catch (error) {
                console.log(error);
            }
        }
    }

    const handleEdit = (userId) => {
        history.push({
            pathname: '/user_management/create',
            state: { userData },
            search: "?" + new URLSearchParams({ id: userId }).toString()
        })
    }


    return (
        <>
            <div className="d-flex" style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                <div className="btn-group--icons d-flex align-items-center mr-auto" style={{ marginBottom: "%" }} >
                    <IconButton aria-label="backward">
                        <ArrowLeftIcon onClick={() => history.goBack()} />
                    </IconButton>
                    <h3 className="ml-2 font-weight-bold" >USER MANAGEMENT</h3>
                </div>

                {userData.length >= 1 ?
                    <div className="p-2 d-flex" sm={4}>
                        <div style={{ alignItems: "center" }} sm={4}>
                            <Checkbox color={userData[0]?.is_active === 1 ? 'success' : 'default'} defaultChecked={userData[0].is_active === 1 ? true : false} id={userData[0].id} onChange={chakeStatus} />

                        </div>
                        {userData[0]?.is_active === 1 ? <><span className='mt-2 pt-1 md-3' style={{ color: "green" }}>Active</span></> : <><span className='mt-2 pt-1 me-3' style={{ color: "red" }}>Disabled</span></>}
                        <Button
                            className='ml-3'
                            bsSize="sm"
                            color="secondary"
                            onClick={() => handleEdit(userData[0]?.id)}
                        >
                            Edit
                        </Button>

                    </div>
                    : null}
            </div>
            {userData.length >= 1 ?
                <Row style={{ marginTop: "-1%" }}>
                    <Col md={5} sm={12}>
                        <CardBody className="card-body h-auto">
                            <h5 className="font-weight-bold">Personal Information</h5>
                            <Container style={{ marginTop: '3%' }}>
                                <Row xs="2">
                                    <Col>
                                        <CardImg size="sm" src={userData[0]?.attachment} style={{ borderRadius: "50%", marginTop: '5%', alignItems: "center", width: "8" }} />
                                    </Col>
                                    <Col className="md-form">
                                        <Form>
                                            <FormGroup style={{}}>
                                                <h6 className="font-weight-bold">{userData[0]?.first_name} {userData[0]?.last_name}</h6>
                                                <p style={{ margin: "0" }}>{userData[0]?.role_name}</p>
                                                <h6 style={{ marginTop: "15%" }} className="font-weight-bold">{userData[0]?.email}</h6>
                                                <h6 style={{ marginTop: "5%" }} className="font-weight-bold">{userData[0]?.mobile}</h6>
                                            </FormGroup>
                                        </Form>
                                    </Col>
                                </Row>
                            </Container>
                            <div className="divider"><hr /></div>
                            <div style={{ display: "flex", justifyContent: "space-around", textAlign: "center" }}>
                                <div>
                                    <h4>{userProject}</h4>
                                    <p style={{ margin: "0" }}>project</p>
                                </div>
                                <div>
                                    <h4>{userTask}</h4>
                                    <p style={{ margin: "0" }}>Tasks</p>
                                </div>
                                <div>
                                    <h4>09</h4>
                                    <p style={{ margin: "0" }}>Issues</p>
                                </div>
                            </div>
                        </CardBody>

                        <CardBody style={{ marginTop: "5%" }}>
                            <h5 className="font-weight-bold">Additional Information</h5>
                            <h6 style={{ marginTop: "8%" }} className="font-weight-bold">Employee Type</h6>
                            <p style={{ margin: "0" }}>{userData[0]?.employee_type}</p>
                            {/*
                           <FormGroup className="d-flex" style={{ display: "flex", justifyContent: 'space-between', marginTop: "8%" }}>
                           <div>
                               <h6 className="font-weight-bold">Engagement Start Date</h6>
                               <p style={{ margin: "0" }}>{userData[0]?.start_date}</p>
                           </div>
                           <div>
                               <h6 className="font-weight-bold">Engagement End Date</h6>
                               <p style={{ margin: "0" }}>{userData[0]?.end_date}</p>
                           </div>
                       </FormGroup>
                        */}
                        </CardBody>
                    </Col>
                    <Col md={7} sm={12}>
                        <CardBody>
                            <FormGroup>
                                <div>
                                    <h5 className="font-weight-bold">Roles Assing</h5>
                                    <FormGroup>
                                        {userData[0]?.roleArry.map((val) =>
                                            <div className="d-flex" style={{ alignItems: "center", marginTop: "4%", marginLeft: "5%" }} sm={4}>
                                                <p style={{ width: "12vw" }}>{val}</p>
                                                <Checkbox color="default" checked={true} />
                                            </div>
                                        )}
                                    </FormGroup>
                                    <FormGroup>

                                    </FormGroup>
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <div>
                                    <h5 className="font-weight-bold">User Access</h5>
                                </div>
                                <div className="d-flex justify-content-around" style={{ marginTop: "2%", width: "60%" }}>
                                    <label>Master access</label>
                                    <FormGroup>
                                        {userData[0]?.view === 1 || userData[0]?.view === "1" ?
                                            <Label>
                                                <Checkbox color="default" checked={true} />
                                                View
                                            </Label>
                                            : <Label>
                                                <Checkbox color="default" />
                                                View
                                            </Label>}
                                    </FormGroup>
                                    <FormGroup >
                                        {userData[0]?.edit === 1 || userData[0]?.edit === "1" ?
                                            <Label>
                                                <Checkbox color="default" checked={true} />
                                                Edit
                                            </Label>
                                            : <Label>
                                                <Checkbox color="default" />
                                                Edit
                                            </Label>}
                                    </FormGroup>
                                </div>
                            </FormGroup>
                        </CardBody>
                    </Col>
                </Row >
                : null}
        </>
    )
}

export default ViewUser;
