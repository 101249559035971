import $ from "jquery";
import React, { useEffect, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import config from '../../../../../config/index';
import axios from 'axios';
import { Col, CardBody, Card, CardText, Input, CardImg, Progress, ButtonToolbar, Button } from 'reactstrap';
import profilelogo from '../../../../../assets/profilelogo.png'
import UserData from '../../../../../config/UserInfo';
import TimeModal from './TimeModal';
import { toast } from 'react-toastify';
import { Toast, showError, showSuccess } from '../../../../../shared/components/Toast/Toast';
import 'react-toastify/dist/ReactToastify.css';



window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require('formBuilder/dist/form-render.min.js');
let formBuilder;
var interv = undefined;
const OverAllProgress = ({ proId }) => {

    const [per, setPer] = useState('');
    const [allTaskInfo, setAllTaskInfo] = useState([]);
    const [tempInfo, setTempInfo] = useState('');
    const [show, setShow] = useState(true);
    const [modal, setModal] = useState(false);
    const [pause, setPause] = useState(false);
    const [submit, setSubmit] = useState(true);
    // const [myValues, setMyValues] = useState(false);
    let [addTimeBtn, setAddTimeBtn] = useState(false);
    const [addTime, setAddTime] = useState(0)
    const [estimTime, setEstimTime] = useState("");
    const [totalTime, setTotalTime] = useState("");
    const [additionalTime, setAdditionalTime] = useState("");
    const [remark, setRemark] = useState("");
    const [tesmpFromData, setTempFromData] = useState("");
    // const [colorcode, setColorcode] = useState([]);

    const [myCondition, setMyCondition] = useState(false);
    const [mySubmit, setMySubmit] = useState(false);
    const [IsComplete, setIsComplete] = useState(0);
    const [time, setTime] = useState({ ms: 0, s: 0, m: 0, h: 0 });
    const [status, setStatus] = useState(0);
    let localUser = localStorage.getItem("authuser");
    const userInfo = JSON.parse(localUser)

    useEffect(() => {
        stepperShow()
        // taskShow()
    }, []);

    useEffect(() => {
        // console.log("AddTimeBtn", addTimeBtn);
        // console.log("AddTimeBtn", estimTime);
    }, [estimTime])


    const start = () => {
        run();
        setStatus(1);
        interv = setInterval(run, 10)
        console.log('interv :- ', interv);
        // setInterv();
    };

    const stop = () => {
        setAddTimeBtn(true)
        clearInterval(interv);
        setStatus(2);
    };

    const reset = () => {
        // setMyValues(true)
        clearInterval(interv);
        setStatus(0);
        // setTime({ ms: 0, s: 0, m: 0, h: 0 })
    };

    const resume = () => start();

    var updatedMs = time.ms,
        updatedS = time.s,
        updatedM = time.m,
        updatedH = time.h;


    const run = () => {
        const eTime = estimTime + ":0:0"
        const currentTime = updatedH + ":" + updatedM + ":" + updatedS;
        if (eTime == currentTime) {
            setMyCondition(true)
            clearInterval(interv);
            setAddTimeBtn(true);
            setPause(true);
            setSubmit(false);
        }
        if (updatedM === 60) {
            updatedH++;
            updatedM = 0;
        }
        if (updatedS === 60) {
            updatedM++;
            updatedS = 0;
        }
        if (updatedMs === 100) {
            updatedS++;
            updatedMs = 0;
        }
        updatedMs++;
        return setTime({ ms: updatedMs, s: updatedS, m: updatedM, h: updatedH });
    };

    const submitClicked = () => {
        setSubmit(false);
        setModal(!modal)
    }

    const stepperShow = async () => {
        try {
            var data = JSON.stringify({
                "project_id": proId,
                "id": userInfo.user_id,
                "roleID": userInfo.roleID
            });
            var confi = {
                method: 'post',
                url: `${config.apiBaseUrl}get/user_info`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };
            await axios(confi)
                .then(async (response) => {
                    taskShow(response?.data?.Data[0]?.task_flowtemplate_id)
                    response?.data?.Data?.map((items) => {
                        let date = items.start_date ? new Date(items.start_date) : "";
                        let edDate = items.end_date ? new Date(items.end_date) : "";
                        let start = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
                        let end = ((edDate.getMonth() > 8) ? (edDate.getMonth() + 1) : ('0' + (edDate.getMonth() + 1))) + '/' + ((edDate.getDate() > 9) ? edDate.getDate() : ('0' + edDate.getDate())) + '/' + edDate.getFullYear()
                        items['startDate'] = start
                        items['endDate'] = end
                    })
                    await setPer(response.data?.Percent)
                    await setAllTaskInfo(response?.data.Data);
                })
        } catch (error) {
            console.log(error);
        };
    }


    const taskShow = (id) => {
        var data = JSON.stringify({
            "project_id": proId,
            "task_flowtemplate_id": id || ""
        });
        var conf = {
            method: 'post',
            url: `${config.apiBaseUrl}post/user_templets`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(conf)
            .then(async (response) => {
                await setTempInfo(response?.data?.Data[0]);
                console.log(response?.data?.Data[0]);
                var fbRender = document.getElementById('fb-render'),
                    formData = response?.data?.Data[0]?.taskflow_form
                var formRenderOpts = {
                    formData,
                    dataType: 'json'
                };
                formBuilder = $(fbRender).formRender(formRenderOpts);
                setEstimTime(response?.data?.Data[0]?.estimated_time)
                setAdditionalTime(response?.data?.time)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const startTimer = (e, tempInfo) => {
        start()
        setShow(false)


        console.log(tempInfo);
        // console.log(tempInfo?.task_flow_id)
        // console.log(tempInfo?.taskflow_form)

        // var data = {
        //     // "task_flowtemplate_id": id,
        //     // "additional_time": addTime,
        //     // "work_by": UserData?.id,

        //     "additional_time": addTime,
        //     "work_by": UserData?.id,
        //     "project_id": tempInfo?.project_id,
        //     "task_flowtemplate_id": tempInfo?.task_flow_id,
        //     "taskflow_form": tempInfo?.taskflow_form
        // };
        // var conig = {
        //     method: 'post',
        //     url: `${config.apiBaseUrl}post/user_work_timer`,
        //     // url: 'http://192.168.2.131:7000/api/post/user_work_timer',
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data: JSON.stringify(data)
        // };
        // axios(conig)
        //     .then((response) => {
        //         // setAdditionalTime(response?.data?.additional_time)
        //         console.log("Additional time", additionalTime);
        //         setTotalTime(response?.data?.Total_time)
        //         setAdditionalTime(response?.data?.Data[0]?.additional_time)
        //         console.log("Total time====>", response?.data?.Data[0]?.additional_time);
        //     })
        //     .catch(function (error) {
        //         console.log(error);
        //     });
    }


    const addMoreTimeHandle = (e, tempInfo) => {
        e.preventDefault();
        var data = JSON.stringify({
            // "task_flowtemplate_id": Id || proId,
            "additional_time": addTime,
            "work_by": UserData?.id,
            "project_id": tempInfo?.project_id,
            "task_flowtemplate_id": tempInfo?.task_flowtemplate_id,
            "taskflow_form": tempInfo?.taskflow_form
        });

        var mainconfig = {
            method: 'post',
            url: `${config.apiBaseUrl}post/user_work_timer`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(mainconfig)
            .then((response) => {
                console.log(response.data);
                setAdditionalTime(response?.data?.Data[0].additional_time)
                setEstimTime(response?.data?.Data[0].estimated_time)
                setTotalTime(response?.data?.Total_time)
                if (response.data.status === 0) {
                    console.log("msg==>", response?.data?.Data[0].message);

                    // showSuccess('Saved Successfully', toast);

                }
                start()
            })
            .catch((error) => {
                console.log(error);
            });


    }


    const pauseTimer = () => {
        stop();
        setPause(true)
    }


    const stopTimer = () => {
        stop();
        setModal(!modal);
    }

    const resumeTimer = () => {
        resume();
        setPause(false)
    }

    const useQontoStepIconStyles = makeStyles({
        root: {
            color: '#eaeaf0',
            display: 'flex',
            height: 22,
            alignItems: 'center',
        },
        active: {
            color: '#784af4'
        },
        circle: {
            width: 25,
            height: 25,
            borderRadius: '50%',
            border: '1px solid gray',
            backgroundColor: 'white',
        },
        completed: {
            width: 25,
            height: 25,
            color: 'white',
            borderRadius: '50%',
            border: '1px solid gray',
            backgroundColor: 'white',
        },
    });


    const handleSubmit = (tempInfo) => {
        var fbRender = document.getElementById('fb-render');

        setTempFromData($(fbRender).formRender('userData'));

        var data = JSON.stringify({
            "project_id": tempInfo?.project_id,
            "task_flowtemplate_id": tempInfo?.task_flowtemplate_id,
            // "task_flowtemplate_id": '1',
            "work_by": UserData?.id,
            "taskflow_form": $(fbRender).formRender('userData')
        });

        var confi = {
            method: 'post',
            url: `${config.apiBaseUrl}post/project_work_flow`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(confi)
            .then(function (response) {
                showSuccess('Task Submited Successfully', toast);
                console.log(JSON.stringify(response.data));

                if (response.data.status === 1) {
                    setMySubmit(true);
                }
                else {
                    setMySubmit(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    function QontoStepIcon(props, color) {
        // console.log('props :- ', color);
        const classes = useQontoStepIconStyles();
        const { active, completed } = props
        return (
            <div
                className={clsx(classes.root, {
                    [classes.active]: active,
                })}
            >
                {/* {completed ? <div className={classes.completed}/> : <div className={classes.circle} />} */}
                {completed ? <div className={classes.completed} /> : <div className={classes?.circle} style={{ backgroundColor: color }} />}

            </div>
        );
    }

    const handleComplete = (e) => {
        e.preventDefault();
        // alert("hello,i am clicked  ");

        console.log("project_id ====>", tempInfo?.project_id);
        console.log("task_flowtemplate_id=====>", tempInfo?.task_flowtemplate_id)

        var data = JSON.stringify({
            "project_id": tempInfo?.project_id,
            "task_flowtemplate_id": tempInfo?.task_flowtemplate_id
        });

        var configu = {
            method: 'post',
            url: `${config.apiBaseUrl}post/project_work_flow_status`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(configu)
            .then(function (response) {
                console.log(response.data);
                setIsComplete(1);
                if (IsComplete === 1) {
                    // alert("show color green");
                    console.log("complete color===>", response.data)
                    showSuccess(response.data.message, toast);
                    stepperShow();
                } else {
                    // alert("show color orrang");
                    stepperShow();
                }

            })
            .catch(function (error) {
                console.log(error);
            });


    }

    return (
        <>
            {/* Modal */}
            {modal === true ?
                <TimeModal style={{ borderRadius: "1%" }} isOpen={modal} stopTimer={stopTimer} submitClicked={submitClicked} />
                :
                null
            }

            {/* Left Card */}
            <Col md={3} sm={12} style={{ height: "742px" }}>
                <Card className="grid">
                    <CardBody style={{ padding: "6%", textAlign: "start" }}>
                        <div>
                            <Input style={{ marginTop: "3%" }} type="select" size="sm" className="owner_input">
                                <option>All</option>
                            </Input>
                        </div>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <label className="text-muted" style={{ margin: "0" }}>Start date</label><br></br><span>{allTaskInfo.length >= 0 ? allTaskInfo[0]?.startDate : null}</span>
                            </div>
                            <div>
                                <label className="text-muted" style={{ margin: "0" }}>End date</label><br></br><span>{allTaskInfo.length >= 0 ? allTaskInfo[0]?.endDate : null}</span>
                            </div>
                        </div>
                        <Divider />
                        <div className="progress-wrap progress-wrap--middle">
                            <label style={{ margin: "0" }}>Overall progress</label>
                            <Progress value={per}>{per}</Progress>
                        </div>

                        <Stepper orientation="vertical" className="h-50" style={{ overflowY: "scroll", padding: "0", marginTop: "2%" }}>
                            {allTaskInfo?.length >= 1 && allTaskInfo?.map((val, index) =>
                                <Step onClick={() => {
                                    taskShow(val?.task_flowtemplate_id)
                                    setShow(true)
                                    setSubmit(true)
                                    setPause(true)
                                    setMySubmit(false)
                                }} key={val?.task_flowtemplate_id} id={val?.task_flowtemplate_id} >
                                    <StepLabel StepIconComponent={(e) => QontoStepIcon(e, val?.color)}>{val?.taskflow_form[0]?.label}</StepLabel>
                                </Step>
                            )}
                        </Stepper>
                    </CardBody>
                </Card>
            </Col>

            {/* Right Card */}
            <Col md={9} sm={12} style={{ height: "742px" }}>
                <Card className="grid">
                    <CardBody style={{ padding: "3%", textAlign: "start" }}>
                        <div style={{ display: 'flex', justifyContent: "space-between" }}>
                            <h2 className="page-title">{tempInfo.length ? tempInfo?.taskflow_form[0].label : null}</h2>
                            <div>
                                <label>Estimeted time:<span className="text-muted"> 00:{"0" + estimTime}:00 </span> Hours</label>
                                <br></br>
                                {/* {additionalTime} {totalTime} */}
                                <label>Total time :<span className="text-muted"> 00:{totalTime}:00 </span></label><br></br>
                                <label>Additional Time :<span className="text-muted"> 00:{"0" + additionalTime ? additionalTime : "0"}:00 </span></label>
                            </div>
                        </div>
                        {/* <>
                            <div style={{ display: 'flex', justifyContent: "space-between", marginTop: "7%" }}>
                                <CardText><strong> Description</strong></CardText>
                            </div>
                            <CardText style={{ marginTop: "-2%" }} className="text-muted">{tempInfo.title_description}</CardText>
                            <CardText tag="h5"><strong>Remarks</strong></CardText>
                            <Input type="textarea" style={{ marginTop: "3%" }} onChange={e => setRemark(e.target.value)} /> 
                            </> */}
                        <form id="fb-render" className="fb-render">
                        </form>
                        <>
                            <h5 style={{ marginTop: "5%" }}>Worked By</h5>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div style={{ marginTop: "1%", display: "flex", justifyContent: "start" }}>
                                    <CardImg size="sm" src={profilelogo} style={{ width: '53px', height: "53px", borderRadius: "50%", marginTop: '2%' }} />
                                    <div>
                                        <CardText style={{ padding: "0" }}><span>{UserData?.first_name} {UserData?.last_name}</span></CardText>
                                        <label className="text-muted">{UserData?.role_name}</label><br></br>
                                        <label style={{ margin: "0" }}>10/08/2021<label style={{ marginLeft: "15px" }}>12.00PM</label></label>
                                    </div>
                                </div>
                                <div>
                                    {
                                        <h1 className="page-title" style={{ textAlign: "right" }}>
                                            <span>{(time.h >= 10) ? time.h : "0" + time.h}</span>&nbsp;:&nbsp;
                                            <span>{(time.m >= 10) ? time.m : "0" + time.m}</span>&nbsp;:&nbsp;
                                            <span>{(time.s >= 10) ? time.s : "0" + time.s}</span>
                                        </h1>
                                    }
                                    {/* <span>== {addTimeBtn} {submit}</span> */}
                                    <div style={{ display: "flex" }}>
                                        {show ?
                                            <ButtonToolbar>
                                                <Button size="sm" onClick={(e) => startTimer(e, tempInfo)}>Start Timer</Button>
                                            </ButtonToolbar>
                                            :
                                            <ButtonToolbar>
                                                {submit ?
                                                    <React.Fragment>
                                                        {pause ?
                                                            <Button size="sm" onClick={resumeTimer}>Resume Timer</Button>
                                                            :
                                                            <Button size="sm" className="btn-success" onClick={pauseTimer}>Pause Timer</Button>
                                                        }
                                                        <Button size="sm" onClick={stopTimer} >Stop Timer</Button>
                                                    </React.Fragment>
                                                    :
                                                    <React.Fragment>
                                                        {/* {addTimeBtn === true ?                                                                       
                                                        <>
                                                            <Button size="sm" onClick={(e) => addMoreTimeHandle(e)} >Add More Time</Button>
                                                            <span style={{ display: "flex", marginRight: "-160px" }}>
                                                                <Input type="number" style={{ width: "18%", alignItems: "center" }} onChange={e => setAddTime(e.target.value)} />
                                                                <label style={{ marginLeft: "2%" }}> Hours</label>
                                                            </span>

                                                            <Button size="sm">Submit</Button>
                                                        </>
                                                        :
                                                        <React.Fragment>
                                                          
                                                        </React.Fragment>

                                                    } */}
                                                        {addTimeBtn ?
                                                            <>
                                                                {
                                                                    myCondition ?
                                                                        <React.Fragment>
                                                                            <Button size="sm" onClick={(e) => addMoreTimeHandle(e, tempInfo)} >Add More Time</Button>
                                                                            <span style={{ display: "flex", marginRight: "-130px" }}>
                                                                                <Input type="number" style={{ width: "30%", alignItems: "center" }} onChange={e => setAddTime(e.target.value)} />
                                                                                <label style={{ marginLeft: "2%" }}> Hours</label>
                                                                            </span>
                                                                        </React.Fragment> :
                                                                        null

                                                                }

                                                                <Button size="sm" onClick={() => handleSubmit(tempInfo)} >Submit</Button>
                                                                <Button size="sm" className="btn-success" onClick={(e) => handleComplete(e)} disabled={!mySubmit ? true : false}>Complete</Button>
                                                            </>
                                                            :
                                                            <React.Fragment>
                                                            </React.Fragment>

                                                        }

                                                    </React.Fragment>
                                                }

                                            </ButtonToolbar>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    </CardBody>
                </Card>
            </Col>
            <Toast />
        </>
    )
}
export default OverAllProgress;