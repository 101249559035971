import React from 'react';
import { Route } from 'react-router-dom';
import Layout from '../../../Layout/index';
// import Finance from './Finance';
import OnLineMarketingDashboard from '../../../Dashboards/OnLineMarketing/index';
import AppDashboard from '../../../Dashboards/App/index';
// import ProposalDashboard from '../../../Dashboards/Booking/index';
// import FitnessDashboard from '../../../Dashboards/Fitness/index';
import Forms from './Forms';
import Account from './Account';
import { Redirect } from 'react-router-dom';
import LogIn from '../../../Account/LogIn';
import ProposalList from '../../../Dashboards/Booking/components/ProposalList/index';
import IndexProposal from '../../../Dashboards/Booking/components/ProposalDetelis/IndexProposal'
import IndexCreateNewProject from '../../../Dashboards/Finance/components/NewProjectFrom/IndexCreateNewProject';
import IndexLittelLamb from '../../../Dashboards/Finance/components/LittelLamb/IndexLittelLamb';
import Kanban from '../../../Dashboards/Finance/components/LittelLamb/Kanban';
import SubProject from '../../../Dashboards/Finance/components/Projects/SubProject';
import Settings from "../../../Settings/SettingsMenu/index";
import Templates from "../../../Settings/Templates";
import TaskFlows from "../../../Settings/TaskFlows";
import UserManagement from "../../../Settings/UserManagement";
import Announcements from "../../../Settings/Announcements";
import AddNewTaskFlow from "../../../Settings/TaskFlows/components/AddNewTaskFlow";
import EditTaskClass from '../../../Settings/TaskFlows/components/EditTaskClass';
import AllTemplate from '../../../Settings/Templates/components/AllTemplate';
import Projects from '../../../Dashboards/Finance/components/Projects/Projects';
import Issue from '../../../Issues/Issues';
import DachBordAnnoun from '../../../Settings/Announcements/components/DachBordAnnoun';
import Roles from "../../../Dashboards/Roles/Roles";
import SelectRoles from "../../../Dashboards/Finance/components/Projects/SelectRoles";
import ProjectReport from "../../../Dashboards/Finance/components/Projects/ProjectReport";
import Edit from '../../../Settings/Templates/components/Edit';


const WrappedRoutes = () => {
  if (localStorage.getItem("token") === "" || localStorage.getItem("token") === null) {

    return <Redirect to='/login' component={LogIn} />
  }
  return (
    <div>
      <Layout />
      <div className="container__wrap">
        <Route path="/online_marketing_dashboard" component={OnLineMarketingDashboard} />
        <Route path="/app_dashboard" component={AppDashboard} />
        <Route path="/proposal" component={ProposalList} />
        <Route path="/select_roles" component={SelectRoles} />
        <Route path="/project_report" component={ProjectReport} />
        <Route path="/proposal_details" component={IndexProposal} />
        <Route path="/project_create_new_project" component={IndexCreateNewProject} />
        <Route path="/projects" component={Projects} />
        <Route path="/littel_lamb" component={IndexLittelLamb} />
        <Route path="/kanban_view" component={Kanban} />
        <Route path="/book_volume" component={SubProject} />
        <Route path="/issues" component={Issue} />
        <Route path="/announcement" component={DachBordAnnoun} />
        <Route path="/roles" component={Roles} />
        {
          /*
          <Route path="/finance_dashboard" component={Finance} />
          <Route path="/fitness_dashboard" component={FitnessDashboard} />
          */
        }
        <Route path="/settings" component={Settings} />
        <Route path="/templates" component={Templates} />
        <Route path="/all_templates" component={AllTemplate} />
        <Route path="/edit/*" component={Edit} />
        <Route path="/task-flows" component={TaskFlows} />
        <Route path="/user_management" component={UserManagement} />
        <Route path="/announcements" component={Announcements} />
        <Route path="/new_task_flows" component={AddNewTaskFlow} />
        <Route path="/template_edit_class" component={EditTaskClass} />
        <Route path="/forms" component={Forms} />
        <Route path="/account" component={Account} />
      </div>
    </div>
  );

}


export default WrappedRoutes