import React, { useState } from 'react';
import axios from 'axios';
import { Button, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import './NewProjectFrom.css';
import UserData from '../../../../../config/UserInfo';
import config from '../../../../../config/index';
import { toast } from 'react-toastify';
import { Toast, showError, showSuccess } from "../../../../../shared/components/Toast/Toast";
import 'react-toastify/dist/ReactToastify.css';

const ModalView = ({ isOpen, newTemplate, list }) => {
    const [templateName, setTemplateName] = useState('');
    console.log(list);
    let userInfo = localStorage.getItem("token");
    const handleSave = () => {
        try {
            var data = JSON.stringify({
                "task_flow_name": templateName,
                "created_by": UserData?.id,
                "ids": list
            });

            var confi = {
                method: 'post',
                url: (`${config.apiBaseUrl}post/save_new_templete`),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + userInfo

                },
                data: data
            };

            axios(confi)
                .then(async (response) => {
                    await console.log(response.data.status);
                    if (response.data.status === 1) {
                        showSuccess('New templete added successfully', toast);
                        setTimeout(() => newTemplate(), 1000);
                    } else if (response.data.status === 0) {
                        showError('This name already exists', toast);
                    }
                })
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>

            <Modal className="model-content" isOpen={isOpen} >
                <div className="modal-content" style={{ display: "flex", alignItems: "flex-end" }}>
                    <CloseCircleIcon style={{ color: "#8D9CA6" }} onClick={newTemplate} />
                </div>
                <ModalHeader style={{ borderBottom: "0" }} className="d-flex flex-row bd-highlight" >NEW TEMPLATE</ModalHeader>
                <ModalBody>
                    <label style={{ display: "flex", alignItems: "flex-start" }}>Enter Name</label>
                    <Input type="text" onChange={e => setTemplateName(e.target.value)} />
                </ModalBody>
                <div className="d-flex flex-row-reverse bd-highlight">
                    <Button style={{ width: "20%", marginRight: "3%", backgroundColor: "#76CA4C" }} color="success" size="sm" onClick={handleSave}>Save</Button>
                </div>
            </Modal>
            <Toast />
        </>
    )
}

export default ModalView
