import React from 'react';
import {Card} from 'reactstrap';
import '../../style/ProposalDetelis.css';

const  ProposalPdf = ({data}) => {

    return (
        <div>
        <div className="Preview">
        <Card className="contentdiv">
            <h3 className="propasal">Propasal: SACRED ACTIONS</h3>
            <div className="Book_Deatilas">
                <h1>Contact Details</h1>
                <div className="content">
                    <div className="content_left">
                        <h5 className="bold-text">Name</h5><span>{data.name}</span>
                        <h5 className="bold-text">Cell Phone</h5><span>{data.cellno}</span>
                        <h5 className="bold-text">Home Phone</h5><span>{data.homephon}</span>
                        <h5 className="bold-text">Work Phone</h5><span>{data.homephon}</span>
                    </div>
                    <div className="content_right" style={{marginLeft:"50%"}}>
                        <h5 className="bold-text">Email Address</h5><span>{data.email}</span>
                        <h5 className="bold-text">Mailing Address</h5><span>{data.address}</span>
                        <h5 className="bold-text">Website</h5><span>{data.Website}</span>
                        <h5 className="bold-text">Social Media Handel</h5><span>{data.media_handle}</span>
                    </div>
                </div>
            </div>
            <div className="Book_Deatilas">
                <h1>Book Concept</h1>
                <div className="content_list">
                    <div className="content_left">
                        <h4 className="bold-text">Proposed Title</h4><span>{data.title}</span>
                        <h5 className="bold-text">What is the idea of book you are proposing?</h5><span>{data.idea}</span>
                        <h5 className="bold-text">Author Bio</h5><span>{data.author_bio}</span>
                        <h5 className="bold-text">What about the subject matters that will drow release</h5><span>{data.matters}</span>
                    </div>
                    <div className="content_right">
                        <h5 className="bold-text">Elebetor Pitch</h5><span>{data.pitch}</span>
                        <h5 className="bold-text">What are the reasons to belive in the project</h5><span>{data.reasons}</span>
                        <h5 className="bold-text">Why are you the right person to write about this project</h5><span>{data.right_about}</span>
                    </div>
                </div>
            </div>
            <div>
                <h1>Target Audience</h1>
                <div className="content_list">
                    <div className="content_left">
                        <h5 className="bold-text">who is your Target readres</h5><span>{data.target}</span>
                        <h5 className="bold-text">What is the demographic</h5><span>{data.demographic}</span>
                    </div>
                    <div className="content_right">
                        <h5 className="bold-text">What is the Market</h5><span>{data.market}</span>
                    </div>
                </div>
            </div>
            <div>
                <h1>Benefits & Features</h1>
                <div className="content_list">
                    <div className="content_left">
                        <h5 className="bold-text">What is the benefits and feature?</h5><span>{data.benefits}</span>
                        <h5 className="bold-text">What is the important message?</h5><span>{data.imp_msg}</span>
                        <h5 className="bold-text">How will it influence in your life?</h5><span>+The product team was so fixated on the trademarked technology that they neglected to consider why someone would pay $600 for a microwave. </span>
                    </div>
                    <div className="content_right">
                        <h5 className="bold-text">What is the render gain from reading your book or using your deck?</h5><span>{data.gain}</span>
                        <h5 className="bold-text">Does it sovle a problem?</h5><span>{data.solve}</span>
                        <h5 className="bold-text">What is your goal?</h5><span>{data.goal}</span>
                    </div>
                </div>
            </div>
            <div>
                <h1>Content Details</h1>
                <div className="content_list">
                    <div className="content_left">
                        <h5 className="bold-text">What are your desired spece for the project</h5><span>{data.desired}</span>
                        <h5 className="bold-text">in this a or oracle deck?</h5><span>What about this particular microwave would make their lives easier or better than a cheaper version that could arrive at their doorstep within 24 hours? And why should they place their trust and hard-earned income in your hands instead of the other brands on the block</span>
                        <h5 className="bold-text">Propased table of contents or outline:</h5><span>{data.table}</span>
                        <h5 className="bold-text">Approximate word count</h5><span>{data.count}</span>
                        <h5 className="bold-text">How many images or illustration do you plan to include</h5><span>{data.copies}</span>
                    </div>
                    <div className="content_right">
                        <h5 className="bold-text">Kindly share the following</h5><span>{data.share}</span>
                        <h5 className="bold-text">Does it sovle a problem?</h5><span>{data.solve}</span>
                        <h5 className="bold-text">files attached</h5><span><input className="Input_file" type="text"></input></span>
                    </div>
                </div>
            </div>
            <div>
                <h1>Marketing and Competitors</h1>
                <div className="content_list">
                    <div className="content_left">
                        <h5 className="bold-text">Whom do tou see as your competitors?</h5><span>{data.competition}</span>
                        <h5 className="bold-text">Have you Authored other books</h5><span>{data.other_book}</span>
                        <h5 className="bold-text">who will help you promot and sell your books?</h5><span>{data.promote}</span>
                        <h5 className="bold-text">How many pepole follow your book?</h5><span>{data.people_follow}</span>
                        <h5 className="bold-text">How does the book fit in your business plan, brand and seles statergy?</h5><span>{data.fit}</span>
                        <h5 className="bold-text">Where do you see your book being reviewed, marketed and sold?</h5><span>{data.sold}</span>
                    </div>
                    <div className="content_right">
                        <h5 className="bold-text">How ir ypour idea diffrent</h5><span>{data.your_idea}</span>
                        <h5 className="bold-text">Have you recived any reviews or endorsement?</h5><span>{data.endorsements}</span>
                        <h5 className="bold-text">What organigation, clubs, guilds or other groups are you involved with?</h5><span>{data.involved}</span>
                        <h5 className="bold-text">Do you plan on selling copies on your books?</h5><span>{data.plan}</span>
                    </div>
                </div>
            </div>
            <div className="Book_Deatilas">
                <h1>Why Schiffer?</h1>
                <div className="content_list">
                    <div className="content_left">
                        <h5 className="bold-text">why did you shift your project on schiffer?</h5><span>{data.why_schiffer}</span>
                    </div>
                </div>
            </div>
        </Card>
    </div>
        </div>
    )
}

export default ProposalPdf
