import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import axios from 'axios';
import config from "../../../../../config/index";
import UserData from '../../../../../config/UserInfo';
import { toast } from 'react-toastify';
import { Toast, showSuccess } from "../../../../../shared/components/Toast/Toast";
import 'react-toastify/dist/ReactToastify.css';
import jwt_decode from "jwt-decode";

const IssueModal = ({ isOpen, newTemplate, proId, user }) => {
    const [descriiption, setDescription] = useState("");
    const [issueError, setIssueError] = useState("");

    const isuuesSave = () => {
        if (descriiption.length === 0) {
            setIssueError("Filed Should Not Be Blank");
        } else if (descriiption.lengthlength > 1) {
            setIssueError("");
        }
        if (!issueError && descriiption) {
            try {
                var data = JSON.stringify({
                    "user_id": user,
                    "project_id": proId,
                    "issue_discription": descriiption
                });
                var confi = {
                    method: 'post',
                    url: `${config.apiBaseUrl}post/issues`,
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: data
                };
                axios(confi)
                    .then((response) => {
                        console.log("issue trigger----------->", response.data.status);
                        if (response.data.status === 1) {
                            showSuccess('Issue raised successfully', toast);
                        }
                        setTimeout(newTemplate, 3000);
                    })
            } catch (error) {
                console.log(error);
            }
        }
    }

    return (
        <>
            <Modal className="model-content" isOpen={isOpen} >
                <div className="modal-content" style={{ display: "flex", alignItems: "flex-end" }}>
                    <CloseCircleIcon style={{ color: "#8D9CA6" }} onClick={newTemplate} />
                </div>
                <ModalHeader style={{ borderBottom: "0" }} className="d-flex flex-row bd-highlight" >Trigger issue</ModalHeader>
                <ModalBody>
                    <label style={{ display: "flex", alignItems: "flex-start" }}>Enter issue</label>
                    <Input type="textarea"
                        onChange={(e) => {
                            setDescription(e.target.value);
                            if (e.target.value.length === 0) {
                                setIssueError("Filed Should Not Be Blank");
                            } else if (e.target.value.length > 1) {
                                setIssueError("");
                            }
                        }}
                    />
                    <p style={{ color: 'red' }}>{issueError}</p>
                </ModalBody>
                <div className="d-flex flex-row-reverse bd-highlight">
                    <Button style={{ width: "20%", marginRight: "3%", backgroundColor: "#76CA4C" }} color="success" size="sm" onClick={isuuesSave}>Save</Button>
                </div>
            </Modal>
            <Toast />
        </>
    )
}

export default IssueModal
