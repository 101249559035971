import React, { useState, useEffect } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CardBody, Progress, Button } from "reactstrap";
import uuid from "uuid/v4";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import IconButton from "@material-ui/core/IconButton";
import { useHistory, useLocation } from "react-router-dom";
import config from '../../../../../config/index';


const Kanban = () => {
	const [startTask, setStartStask] = useState([])
	const [inProgressTask, setInProgressStask] = useState([])
	const [completedTask, setCompletedStask] = useState([])
	let location = useLocation()
	let queryString = new URLSearchParams(location.search);
	let proId = queryString.get("_id");

	let localUser = localStorage.getItem("authuser");
	const Auth = JSON.parse(localUser)


	useEffect(() => {
		try {
			var axios = require('axios');
			var data = JSON.stringify({
				"project_id": proId
			});
			var confi = {
				method: 'post',
				url: `${config.apiBaseUrl}post/user_canban`,
				headers: {
					'Authorization': 'Bearer ' + Auth?.token,
					'Content-Type': 'application/json'
				},
				data: data
			};
			axios(confi)
				.then((response) => {
					let startList = []
					let inprogress = []
					let completed = []
					response.data?.start?.forEach((element, index) => {
						element.taskflow_form[0].id = element.task_flowtemplate_id + '_' + uuid();
						element.taskflow_form[0].content = element.taskflow_form[0].label;
						startList.push(element.taskflow_form[0])
					});
					response.data?.inprogress?.forEach((element, index) => {
						element.taskflow_form[0].id = element.task_flowtemplate_id + '_' + uuid();
						element.taskflow_form[0].content = element.taskflow_form[0].label;
						inprogress.push(element.taskflow_form[0])
					});
					response.data?.completed?.forEach((element, index) => {
						element.taskflow_form[0].id = element.task_flowtemplate_id + '_' + uuid();
						element.taskflow_form[0].content = element.taskflow_form[0].label;
						completed.push(element.taskflow_form[0])
					});
					setStartStask(startList);
					setCompletedStask(completed);
					setInProgressStask(inprogress);
				})
		} catch (error) {
			console.log(error);
		}
	}, []);

	useEffect(() => {
		setColumns(columnsFromBackend)
	}, [startTask, inProgressTask, completedTask]);


	const columnsFromBackend = {
		[uuid()]: {
			name: "OPEN",
			items: startTask
		},
		[uuid()]: {
			name: "IN PROGRESS",
			items: inProgressTask
		},
		[uuid()]: {
			name: "COMPLETE",
			items: completedTask
		}
	};


	// const columnsFromBackend = {
	// 	[uuid()]: {
	// 		name: "OPEN",
	// 		items: allTask?.start
	// 	},
	// 	[uuid()]: {
	// 		name: "IN PROGRESS",
	// 		items: allTask?.inprogress
	// 	},
	// 	[uuid()]: {
	// 		name: "COMPLETE",
	// 		items: allTask?.completed
	// 	}
	// };

	const onDragEnd = (result, columns, setColumns) => {
		if (!result.destination) return;
		const { source, destination } = result;

		if (source.droppableId !== destination.droppableId) {
			const sourceColumn = columns[source.droppableId];
			const destColumn = columns[destination.droppableId];
			const sourceItems = [...sourceColumn.items];
			const destItems = [...destColumn.items];
			const [removed] = sourceItems.splice(source.index, 1);
			destItems.splice(destination.index, 0, removed);
			setColumns({
				...columns,
				[source.droppableId]: {
					...sourceColumn,
					items: sourceItems
				},
				[destination.droppableId]: {
					...destColumn,
					items: destItems
				}
			});
		} else {
			const column = columns[source.droppableId];
			const copiedItems = [...column.items];
			const [removed] = copiedItems.splice(source.index, 1);
			copiedItems.splice(destination.index, 0, removed);
			setColumns({
				...columns,
				[source.droppableId]: {
					...column,
					items: copiedItems
				}
			});
		}
	};




	const [columns, setColumns] = useState(columnsFromBackend);
	const [completedPercentage, setCompletedPercentage] = useState(0);
	
	let history = useHistory();

	useEffect(() => {
		// All Tasks
		const allTasks = []
		Object.entries(columns).map(([columnId, column], index) => {
			
			column.items.map(each => {
				allTasks.push(each)
			})
		})

		// Completed Tasks
		const completedTasks = []
		Object.entries(columns).map(([columnId, column], index) => {
			if (index === 2) {
				column.items.map(each => {
					completedTasks.push(each)
				})
			}
		})

		// Percentage
		setCompletedPercentage((completedTasks.length / allTasks.length) * 100)

	}, [columns]);


	const gotoTimeLine = () => {
		history.push('/littel_lamb')
	}



	return (
		<React.Fragment>
			{/* Progress Bar */}
			<div className="btn-group--icons d-flex align-items-center" style={{ justifyContent: "space-between" }}>
				<div className="btn-group--icons d-flex align-items-center">
					<IconButton aria-label="backward">
						<ArrowLeftIcon onClick={() => history.goBack()} />
					</IconButton>
					<h4 className="ml-2 font-weight-bold">TITLES</h4>
				</div>

				<div style={{ marginRight: "3%" }}>
					<Button className="p-2 btn-success" color="secondary" onClick={gotoTimeLine} >Switch to TimeLine view</Button>
				</div>
			</div>

			<span style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
				<Progress style={{ width: "80%" }} value={completedPercentage.toFixed(2)}>{completedPercentage.toFixed(2)}</Progress>
			</span>

			<div style={{ display: "flex", justifyContent: "center", height: "100%", marginTop: '3%' }}>
				<DragDropContext onDragEnd={result => onDragEnd(result, columns, setColumns)} >

					{/* All Columns */}
					{Object.entries(columns).map(([columnId, column], index) => {
						return (
							// Each Column
							<div style={{ display: "flex", flexDirection: "column", alignItems: "center" }} key={columnId} >
								<CardBody style={{ marginRight: "20px" }}>
									<h4>{column.name}</h4>
									<div>
										<Droppable droppableId={columnId} key={columnId}>
											{(provided, snapshot) => {
												return (
													<div
														{...provided.droppableProps}
														ref={provided.innerRef}
														style={{
															background: snapshot.isDraggingOver,
															padding: 4,
															width: 250,
															minHeight: 500
														}}
													>
														{column.items?.map((item, index) => {
															return (
																<Draggable
																	key={item.id}
																	draggableId={item.id}
																	index={index}
																>
																	{(provided, snapshot) => {
																		return (
																			<div
																				ref={provided.innerRef}
																				{...provided.draggableProps}
																				{...provided.dragHandleProps}
																				style={{
																					userSelect: "none",
																					padding: 16,
																					margin: "0 0 8px 0",
																					minHeight: "100px",
																					borderRadius: "5%",
																					backgroundColor: snapshot.isDragging
																						? "#FFEDED"
																						: "#FFEDED",
																					color: "#3D3D3D",
																					...provided.draggableProps.style
																				}}
																			>
																				{item.content}
																			</div>
																		);
																	}}
																</Draggable>
															);
														})}
														{provided.placeholder}
													</div>
												);
											}}
										</Droppable>
									</div>
								</CardBody>
							</div>
						);
					})}
				</DragDropContext>
			</div>
		</React.Fragment>
	);
}

export default Kanban;
