import React, { useState, useEffect } from "react";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { useHistory, useLocation } from "react-router-dom";
import axios from 'axios';
import {
    Form,
    Container,
    Button,
    CardBody,
    FormGroup,
    Label,
    Input,
    Col,
    Row,
    CardImg
} from "reactstrap";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import IconButton from '@material-ui/core/IconButton';
import PencilCircleOutlineIcon from 'mdi-react/PencilCircleOutlineIcon';
import config from '../../../../config/index';
import ModalView from './RoleModel';
import AddImprint from './Imprintmodal';
import { toast } from 'react-toastify';
import { Toast, showError, showSuccess } from "../../../../shared/components/Toast/Toast";
import 'react-toastify/dist/ReactToastify.css';



const AddUser = (props) => {
    let history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const user_Id = params.get('id');
    let UserInfo = [];
    // UserInfo = props.location.state?.userData ? props.location.state?.userData[0] : [];
    const [impModal, setImpModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [isSubmit, setIsSubmit] = useState(false);
    const [firstName, setFirstname] = useState("");
    const [lastName, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [empType, setEmpType] = useState('');
    const [imprint, setImprint] = useState('');
    const [rolesData, setrolesData] = useState('');
    const [profile, setProfile] = useState('');
    const [profileImage, setProfileImage] = useState('');
    const [imprintid, setimprintId] = useState([]);
    const [userId, setUserId] = useState(user_Id);
    const [role, setRole] = useState([])
    let userInfo = localStorage.getItem("token");




    useEffect(() => {
        roleAdd()
        imprintAdd()
        userInformation()
    }, [])


    // for the get user info 
    const userInformation = () => {
        var data = JSON.stringify({
            "id": userId
        });
        var confi = {
            method: 'post',
            url: `${config.apiBaseUrl}post/user_information`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + userInfo

            },
            data: data
        };
        axios(confi)
            .then(async (response) => {
                response.data.Data?.map((items) => {
                    let roles_id_array = items.role_name ? items.role_name.split(',') : [];
                    items['roleArry'] = roles_id_array
                })
                UserInfo = response?.data?.Data
                setFirstname(response?.data?.Data[0]?.first_name)
                setLastname(response?.data?.Data[0]?.last_name)
                setEmail(response?.data?.Data[0]?.email)
                setMobile(response?.data?.Data[0]?.mobile)
                setEmpType(response?.data?.Data[0]?.employee_type)
                setProfileImage(response?.data?.Data[0]?.attachment)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //get roles list
    const roleAdd = () => {
        var conf = {
            method: 'get',
            url: `${config.apiBaseUrl}get/all_role_list`,
            headers: {
                'Authorization': "Bearer " + userInfo
            }
        };
        axios(conf)
            .then((response) => {
                setrolesData(response.data.Data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    //get imprint list
    const imprintAdd = () => {
        var con = {
            method: 'get',
            url: `${config.apiBaseUrl}get/all_imprint_list`,
            headers: {
                'Authorization': "Bearer " + userInfo
            }
        };
        axios(con)
            .then((response) => {
                setImprint(response.data.Data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    // validation to all fileds
    const validate = (firstName, lastName, email, mobile, empType, profileImage) => {
        const errors = {};
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;//for email
        const regexformobile = /^\d+$/; // for numbers
        if (!firstName) {
            errors.firstName = "First name is required!";
        }

        if (!lastName) {
            errors.lastName = "Last name is required!";
        }
        if (!email) {
            errors.email = "Email is required!";
        } else if (!regex.test(email)) {
            errors.email = "This is not a valid email format!";
        }
        if (!mobile) {
            errors.mobile = "Mobile number is required";
        } else if (mobile?.length !== 10) {
            errors.mobile = "Mobile number must be 10 digit";
        } else if (!regexformobile.test(mobile)) {
            errors.mobile = "Mobile number digit only";
        }
        if (!empType) {
            errors.empType = "Select Employee type";
        }
        if (!profileImage) {
            errors.profileImage = "Select profileImage image";
        }
        return errors;
    };

    useEffect(() => {
        if (Object.keys(formErrors).length === 0 && isSubmit) {
            saveUserInfo()
        }
    }, [formErrors]);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(firstName, lastName, email, mobile, empType, profileImage));
        setIsSubmit(true);
    };



    const saveUserInfo = () => {
        setImprintHandle()
        var data = JSON.stringify({
            "id": userId || "",
            "first_name": firstName,
            "email": email,
            "last_name": lastName,
            "mobile": mobile,
            "attachment": profileImage,
            "employee_type": empType,
            "role": role,
            "imprint_id": imprintid
        });


        var confi = {
            method: 'post',
            url: `${config.apiBaseUrl}post/create_user_profile`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };
        axios(confi)
            .then((response) => {
                if (response.data.status === 1) {
                    showSuccess('Saved Successfully', toast);
                }

                else if (response.data.status === 0) {
                    showError('E-mail already in use', toast);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const fileSaver = (e) => {
        e.preventDefault();

        const reader = new FileReader();
        // reader.onload = () => {
        //     if (reader.readyState === 2) {
        //         setProfile(reader.result)
        //     }
        // }
        reader?.readAsDataURL(e.target.files[0])

        // ============================================================

        var formdata = new FormData();
        formdata.append("file", e.target.files[0]);
        var requestOptions = {
            method: 'POST',
            // headers: myHeaders,
            body: formdata,
            redirect: 'follow'
        };
        fetch(`${config.apiBaseUrl}upload/`, requestOptions)
            .then(response => response.text())
            .then(result => setProfileImage(JSON.parse(result).url))
            .catch(error => console.log('error', error));
        // ==============================================================
    }

    const setImprintHandle = (e) => {
        console.log(e?.target.checked)
        if (e?.target.checked === true) {
            imprintid.push(e?.target.id);
        }
        if (e?.target.checked === false) {
            setimprintId(imprintid.filter(item => item !== e?.target.id))
        }
        var data = JSON.stringify({
            "imprint_id": imprintid,
        });
        var conig = {
            method: 'post',
            url: `${config.apiBaseUrl}post/insert_user_imprints`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': "Bearer " + userInfo
            },
            data: data
        };
        axios(conig)
            .then((response) => {
                // console.log("chechimprint", response.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const newRole = () => {
        setModal(!modal);
        roleAdd()
    }
    const newImp = () => {
        setImpModal(!impModal)
        imprintAdd()
    }


    const handlerole = (e) => {
        if (e.target.checked === true) {
            role.push(e.target.id);
        }
        if (e.target.checked === false) {
            setRole(role.filter(item => item !== e.target.id))
        }
        // if (email != undefined) {
        //     if (e.target.checked === true) {
        //         var data = JSON.stringify({
        //             "email": email,
        //             "role_id": e.target.id
        //         });
        //         var fect = {
        //             method: 'post',
        //             url: `${config.apiBaseUrl}post/insert_user_role`,
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Authorization': "Bearer " + userInfo
        //             },
        //             data: data
        //         };
        //         axios(fect)
        //             .then((response) => {
        //                 console.log(JSON.stringify(response.data));
        //                 setUserId(response.data.userID)
        //             })
        //             .catch(function (error) {
        //                 console.log(error);
        //             });
        //     } else if (e.target.checked === false) {
        //         var Userdata = JSON.stringify({
        //             "email": email,
        //             "role_id": e.target.id
        //         });
        //         var fecth = {
        //             method: 'post',
        //             url: `${config.apiBaseUrl}post/delete_user_role`,
        //             headers: {
        //                 'Content-Type': 'application/json',
        //                 'Authorization': "Bearer " + userInfo
        //             },
        //             data: Userdata
        //         };
        //         axios(fecth)
        //             .then((response) => {
        //                 setUserId(response.data.userID)
        //             })
        //             .catch(function (error) {
        //                 console.log(error);
        //             });
        //     }
        // }
    }

    const handleCancel = () => {
        history.push("/user_management");
    }


    return (
        <>
            {impModal === true ? <AddImprint style={{ borderRadius: "1%" }} isOpen={impModal} newImp={newImp} /> : null}
            {modal === true ? <ModalView style={{ borderRadius: "1%" }} isOpen={modal} newRole={newRole} /> : null}
            <div className="d-flex" style={{ display: "flex", justifyContent: "space-between" }}>
                <div className="btn-group--icons d-flex align-items-center mr-auto">
                    <IconButton aria-label="backward">
                        <ArrowLeftIcon onClick={() => history.goBack()} />
                    </IconButton>
                    <h3 className="ml-2 font-weight-bold" >ADD NEW USER</h3>
                </div>
            </div>
            <CardBody>
                <Row>
                    <Col xs={12} md={12} lg={12} xl={6}>
                        <h5 className="font-weight-bold">Personal Information</h5>
                        <Container style={{ marginTop: '3%' }}>
                            <Row xs="2">
                                <Col style={{ backgroundColor: "#FFEDED", display: 'flex', justifyContent: 'center', alignItems: "center" }}>
                                    <input
                                        accept="image/*"
                                        className="input"
                                        id="contained-button-file"
                                        multiple
                                        type="file"
                                        onChange={fileSaver}
                                        style={{ display: 'none' }}
                                    />
                                    <div style={{ height: '20vh', width: '20vh', borderRadius: "50%", display: 'flex', alignItems: "flex-end", justifyContent: "center" }}>
                                        {profileImage === '' ? <label htmlFor="contained-button-file" style={{ height: '20vh', width: '20vh', backgroundColor: '#FFFFFF', borderRadius: "50%", display: 'flex', alignItems: "flex-end", justifyContent: "right" }}><PencilCircleOutlineIcon style={{ height: "25%", width: "25%", color: "#808080" }} /></label> : null}
                                        {profileImage === '' ? null : <label htmlFor="contained-button-file" style={{ height: '20vh', width: '20vh', backgroundColor: '#FFFFFF', borderRadius: "50%", display: 'flex', alignItems: "flex-end", justifyContent: "right" }}><CardImg bsSize="sm" src={profileImage} style={{ height: '20vh', width: '20vh', borderRadius: "50%" }} /><PencilCircleOutlineIcon style={{ height: "25%", width: "25%", color: "#808080", marginLeft: "-24%" }} /></label>}
                                    </div>
                                </Col>

                                <Col className="md-form">
                                    <Form>
                                        <FormGroup>
                                            <TextField id="standard-basic" label="First name" variant="standard" onChange={e => setFirstname(e.target.value)} InputLabelProps={{ style: { fontSize: 12 } }} value={firstName} maxLength={12} />
                                            <p className='text-danger'>{formErrors?.firstName}</p>
                                            <TextField id="standard-basic" label="Last name" variant="standard" onChange={e => setLastname(e.target.value)} InputLabelProps={{ style: { fontSize: 12 } }} value={lastName} maxLength={12} />
                                            <p className='text-danger'>{formErrors?.lastName}</p>
                                            <TextField id="standard-basic" label="Email id" variant="standard" onChange={e => setEmail(e.target.value)} InputLabelProps={{ style: { fontSize: 12 } }} value={email} />
                                            <p className='text-danger'>{formErrors?.email}</p>
                                            <TextField id="standard-basic" label="Mobile Number" variant="standard" onChange={e => setMobile(e.target.value)} InputLabelProps={{ style: { fontSize: 12 } }} value={mobile} />
                                            <p className='text-danger'>{formErrors?.mobile}</p>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </Row>
                            <p className='text-danger'>{formErrors?.profileImage}</p>
                            <FormGroup style={{ marginTop: '5%' }}>
                                <h6 className="font-weight-bold" htmlFor="titlename">Employee Type</h6>
                                <Input type="select" size='sm' value={empType} onChange={e => setEmpType(e.target.value)} style={{ width: "37%", marginTop: "3%" }} >
                                    <option value="" defaultValue>Employee Type</option>
                                    <option value="Tepmorary">Tepmorary</option>
                                    <option value="Permanent">Permanent</option>
                                </Input>
                                <p className='text-danger'>{formErrors?.empType}</p>
                            </FormGroup>

                        </Container>
                    </Col>
                    <Col xs={12} md={12} lg={12} xl={6}>
                        <FormGroup>
                            <div style={{ display: "flex", justifyContent: 'space-between' }}>
                                <div>
                                    <h5 className="font-weight-bold">Roles Assing</h5>
                                </div>
                                <div style={{ alignItems: "flex-end" }}>
                                    <Button size="sm" outline color="secondary" onClick={newRole}>Add New Role</Button>
                                </div>
                            </div>
                            <Row>
                                {rolesData?.length === 0 ? <h4 style={{ textAlign: "center" }}>No Role Assing </h4> : rolesData?.length && rolesData.map((val, index) =>
                                    <Col >
                                        <div className="d-flex" key={index}>
                                            <label style={{ width: "12vw" }}>{val.role_name}</label>
                                            <Checkbox name="isCurrent" id={val.role_id} color="default" defaultChecked={UserInfo.roleArry?.find((items) => items === val.role_name ? true : false) === val.role_name ? true : false} onChange={handlerole} />
                                        </div>
                                    </Col>
                                )}
                            </Row>
                        </FormGroup>
                        <div className="divider"><hr /></div>
                        {/* <FormGroup>
                            <div>
                                <h5 className="font-weight-bold">User Access</h5>
                            </div>
                            <div className="d-flex justify-content-around" style={{ marginTop: "2%" }}>
                                <label>Master access</label>
                                <FormGroup check>
                                    {UserInfo.view === 1 || UserInfo.view === "1" ?
                                        <Label>
                                            <Checkbox name="isCurrent" defaultChecked={true} label="Current" color="default" onClick={e => setview(e.target.checked)} />
                                            View
                                        </Label>
                                        : <Label>
                                            <Checkbox name="isCurrent" label="Current" color="default" onClick={e => setview(e.target.checked)} />
                                            View
                                        </Label>}

                                </FormGroup>
                                <FormGroup check>
                                    {UserInfo.view === 1 || UserInfo.view === "1" ?
                                        <Label>
                                            <Checkbox name="isCurrent" defaultChecked={true} label="Current" color="default" onClick={e => setEdit(e.target.checked)} />
                                            Edit
                                        </Label>
                                        : <Label>
                                            <Checkbox name="isCurrent" label="Current" color="default" onClick={e => setEdit(e.target.checked)} />
                                            Edit
                                        </Label>}
                                </FormGroup>
                            </div>
                        </FormGroup> */}
                        {/* <div className="divider"><hr /></div> */}
                        <FormGroup>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <div>
                                    <h5 className="font-weight-bold">Imprints</h5>
                                </div>
                                <div>
                                    <Button size="sm" outline color="secondary" onClick={newImp}>Add New imprint</Button>
                                </div>
                            </div>

                            {imprint?.length === 0 ? <h4 style={{ textAlign: "center" }}>No imprint</h4> : imprint?.length && imprint.map((val, index) =>
                                <div key={index} className="d-flex justify-content-around">
                                    <label style={{ width: "15%" }}>{val.imprints}</label>
                                    <FormGroup check>
                                        <Label check>
                                            <Checkbox name="isCurrent" label="Current" color="default" id={val.imprint_id} onChange={setImprintHandle} />
                                        </Label>
                                    </FormGroup>
                                </div>
                            )}
                        </FormGroup>
                        <div className="d-flex justify-content-sm-end">
                            <Button color="success" size="sm" style={{ marginRight: "7%" }} onClick={(e) => handleSubmit(e)}>Save</Button>
                            <Button onClick={handleCancel} >Cancel</Button>
                        </div>
                    </Col>
                </Row>
            </CardBody>
            <Toast />
        </>
    )
}

export default AddUser;
