import React, { useState, useEffect } from 'react';
import {
    Card, CardBody, Row, CardTitle, Col, Container, Button, Badge
} from 'reactstrap';
import axios from "axios";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { useHistory, useLocation } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import config from '../../../../../config/index';

const SubProject = (props) => {
    const [projectCard, setProjectCard] = useState('');
    let location = useLocation()
    let queryString = new URLSearchParams(location.search);
    let projectName = queryString.get("search_name");
    const projectId = queryString.get("id");
    let history = useHistory();

    useEffect(() => {
        try {
            var data = JSON.stringify({
                "project_name": projectName,
                "project_id": projectId
            });

            var confi = {
                method: 'post',
                url: `${config.apiBaseUrl}post/sub_heading`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(confi)
                .then(async (response) => {
                    response.data.Data.map((items) => {
                        let date = items.start_date ? new Date(items.start_date) : "";
                        let edDate = items.end_date ? new Date(items.end_date) : "";
                        let start = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
                        let end = ((edDate.getMonth() > 8) ? (edDate.getMonth() + 1) : ('0' + (edDate.getMonth() + 1))) + '/' + ((edDate.getDate() > 9) ? edDate.getDate() : ('0' + edDate.getDate())) + '/' + edDate.getFullYear()
                        items['startDate'] = start
                        items['endDate'] = end
                    })
                    await setProjectCard(response.data.Data);
                    console.log("project", response.data.Data)
                })
        } catch (error) {
            console.log(error);
        }
    }, [])


    const gotoCreateNewPage = () => {
        history.push({
            pathname: "/project_create_new_project",
            state: { projectName }
        });
    }

    const goToVolume = (val) => {
        console.log("project_idval", val)
        let startD = val.startDate
        let endD = val.endD
        let project_id = val.project_id
        console.log("project_id", project_id)
        history.push({
            pathname: '/littel_lamb',
            state: { project_id, startD, endD },
            search: '?' + new URLSearchParams({ id: project_id }).toString() + '&search' + new URLSearchParams({ _s: startD }) + '&search' + new URLSearchParams({ _e: startD }),
        })

    }

    return (
        <>
            <div className="btn-group--icons d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                <div className="btn-group--icons d-flex align-items-center">
                    <IconButton aria-label="backward">
                        <ArrowLeftIcon onClick={() => history.goBack()} />
                    </IconButton>
                    <h4 className="ml-2 font-weight-bold">PROJECTS / PROJECT</h4>
                </div>
                <div style={{ marginRight: "3%" }}>
                    <Button className="p-2" color="secondary" onClick={gotoCreateNewPage}>Add New Title</Button>
                </div>
            </div>
            <Container className="container" style={{ backgroundColor: "#FFFFFF", marginTop: "2%" }}>
                <Row style={{ width: "100%", margin: "-24px auto" }}>
                    <>{projectCard?.length && projectCard.map((val, index) =>
                        <Col key={index} sm="3" style={{ marginTop: "5%" }}>
                            <Card style={{ cursor: "pointer", height: "100%" }} onClick={() => goToVolume(val)}>
                                <CardBody style={{ backgroundColor: "#FFEDED" }}>
                                    <div className="d-flex justify-content-between mb-1">
                                        <div>
                                            <Badge color="danger" className="p-1">{val.imprint}</Badge>
                                        </div>
                                        {/* <Badge color="success" className="p-1">New</Badge>*/}
                                    </div>
                                    <span style={{ color: "#707070" }}>volume : {val.volumes} </span>
                                    <CardTitle tag="h5" className="font-weight-bold">{val.project_name}</CardTitle>
                                    <div className="d-flex">
                                        <div className="p-2">
                                            <div className="d-flex flex-column mb-2">
                                                <label className="text-muted mb-0">Owner</label>
                                                <span className="font-weight-bold"></span><b>{val.owner}</b>
                                            </div>
                                            <div className="d-flex flex-column mb-2">
                                                <label className="text-muted mb-0">Current Task</label>
                                                <span className="font-weight-bold">Check In</span>
                                            </div>
                                        </div>
                                        <div className="ml-auto p-2" style={{ width: 90, height: 80 }}>
                                            <div label="Default">
                                                <CircularProgressbar value="33" text="33%" styles={buildStyles({
                                                    pathColor: "#f00",
                                                    trailColor: "#fff",
                                                    textColor: "#f00"
                                                })} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-row justify-content-between">
                                        <div className="d-flex flex-column">
                                            <label className="text-muted mb-0">Srart Date</label>
                                            <b>{val.startDate}</b>
                                        </div>
                                        <div className="d-flex flex-column">
                                            <label className="text-muted mb-0">End Date</label>
                                            <b>{val.endDate}</b>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    )}</>
                </Row>
            </Container>
        </>
    )
}

export default SubProject
