import React from 'react';
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import TemplateDefaultCard from './TemplateCard';
import TemplateCustomizedCard from './TemplateCustomizedCard';
import { Row, Container, Button } from 'reactstrap';


const AllTemplate = () => {

    let history = useHistory();

    const goToCreateTemplate = () => {
        history.push("/templates/create")
    }
   

    return (
        <>
            <div className="btn-group--icons d-flex align-items-center">
                <IconButton aria-label="backward">
                    <ArrowLeftIcon onClick={() => history.goBack()} />
                </IconButton>

                <h3 className="ml-2 font-weight-bold">Template List</h3>
            </div>
            <Container className="container">
                {/* <h4 style={{marginLeft:"2%"}}>DEFAULT</h4> */}
                <Row style={{ width: "100%", margin: "-24px auto" }}>
                    {/* <TemplateDefaultCard /> */}
                </Row>
            </Container>
            <Container className="container" style={{marginTop:"5%"}}>
            <div className="d-flex bd-highlight">
            {/* <h4 className="mr-auto bd-highlight" style={{marginLeft:"2%"}}>CUSTOMIZED</h4> */}
            <Button className="bd-highlight" size="sm" type="button" onClick={goToCreateTemplate} style={{alignItems:"center"}} color="success" >  Create New Template </Button>
            </div>
                <Row style={{ width: "100%", margin: "-24px auto", paddingBottom:"2%",marginTop:"-5%"}}>
                    <TemplateCustomizedCard />
                </Row>
            </Container>
        </>
    )
}

export default AllTemplate;
