import React from 'react';
import {
 Button, ButtonToolbar
} from 'reactstrap';
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer';
import '../../style/ProposalDetelis.css';
//import '../../../../Schiffer/Styles/NewComp.css'
//import Pdf1 from '../../../../../public/img/shifimg/Pdfimg.png';
//import Pdfimg from '../../../../assets/img/Pdf



const MyDoc = ({data}) => (
    <Document>
        <Page style={styles.page} size='A4'>
            <View>
                <Text style={styles.pagetitle}>Proposal: SACRED ACTIONS</Text>
                <Text style={styles.title}>Contact Details</Text>
                <View style={styles.content}>
                    <View style={styles.contentleft}>
                        <Text style={styles.body}>Name:</Text><Text style={styles.info}>{data.name}</Text>
                        <Text style={styles.body}>Cell Phone:</Text><Text style={styles.info}>{data.cellno}</Text>
                        <Text style={styles.body}>Home Phone:</Text><Text style={styles.info}>{data.homephon}</Text>
                        <Text style={styles.body}>Work Phone:</Text><Text style={styles.info}>{data.homephon}</Text>
                    </View>
                    <View style={styles.contentRight}>
                        <Text style={styles.body}>Email Address:</Text><Text style={styles.info}>{data.address}</Text>
                        <Text style={styles.body}>Mailing Address</Text><Text style={styles.info}>{data.email}</Text>
                        <Text style={styles.body}>Website</Text><Text style={styles.info}>{data.Website}</Text>
                        <Text style={styles.body}>Social Media Handel</Text><Text style={styles.info}>{data.media_handle}</Text>
                    </View>
                </View>


                <Text style={styles.title}>Book Concept</Text>
                <View style={styles.content}>
                    <View style={styles.contentleft}>
                        <Text style={styles.body}>Proposed Title:</Text><Text style={styles.info}>{data.title}</Text>

                        <Text style={styles.body}>What is the idea of book you are proposing?</Text><Text style={styles.info}>{data.idea}</Text>

                        <Text style={styles.body}>Author Bio</Text><Text style={styles.info}>{data.author_bio}</Text>

                        <Text style={styles.body}>What about the subject matters that will drow release</Text><Text style={styles.info}>{data.matters}</Text>
                    </View>
                    <View style={styles.contentRight}>
                        <Text style={styles.body}>Elebetor Pitch:</Text><Text style={styles.info}>{data.pitch}</Text>

                        <Text style={styles.body}>What are the reasons to belive in the project? </Text><Text style={styles.info}>{data.reasons}</Text>

                        <Text style={styles.body}>Why are you the right person to write about this project?</Text><Text style={styles.info}>{data.right_about}</Text>

                    </View>
                </View>


                <Text style={styles.title}>Target Audience</Text>
                <View style={styles.content}>
                    <View style={styles.contentleft}>
                        <Text style={styles.body}>Target Audience</Text><Text style={styles.info}>{data.target}</Text>

                        <Text style={styles.body}>What is the demographic</Text><Text style={styles.info}>{data.demographic}</Text>

                    </View>
                    <View style={styles.contentRight}>
                        <Text style={styles.body}>What is the Market</Text><Text style={styles.info}>{data.market}</Text>
                    </View>
                </View>


                <Text style={styles.title}>Benefits & Features</Text>
                <View style={styles.content}>
                    <View style={styles.contentleft}>
                        <Text style={styles.body}>What is the benefits and feature?</Text><Text style={styles.info}>{data.benefits}</Text>

                        <Text style={styles.body}>What is the important message?</Text><Text style={styles.info}>{data.imp_msg}</Text>

                        <Text style={styles.body}>How will it influence in your life?</Text><Text style={styles.info}>+The product team was so fixated on the trademarked technology that they neglected to consider why someone would pay $600 for a microwave.</Text>
                    </View>
                    <View style={styles.contentRight}>
                        <Text style={styles.body}>What is the render gain from reading your book or using your deck?</Text><Text style={styles.info}>{data.gain}</Text>

                        <Text style={styles.body}>Does it sovle a problem?</Text><Text style={styles.info}>{data.solve}</Text>

                        <Text style={styles.body}>What is your goal?</Text><Text style={styles.info}>{data.goal}</Text>

                    </View>
                </View>


                <Text style={styles.title}>Content Details</Text>
                <View style={styles.content}>
                    <View style={styles.contentleft}>
                        <Text style={styles.body}>What are your desired spece for the project</Text><Text style={styles.info}>{data.desired}</Text>

                        <Text style={styles.body}>in this a or oracle deck?</Text><Text style={styles.info}>What about this particular microwave would make their lives easier or better than a cheaper version that could arrive at their doorstep within 24 hours? And why should they place their trust and hard-earned income in your hands instead of the other brands on the block</Text>

                        <Text style={styles.body}>Propased table of contents or outline:</Text><Text style={styles.info}>{data.table}</Text>

                        <Text style={styles.body}>Approximate word count</Text><Text style={styles.info}>{data.count}</Text>

                        <Text style={styles.body}>How many images or illustration do you plan to include</Text><Text style={styles.info}>{data.copies}</Text>
                    </View>
                    <View style={styles.contentRight}>
                        <Text style={styles.body}>Kindly share the following</Text><Text style={styles.info}>{data.share}</Text>

                        <Text style={styles.body}>Does it sovle a problem?</Text><Text style={styles.info}>{data.solve}</Text>

                        <Text style={styles.body}>files attached</Text><Text style={styles.info}></Text>

                    </View>
                </View>




                <Text style={styles.title}>Marketing and Competitors</Text>
                <View style={styles.content}>
                    <View style={styles.contentleft}>
                        <Text style={styles.body}>Whom do tou see as your competitors?</Text><Text style={styles.info}>{data.competition}</Text>

                        <Text style={styles.body}>Have you Authored other books</Text><Text style={styles.info}>{data.other_book}</Text>

                        <Text style={styles.body}>who will help you promot and sell your books?</Text><Text style={styles.info}>{data.promote}</Text>

                        <Text style={styles.body}> How many people follow book?</Text><Text style={styles.info}>{data.people_follow}</Text>

                        <Text style={styles.body}>How does the book fit in your business plan, brand and seles statergy?</Text><Text style={styles.info}>{data.fit}</Text>

                        <Text style={styles.body}>Where do you see your book being reviewed, marketed and sold?</Text><Text style={styles.info}>{data.sold}</Text>

                    </View>
                    <View style={styles.contentRight}>
                        <Text style={styles.body}>How ir ypour idea diffrent</Text><Text style={styles.info}>{data.your_idea}</Text>

                        <Text style={styles.body}>Have you recived any reviews or endorsement?</Text><Text style={styles.info}>{data.endorsements}</Text>

                        <Text style={styles.body}>What organigation, clubs, guilds or other groups are you involved with?</Text><Text style={styles.info}>{data.involved}</Text>
                        <Text style={styles.body}>Do you plan on selling copies on your books?</Text><Text style={styles.info}>{data.plan}</Text>

                    </View>
                </View>

                <Text style={styles.title}>Why Schiffer</Text>
                <Text style={styles.text}>why did you shift your project on schiffer?</Text><Text style={styles.textsm}>{data.why_schiffer}</Text>


            </View>
        </Page>
    </Document>
);

const styles = StyleSheet.create({
    page: {
        marginLeft: 20,
        marginBottom: 20
    },
    title: {
        marginTop: 13,
        fontSize: 20,
        color: 'red'
    },
    pagetitle: {
        marginTop: 18,
        fontSize: 15
    },
    content: {
        display: "flex",
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 8
    },
    contentRight: {
        marginRight: 100
    },
    body: {
        marginLeft: 10,
        fontSize: 12,
        marginTop: 10,
        textAlign: 'justify',
        width: 210
    },
    info: {
        marginLeft: 10,
        marginTop: 3,
        marginBottom: 5,
        fontSize: 10,
        textAlign: 'justify',
        width: 210
    },
    text: {
        marginLeft: 10,
        fontSize: 12,
        marginTop: 10,
        textAlign: 'justify'
    },
    textsm: {
        marginLeft: 10,
        marginTop: 3,
        marginBottom: 5,
        fontSize: 10,
        textAlign: 'justify',
        width: 510
    }
})







const DownPdf = ({ data }) => {
    //console.log(data)
    //alert({data})
    return <PDFDownloadLink document={<MyDoc data={data} />} fileName="SchifferProposal.pdf">
        <ButtonToolbar>
            <Button size="sm">Download PDF</Button>
        </ButtonToolbar>
    </PDFDownloadLink>
};
export default DownPdf;