import React, { useState, useEffect } from "react";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import PlusCircleIcon from "mdi-react/PlusCircleIcon";
import IconButton from "@material-ui/core/IconButton";
import config from '../../../../config/index';
import TaskFlowSteps from './TaskFlowSteps';

const TaskFlowView = () => {
  let history = useHistory();
  const [taskFlows, setTaskFlows] = useState('');
  const [taskFlowId, setTaskFlowId] = useState('');
  //const [endDate, setEndDate] = useState(new Date());
  const [taskFlowsStep, setTaskFlowsStep] = useState('');
  let userInfo = localStorage.getItem("token");


  useEffect(() => {

    fetchData(1)
    try {
      var confi = {
        method: 'get',
        url: (`${config.apiBaseUrl}get/task_flow_list`),
        headers: {
          'Authorization': "Bearer " + userInfo
        }
      };

      axios(confi)
        .then((response) => {
          setTaskFlows(response.data.data);

        })
    } catch (error) {
      console.log(error)
    }

  }, [])


  const fetchData = (taskFlowId) => {
    try {
      var data = JSON.stringify({
        "task_flow_id": taskFlowId
      });
      var confi = {
        method: 'post',
        url: `${config.apiBaseUrl}get/task_flow_steps`,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + userInfo
        },
        data: data
      };
      axios(confi)
        .then((response) => {
          setTaskFlowsStep(response.data.Data)
        })
    } catch (error) {
      console.log(error);
    }
  }



  const redirectPage = () => {
    history.push("/new_task_flows")
  }

  const showTaskFlowList = (task_flow_id) => {
    setTaskFlowId(task_flow_id);
    fetchData(task_flow_id);
  }

  return (
    <>
      <div className="btn-group--icons d-flex align-items-center">
        <IconButton aria-label="backward">
          <ArrowLeftIcon onClick={() => history.goBack()} />
        </IconButton>
        <h3 className="ml-2 font-weight-bold">TASK FLOWS</h3>
      </div>
      <div>

        {/*
      <Container>
          <Row>
            <Col>
              <h4>Default</h4>
            </Col>
          </Row>
        </Container>
      */}

        <Container>
          <Row>
            <Col md={5} sm={12}>
              <Card>
                <ListGroup style={{ overflowY: "scroll", height: "50vh" }}>
                  {taskFlows?.length > 0 ? taskFlows?.map((val, index) =>
                    <ListGroupItem key={index} className="font-weight-bold" onClick={() => showTaskFlowList(val.task_flow_id)}>{val.task_flow_name}</ListGroupItem>
                  ) : <h4>Data not Found</h4>
                  }
                  {/*
                   <ListGroupItem>Morbi leo risus</ListGroupItem>
                    <ListGroupItem>Porta ac consectetur ac</ListGroupItem>
                    <ListGroupItem>Vestibulum at eros</ListGroupItem>
                  */}
                </ListGroup>

                <div className="p-3 mb-4">
                  <div
                    className="d-flex flex-column  justify-content-center align-items-center h-100"
                    style={{ cursor: "pointer" }}
                    onClick={redirectPage}
                  >
                    <PlusCircleIcon className="text-muted" size={48} />
                    <div className="text-muted font-weight-bold mt-2">
                      Create New Task Flow
                    </div>
                  </div>
                </div>
              </Card>
            </Col>
            <TaskFlowSteps taskFlowsStep={taskFlowsStep} taskFlowId={taskFlowId} />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default TaskFlowView;
