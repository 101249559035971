import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from "../../../../config/index";
import { Card, CardBody } from 'reactstrap';
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const StatisticsView = () => {
    const [topData, setData] = useState("");
    const [overAll, setOverAll] = useState(0);


    useEffect(() => {
        try {
            var confi = {
                method: 'get',
                url: `${config.apiBaseUrl}get/top_imprint`,
                headers: {},
            };
            axios(confi)
                .then(function (response) {
                    setOverAll(response.data.Overall_project);
                    setData(response.data.Data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
        catch (err) {
            console.log("error");
        }
    }, [])



    return (
        <>
            <Card>
                <CardBody className="dashboard__card">
                    <label className="font-weight-bold">STATISTICS</label>
                    <span style={{ marginTop: "2%" }}>
                        <div className="d-flex justify-content-around">
                            <h1 style={{ fontSize: "4vw", fontWeight: "bold", color: "#09B207" }}>{overAll?.length ? overAll : "0"}</h1>
                            <span>
                                <p style={{ opacity: "0.5" }}>2020 - 2021</p>
                                <h3 style={{ color: "#3D3D3D" }}>Overall Projects</h3>
                            </span>
                        </div>
                    </span>
                    <>
                        <h3 className="text-muted" style={{ marginTop: "3%" }}>Top Imprints</h3>
                        <span className="text-muted">Best performance imprints</span>
                        <span className="d-flex justify-content-around" style={{ marginTop: "3%" }}>
                            {topData?.length > 0 ? topData?.map((val, index) => (
                                <div key={index} style={{ width: "70px", height: "70px" }}>
                                    <CircularProgressbar value={val.imprint_progress} text={Math.floor(val.imprint_progress)} styles={buildStyles({
                                        pathColor: "#FC5D6D",
                                        trailColor: "#dbd7d2",
                                        textColor: "#FC5D6D"
                                    })} />
                                </div>
                            )) : null}
                            {/*
                         <div style={{ width: "70px", height: "70px" }}>
                                <CircularProgressbar value="33" text="33%" styles={buildStyles({
                                    pathColor: "#09B207",
                                    trailColor: "#dbd7d2",
                                    textColor: "#09B207"
                                })} />
                            </div>
                            <div style={{ width: "70px", height: "70px" }}>
                                <CircularProgressbar value="88" text="88%" styles={buildStyles({
                                    pathColor: "#1588FC",
                                    trailColor: "#dbd7d2",
                                    textColor: "#1588FC"
                                })} />
                            </div>
                        */}
                        </span>
                    </>
                </CardBody>
            </Card>
        </>
    )
}

export default StatisticsView