import React from "react";
import { Route, Switch } from "react-router-dom";
import TemplateList from "./components/TemplateList";
import TemplateCreate from "./components/TemplateCreate";
import { Container, Row, Col } from "reactstrap";

const Templates = ({ match }) => {
  // let history = useHistory();
  return (
    <Container>
      <Row>
        <Col>
          <Switch>
            <Route path={`${match.url}/create`} component={TemplateCreate} />
            <Route path={`${match.url}`} component={TemplateList} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

export default Templates;
