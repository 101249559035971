import $ from "jquery";
import React, { Component, createRef } from "react";
import { withRouter } from "react-router-dom";
import { Button } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config/index';
import "./styles.scss";

window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require("formBuilder");

const options = {
  disabledActionButtons: ['data', 'save', 'clear'],
  disableFields: ['autocomplete', "hidden", 'button', 'date', 'header', 'number', 'starRating', 'file'],
  formData: [
    {
      "type": "header",
      "subtype": "h1",
      "label": " Default Template",
      "access": false
    },
    {
      "type": "paragraph",
      "subtype": "p",
      "label": "New Template Description",
      "access": false
    }
  ]
};

let userInfo = localStorage.getItem("token");
let user_Info = localStorage.getItem("authuser");
let userData = JSON.parse(user_Info)


class FormBuilder extends Component {

  constructor(props) {
    super(props)
    this.state = {
      template_id: "",
      u_id: userData?.user_id
    }
  }

  fb = createRef();
  formBuilder;



  saveTepmlate = (e) => {
    e.preventDefault();
    try {
      const { history } = this.props;
      const result = this.formBuilder.actions.save();
      console.log(this.formBuilder.actions.save())
      console.log(this.state.u_id)
      var template_data = result;
      var uid = userData?.user_id;
      var data = { template_data, uid };



      var confi = {
        method: 'post',
        url: (`${config.apiBaseUrl}post/templetes`),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + userData.token
        },
        data: data
      };

      axios(confi)
        .then(async response => {
          const id = response?.data?.id
          await this.setState({
            template_id: id
          })
          history.push('/all_templates');
        })
    } catch (error) {
      console.log(error);
    }
  }

  clearTepmlate = (e) => {
    e.preventDefault();
    this.formBuilder.actions.clearFields();
  }



  componentDidMount() {
    this.formBuilder = $(this.fb.current).formBuilder(options);
    //console.log(this.formBuilder);
  }

  render() {


    return <>
      <div className="body">
        <div id="fb-editor" ref={this.fb} style={{ marginTop: "3px", marginRight: "5px", marginLeft: "5px" }} />
      </div>
      <div style={{ marginTop: "8px", width: "auto", position: "relative", float: "right" }}>
        <Button bsSize="sm" style={{ marginRight: "8px" }} color="danger" onClick={this.clearTepmlate} >Clear Template</Button>

        <Button bsSize="sm" color="success" onClick={this.saveTepmlate}>Save Template</Button>
      </div>
    </>
  }
}

export default withRouter(FormBuilder);
