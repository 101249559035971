import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Col, CardBody, Card, Row, Container, Table } from 'reactstrap';
import { useHistory, useLocation } from "react-router-dom";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import IconButton from "@material-ui/core/IconButton";
import config from '../../../../../config/index';
import Select from 'react-select';

const ProjectReport = () => {
    let history = useHistory();
    const location = useLocation();
    const [dropDownList, setDropDownList] = useState([]);
    const [projectInfoData, setProjectInfoData] = useState([]);
    const [taskRecord, setTaskRecord] = useState([]);
    let user_Info = localStorage.getItem("authuser");
    let userAuth = JSON.parse(user_Info);

    useEffect(() => {
        try {
            var confg = {
                method: 'get',
                url: `${config.apiBaseUrl}/get/project_list`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + userAuth.token
                }
            };
            axios(confg)
                .then((response) => {
                    setDropDownList(response.data.Data)
                })
        } catch (error) {
            console.log(error);
        }
    }, [])

    const projectInfo = (projectId) => {
        try {
            var confi = {
                method: 'get',
                url: `${config.apiBaseUrl}get/project_details/${projectId}`,
                headers: {
                    'Authorization': "Bearer " + userAuth.token,
                }
            };
            axios(confi)
                .then((response) => {
                    if (response.data?.status === 1) {
                        setTaskRecord(response.data?.Data[0].task_record)
                        setProjectInfoData(response.data?.Data[0]);
                    }
                })
        } catch (error) {
            console.log(error);
        }
    }

    //set valude for select dropdown
    const optionsSize = dropDownList?.map(d => ({
        "value": d.project_id,
        "label": d.project_name

    }))

    //ste id and call api for get project info
    const handleChange = (e) => {
        projectInfo(e.value)
    }

    // // Extra Side Effects
    // useEffect(() => {
    //     Object.keys(projectInfoData).length && Object(projectInfoData?.task_record)?.map((each, i) => {
    //         return console.log("hj", each.status[0].user_data.first_name)
    //     })
    // }, [projectInfoData])


    return (
        <>
            <div className="btn-group--icons d-flex align-items-center" style={{ justifyContent: "space-between" }}>
                <div className="btn-group--icons d-flex align-items-center">
                    <IconButton aria-label="backward">
                        <ArrowLeftIcon onClick={() => history.goBack()} />
                    </IconButton>
                    <h4 className="ml-2 font-weight-bold">PROJECT REPORT</h4>
                </div>
            </div>
            <Container className="container" style={{ backgroundColor: "#FFFFFF", marginTop: "2%" }}>
                <Row style={{ width: "100%", margin: "-24px auto" }}>
                    <Col sm="12">
                        <Card>
                            <CardBody>
                                <div className='w-50 mx-auto mb-3'>
                                    <Select
                                        options={optionsSize}
                                        onChange={handleChange}
                                        issearchable
                                        bsSize="sm"
                                    />
                                </div>
                                <Table responsive hover className="table--bordered">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center", width: "12%" }}>Task</th>
                                            <th style={{ textAlign: "center", width: "12%" }}>User</th>
                                            <th style={{ textAlign: "center", width: "16%" }}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ textAlign: "center" }}>
                                                {
                                                    Object.keys(projectInfoData).length && Object(projectInfoData?.task_record)?.map((each, i) => {
                                                        return <p>{each.task_data[0].taskflow_form[0].label}</p>
                                                    })
                                                }
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {
                                                    Object.keys(projectInfoData).length && Object(projectInfoData?.task_record)?.map((each, i) => {
                                                        return <p>{each.status[0].user_data.first_name} {each.status[0].user_data.last_name}</p>
                                                    })
                                                }
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {
                                                    Object.keys(projectInfoData).length && Object(projectInfoData?.task_record)?.map((each, i) => {
                                                        return <p className={each.status[0].status === "running" ? "text-warning" : "text-success"}>{each.status[0].status}</p>
                                                    })
                                                }
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ProjectReport