import $ from "jquery";
import React, { Component, createRef } from "react";
import ReactDOM from "react-dom";
import { Button } from 'reactstrap';
import axios from 'axios';
import config from '../../../../config/index';
window.jQuery = $;
window.$ = $;
let userInfo = localStorage.getItem("token");
let user_Info = localStorage.getItem("authuser");
let userData = JSON.parse(user_Info)

require("jquery-ui-sortable");
require("formBuilder");



/* 
The order of the imports and requires is very important, especially in the online enviornment.
The two jQuery libraries must be imported using Node's require(), and not ES6 import.
Also, these two requires MUST come after setting the global jQuery and $ symbols.

In my Babel/Webpack project, the type and order of the imports is a little less sensitive.
For my project, the following alternative works:

    import $ from 'jquery';
    import React from 'react';
    import ReactDOM from 'react-dom';
    import 'jquery-ui-sortable';

    window.jQuery = $;
    window.$ = $;

    require('formBuilder');
*/


let  formData = [
    {
      type: "header",
      subtype: "h1",
      label: "formBuilder in React"
    },
    {
      type: "paragraph",
      label: "This is a demonstration of formBuilder running in a React project."
    }
  ];
class Edit extends Component {

 
 fb = createRef();
  formBuilder;

    saveTepmlate = (e) => {
    //console.log(this.formBuilder)
    e.preventDefault();
     try {
      const { history } = this.props;
      const result = this.formBuilder.actions.save();
      console.log(this.formBuilder.actions.save())
      //console.log(this.state.u_id)
      var template_data = JSON.stringify(result);
      //var template_id = userData?.user_id;
      var template_id = this.props.match.params[0];
      var data = { template_data , template_id };



      var confi = {
        method: 'post',
        url: (`${config.apiBaseUrl}post/update_templetes_body`),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + userData.token
        },
        data: data
      };

      axios(confi)
        .then(async response => {
          const id = response?.data?.id
          await this.setState({
            template_id: id
          })
          history.push('/all_templates');
        })
     } catch (error) {
      console.log(error);
     }
  }

 
  componentDidMount() {
     console.log(this.props.match.params)

     const id = this.props.match.params[0];
     var data = JSON.stringify({
      "template_id": id
      });
      var confi = {
          method: 'post',
          url: `${config.apiBaseUrl}get/templetes`,
          headers: {
              'Content-Type': 'application/json',
              'Authorization': "Bearer " + userInfo

          },
          data: data
      };
      axios(confi)
          .then(async (response) => {
              let  tempformData = response?.data?.data[0]?.template_data;
               var arr = [];
                tempformData.forEach(element => {
                  var temp = {}
                  temp.label = element.label
                  temp.type = element.type
                  arr.push(temp)
                });
              formData = arr

              const options = {
                disabledActionButtons: ['data', 'save', 'clear'],
                disableFields: ['autocomplete', "hidden", 'button', 'date', 'header', 'number', 'starRating', 'file'],
                formData: formData
              };


              this.formBuilder = $(this.fb.current).formBuilder(options);

          })
          .catch(function (error) {
              console.log(error);
          });
    
    // $(this.fb.current).formBuilder({ formData });

  }


   render() {


    return <>
      <div className="body">
        <div id="fb-editor" ref={this.fb} style={{ marginTop: "3px", marginRight: "5px", marginLeft: "5px" }} />
      </div>
      <div style={{ marginTop: "8px", width: "auto", position: "relative", float: "right" }}>
        <Button bsSize="sm" color="success" onClick={this.saveTepmlate}>Save Template</Button>
      </div>
    </>
  }
}

export default Edit;
