import React, { useState, useEffect } from 'react';
import axios from "axios";
import {
    Table,
    Card,
    CardBody,
    Col, Input
} from "reactstrap";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "mdi-react/DeleteIcon";
import { useHistory } from "react-router-dom";
import config from "../../config/index";
import { toast } from 'react-toastify';
import { Toast, showSuccess } from "../../shared/components/Toast/Toast";
import 'react-toastify/dist/ReactToastify.css';

const Issues = () => {
    let history = useHistory();
    const [data, setData] = useState([]);

    useEffect(() => {
        issues();
    }, [])

    const issues = () => {
        try {
            var data = '';

            var confi = {
                method: 'get',
                url: `${config.apiBaseUrl}get/issues_list`,
                headers: {},
                data: data
            };
            axios(confi)
                .then(async (response) => {
                    await setData(response.data.Data);
                })
        } catch (error) {
            console.log(error);
        };
    }


    const deleteIssu = (ID) => {
        try {
            var data = JSON.stringify({
                "issue_id": ID
            });

            var conf = {
                method: 'post',
                url: `${config.apiBaseUrl}post/resolved_issues`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: data
            };

            axios(conf)
                .then((response) => {
                    showSuccess('Issue has been resolved', toast);
                    issues();
                })
        } catch (error) {
            issues()
        }
    }

    const issueList = (e) => {
        try {
            var confi = {
                method: 'get',
                url: `${config.apiBaseUrl}get/issues_list_name/${e.target.value}`,

                headers: {}
            };
            axios(confi)
                .then(function (response) {
                    setData(response.data.Data)
                })
        } catch (error) {
            console.log(error);
        };
    }


    return (
        <>
            <div className="d-flex" style={{ alignItems: "flex-start" }}>
                <Col className="btn-group--icons d-flex align-items-center mr-auto p-2" sm={8}>
                    <IconButton aria-label="backward">
                        <ArrowLeftIcon onClick={() => history.goBack()} />
                    </IconButton>
                    <h3 className="ml-2 font-weight-bold" >TRIGGER ISSUES</h3>
                </Col>

                {/*
                    <Col className="p-2" style={{ display: "flex" }} sm={4}>
                        <Button
                            style={{ marginRight: "5%" }}
                            size="sm"
                            outline
                            color="success"
                            onClick={() => history.push("/user_management/create")}
                        >
                            Add New User
                        </Button>
                        <Button
                            size="sm"
                            outline
                            color="success"
                            onClick={() => history.push("/user_management/pending")}
                        >
                            Pending request
                        </Button>
                        </Col>
                    */}
            </div>
            <div>
                <Card>
                    <CardBody style={{ overflowY: "scroll", height: "70vh" }}>
                        <span style={{ display: "flex", alignItems: "center" }}>
                            <h4>Issue Name :</h4>
                            <Input type="text" style={{ width: "20%", marginLeft: "10px" }} onChange={issueList} />
                        </span>
                        <Table responsive hover className="table--bordered" style={{ marginTop: "20px" }}>
                            <thead >
                                <tr>
                                    <th style={{ textAlign: "center", width: "10%" }}>Sr. No</th>
                                    <th style={{ textAlign: "center", width: "30%" }}>Issues</th>
                                    <th style={{ textAlign: "center", width: "10%" }}>Actions</th>
                                </tr>
                            </thead>
                            {data?.length === 0 ? <>

                                <tbody>
                                    <tr>
                                        <td colSpan="9" key="no_data" style={{ textAlign: "center" }}><h2>No Data Found</h2></td>
                                    </tr>
                                </tbody>

                            </> : data?.length ? data.map((val, index) =>
                                <tbody kay={index}>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>{index + 1}</td>
                                        <td style={{ textAlign: "left" }}>{val?.issue_discription}</td>
                                        <td style={{ textAlign: "center" }}>
                                            <DeleteIcon value={val?.issue_id} color="red" size={24} onClick={() => deleteIssu(val?.issue_id)} />
                                            {/* <span>{val?.issue_id}</span> */}
                                        </td>
                                    </tr>
                                </tbody>
                            ) :
                                <h4 style={{ paddingTop: "20px" }}>No issues Found</h4>}
                        </Table>
                    </CardBody>
                </Card>
            </div>

            <Toast />
        </>
    )
}

export default Issues;
