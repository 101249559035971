import React, { useEffect, useState } from 'react';
import axios from "axios";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import { Container, Card, CardBody, Row, Col, Input, Button } from "reactstrap";
import Checkbox from '@mui/material/Checkbox';
import UserData from "../../../config/UserInfo";
import config from "../../../config/index";
import { toast } from 'react-toastify';
import { Toast, showSuccess } from '../../../shared/components/Toast/Toast';
import 'react-toastify/dist/ReactToastify.css';

const Roles = () => {
    let history = useHistory();
    const [roleData, setRoleData] = useState([]);
    const [roleName, setRoleName] = useState([]);
    let userInfo = localStorage.getItem("token");

    useEffect(() => {
        roles();
        rolesData();
    }, [])

    const roles = () => {
        try {
            var confi = {
                method: 'get',
                url: `${config.apiBaseUrl}get/all_role_list`,
                headers: {
                    'Authorization': "Bearer " + userInfo
                }
            };
            axios(confi)
                .then(async (response) => {
                    await setRoleData(response.data.Data);
                    console.log("roleData----------->", roleData);
                })
        } catch (error) {
            console.log(error);
        };
    }


    const rolesData = () => {
        try {
            var conf = {
                method: 'get',
                url: `${config.apiBaseUrl}get/templetesall`,
                headers: {
                    'Authorization': "Bearer " + userInfo
                }
            };

            axios(conf)
                .then(async (response) => {
                    await setRoleName(response.data.data);
                })
        } catch (error) {
            console.log(error);
        };
    }

    const handleSave = (e) => {
        let RoleValue = document.getElementById("roleSelect").value

        var data = JSON.stringify({
            "role_id": RoleValue,
            "template_id": e.target.id,
            "created_by": UserData.id
        });

        var con = {
            method: 'post',
            url: `${config.apiBaseUrl}post/assign_templates`,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        };

        axios(con)
            .then((response) => {
                console.log(response.data.status);
                // if(response.data.status === 1){
                // showSuccess('Roles Access successfully', toast);
                // } 
            })
            .catch(function (error) {
                console.log(error);
            });


    }


    const saveApi = () => {
        roles();
        rolesData();
        showSuccess('Roles Access successfully', toast);
    }


    return (
        <>
            <Toast />
            <div className="btn-group--icons d-flex align-items-center">
                <IconButton aria-label="backward">
                    <ArrowLeftIcon onClick={() => history.goBack()} />
                </IconButton>
                <h3 className="ml-2 font-weight-bold">Role Access</h3>
            </div>
            <Container>
                <Card>
                    <Row>
                        <Col>
                            <CardBody style={{ height: "80vh", }}>
                                <Input type="select" size="sm" name="select" id="roleSelect" className="input-without-border-radius_multi-select text-muted">
                                    {roleData.map((items, index) => {
                                        return (<option id="roleSelect" value={items.role_id}>{items.role_name}</option>)
                                    })}
                                </Input>
                            </CardBody>
                        </Col>
                        <Col>
                            <CardBody style={{ overflowY: "scroll", height: "80vh" }}>
                                {roleName?.map((items, i) => {
                                    return (
                                        <div key={i} >
                                            <Checkbox name="isCurrent" id={items.template_id} color="default" onChange={handleSave} />
                                            <label style={{ width: "12vw" }}>{items.template_name}</label>
                                        </div>
                                    )
                                })}
                                <Button color="success" onClick={saveApi} >save</Button>
                            </CardBody>
                        </Col>
                    </Row>
                </Card>
            </Container>
        </>
    );
};

export default Roles;
