import jwt_decode from "jwt-decode";

let userInfo = localStorage.getItem("token");

if(userInfo){
    var decoded = jwt_decode(userInfo);
}
// var decoded = jwt_decode(userInfo);
let userFullInfo = (decoded);
// const UserData = [userFullInfo];
let allInfo = [userFullInfo]
const UserData = allInfo[0]?.id



export default UserData;