import React, { useEffect, useState } from 'react';
import axios from "axios";
import { connect } from 'react-redux';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import { Row, Container, Col, Card, CardBody } from 'reactstrap';
import StatisticsView from './components/StatisticsView';
import Announcements from './components/Announcements';
import config from "../../../config/index";
import jwt_decode from "jwt-decode";


const AppDashboard = () => {
  const [dashData, setDashData] = useState("");
  let userInfo = localStorage.getItem("token");

  if (userInfo) {
    var decoded = jwt_decode(userInfo);
  }
  let userFullInfo = (decoded);
  let allInfo = [userFullInfo]
  const DataUser = allInfo[0]?.id

  useEffect(() => {
    var data = JSON.stringify({
      "created_by": DataUser?.id
    });
    var confi = {
      method: 'post',
      url: `${config.apiBaseUrl}post/dashboard`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios(confi)
      .then(function (response) {
        console.log(response.data)
        setDashData(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [])


  return (
    <>
      <div className="btn-group--icons d-flex align-items-center">
        <h4 className="ml-2 font-weight-bold">DASHBOARD</h4>
      </div>
      <Container>
        <div className="row">
          <div className="col-sm-4 sidenav">
            <Row>
              <Col className="col" style={{ height: '109px' }}>
                <Card>
                  <CardBody className="dashboard__card">
                    <h3 style={{ fontSize: "30px", fontWeight: "bold", color: "#D05454" }}>{dashData?.My_tasks ? dashData?.My_tasks : 0}</h3>
                    <label style={{ fontSize: "8px" }}>MY TASKS</label>
                  </CardBody>
                </Card>
              </Col>
              <Col className="col" style={{ height: '109px' }}>
                <Card>
                  <CardBody className="dashboard__card-widget">
                    <h3 style={{ fontSize: "30px", fontWeight: "bold", color: "#D05454" }}>{dashData?.Projects ? dashData?.Projects : 0}</h3>
                    <label className="text-muted" style={{ fontSize: "8px" }}>MY PROJECTS</label>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Card style={{ height: '109px' }}>
              <CardBody style={{ padding: "0" }}>
                <span className="d-flex justify-content-around">
                  <span className="me-auto p-2 bd-highlight">
                    <h3 style={{ fontSize: "30px", fontWeight: "bold", color: "#D05454" }}>{dashData?.Proposals ? dashData?.Proposals : 0}</h3>
                    <label style={{ fontSize: "8px" }}>PROPOSALS</label>
                  </span>
                  <span className="p-2 bd-highlight">
                    <h3 style={{ fontSize: "30px", fontWeight: "bold", color: "#75C259" }}>{dashData?.New_proposals ? dashData?.New_proposals : 0}</h3>
                    <label style={{ fontSize: "8px" }}>NEW</label>
                  </span>
                  <span className="p-2 bd-highlight">
                    <h3 style={{ fontSize: "30px", fontWeight: "bold", color: "#E6CC49" }}>{dashData?.Underreview ? dashData?.Underreview : 0}</h3>
                    <label style={{ fontSize: "8px" }}>UNDER REVIEW</label>
                  </span>
                </span>
              </CardBody>
            </Card>
            <StatisticsView />
          </div>

          <div className="col-sm-8">
            <Row>
              {/*
              <Col className="sidenav" md={12} xl={7} lg={6} xs={12}>
              <StatisticsView />
            </Col>
            <Col className="sidenav" md={12} xl={5} lg={6} xs={12}>
              <Notification />
            </Col>
            */}
            </Row>
            <Row>
              <Col className="sidenav" md={12} xl={12} lg={6} xs={12}>
                <Announcements />
              </Col>
            </Row>
          </div>
        </div>
      </Container>

    </>
  );
};

AppDashboard.propTypes = {
  rtl: RTLProps.isRequired,
};

export default connect(state => ({
  rtl: state.rtl,
}))(AppDashboard);