import $ from "jquery";
import React, { useState, useEffect } from 'react';
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import IconButton from "@material-ui/core/IconButton";
import { useHistory } from "react-router-dom";
import Select from 'react-select';

import axios from 'axios';
import config from '../../../../config/index';
import './taskflowcss.css';
import {
  Container,
  Row,
  Col,
  ButtonToolbar,
  Button,
  Card,
  CardBody,
  FormGroup,
  CardText,
  CardImg,
  Label,
  Input
} from "reactstrap";
import profilelogo from '../../../../assets/profilelogo.png';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import jwt_decode from "jwt-decode";
import { Toast, showSuccess, showError } from '../../../../shared/components/Toast/Toast';
import { toast } from 'react-toastify';


window.jQuery = $;
window.$ = $;

require("jquery-ui-sortable");
require('formBuilder/dist/form-render.min.js');

const AddNewTaskFlow = (props) => {

  const [dropDownList, setDropDownList] = useState([]);
  const [template, setTemplate] = useState(1);
  const [coustmize, setcoustmize] = useState(false);
  const featchData = props.location.state?.fetchData;
  const featcDataId = featchData?.data[0].task_flow_id;
  const [templateData, setTemplateData] = useState(null);
  //const [saveResponse, setSaveResponse] = useState([]);
  const [result, setResult] = useState('');
  const [time, setTime] = useState('');
  const [taskFlowName, setTaskFlowName] = useState(null);
  const [taskFlowsStep, setTaskFlowsStep] = useState("");
  //const [taskFlowId, setTaskFlowId] = useState('');
  //const fb = createRef();
  const [taskFlowList, setTaskFlowList] = useState("");
  const [taskFlow, setTaskFlow] = useState("");
  const [dropDownTaskflowId, setDropDownTaskFlowId] = useState("");
  let formBuilder;
  let history = useHistory();

  //console.log(taskFlowName);
  //console.log("addnewtsk_featchdata", featchData);
  //console.log("featchdata", featchData.data[0].task_flow_id);

  // ================================================================

  let userInfo = localStorage.getItem("token");
  var JWTtoken = userInfo;
  var decoded = jwt_decode(JWTtoken);
  let userFullInfo = (decoded);
  let allInfo = [userFullInfo];
  let data = allInfo[0].id


  let user_Info = localStorage.getItem("authuser");
  let userAuth = JSON.parse(user_Info);

  // console.log("user_Info", userAuth)

  // ================================================================

  useEffect(() => {

    fetchData(1)
    setpperhandel(featcDataId)
    setTaskFlowName(featchData?.data[0].task_flow_name)

  }, [])


  useEffect(() => {
    try {
      var confi = {
        method: 'get',
        url: (`${config.apiBaseUrl}get/templetesdropdown`),
        headers: {
          'Authorization': "Bearer " + userInfo
        }
      };
      axios(confi)
        .then(function (response) {
          setDropDownList(response.data.data);
        })
    } catch (error) {
      console.log(error);
    }
  }, []);


  // ===================================================================
  useEffect(() => {
    try {
      var con = {
        method: 'get',
        url: (`${config.apiBaseUrl}get/task_flow_list`),
        headers: {
          'Authorization': "Bearer " + userInfo
        }
      };

      axios(con)
        .then(async (response) => {
          await setTaskFlowList(response.data?.data);
        })
    } catch (error) {
      console.log(error);
    }
  }, []);



  // ===========================================================



  const fetchData = (template_id) => {
    try {
      var data = JSON.stringify({
        "template_id": template_id
      });

      var confi = {
        method: 'post',
        url: (`${config.apiBaseUrl}get/templetes`),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + userInfo
        },
        data: data
      };

      axios(confi)
        .then((response) => {
          if (response.data.status === 201) {
            setTemplateData(response.data?.data[0].template_data)
            var fbRender = document.getElementById('fb-render'),
              formData = response.data?.data[0].template_data
            var formRenderOpts = {
              formData,
              dataType: 'json'
            };
            formBuilder = $(fbRender).formRender(formRenderOpts);
          }
        })
    } catch (error) {
      console.log(error);
    }

  }



  // const showCoustmize = () => {
  //   setcoustmize(true)
  //   var confi = {
  //     method: 'get',
  //     //url: 'https://scpmbe.pagekite.me/api/get/templetesdropdown'
  //     url: (`${config.apiBaseUrl}get/templetesdropdown`)
  //   };

  //   axios(confi)
  //     .then(function (response) {
  //       setDropDownList(response.data.data);
  //       // console.log(response.data);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // }


  const optionsSize = dropDownList?.map(d => ({
    "value": d.template_id,
    "label": d.template_name
  }))
  //console.log("optionsize",optionsSize);

  //const optionsSize = [{ value: "1", label: "Log proposal" }, { value: "2", label: "review" }, { value: "3", label: "check" }, { value: "4", label: "option" }]

  const goEditPage = (template) => {
    history.push({
      pathname: '/template_edit_class',
      state: { template, templateData }
    })
  }

  const showDefalut = () => {
    setcoustmize(false)
    fetchData(1)
  }

  const handleChange = (e) => {
    setTemplate(e.value)
    fetchData(e.value)
  }

  //console.log("id",template);

  const handelSubmit = (e) => {
    e.preventDefault();
    try {
      var fbRender = document.getElementById('fb-render');
      console.log("result", $(fbRender).formRender('userData'));
      setResult($(fbRender).formRender('userData'));

      var data = JSON.stringify({
        "task_flow_name": taskFlow,
        "created_by": userAuth?.user_id,
        "template_id": template,
        "taskflow_form": $(fbRender).formRender('userData'),
        "estimated_time": time
      });

      var confi = {
        method: 'post',
        url: (`${config.apiBaseUrl}post/task_name`),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + userInfo
        },
        data: data
      };

      axios(confi).then((response) => {
        //setSaveResponse(response.data.data ,
        // setTaskFlowId(response.data.data[0].task_flow_id,
        // ===========================================================
        if (response?.data?.status === 1) {
          showSuccess('Task Flow Added Successfully', toast);
          // setTimeout(() => { history.push("/projects") }, 1000);
        } else if (response?.data?.status === 0) {
          showError('Task Flow Create failed', toast);
        }
        // =====================================================
        // showSuccess('Task Flow Added Successfully', toast)
        //);
        setpperhandel(response?.data?.data[0]?.task_flow_id)
        //console.log(this.state.fetchData)
        //setfetchData(response.data);
        // setTimeout(() => history.push({
        //   pathname: '/new_task_flows',
        //   state: { fetchData: response.data }
        // }), 1000);
      })
    } catch (error) {
      console.log(error);
    }
  };

  //console.log("taskFlowId",taskFlowId);






  const handleTaskFlowId = (e) => {
    // console.log(e)
    setTaskFlow(e.target.value)
    setDropDownTaskFlowId(e.target.selectedIndex)
    setpperhandel(e.target.selectedIndex)
  }

  const useQontoStepIconStyles = makeStyles({
    root: {
      color: '#eaeaf0',
      display: 'flex',
      height: 22,
      alignItems: 'center',
    },
    active: {
      color: '#784af4'
    },
    circle: {
      width: 25,
      height: 25,
      borderRadius: '50%',
      border: '1px solid gray',
      backgroundColor: 'white',
    },
    completed: {
      width: 25,
      height: 25,
      color: 'white',
      borderRadius: '50%',
      border: '1px solid gray',
      backgroundColor: 'white',
    },
  });


  function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={clsx(classes.root, {
          [classes.active]: active,
        })}
      >
        {completed ? <div className={classes.completed} /> : <div className={classes.circle} />}
      </div>
    );
  }

  const setpperhandel = (dropDownTaskflowId) => {
    try {
      var data = JSON.stringify({
        "task_flow_id": dropDownTaskflowId
      });
      var confi = {
        method: 'post',
        url: (`${config.apiBaseUrl}get/task_flow_steps`),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + userInfo
        },
        data: data
      };

      axios(confi)
        .then(function (response) {
          setTaskFlowsStep(response?.data?.Data);
        })
    } catch (error) {
      console.log(error);
    }
  }


  return (
    <>
      <div className="btn-group--icons d-flex align-items-center">
        <IconButton aria-label="backward">
          <ArrowLeftIcon onClick={() => history.goBack()} />
        </IconButton>
        <h3 className="ml-2 font-weight-bold">ADD NEW TASK FLOW</h3>
      </div>
      <Container>
        <Row>
          <Col md={3} sm={12}>
            {/*
              task_flow_name == ""
              <div>
              <h4 for="exampleEmail">Task Flow Name</h4>
              <Input bsSize="sm" type="text" placeholder={featchData?.data[0].task_flow_name} value={featchData?.data[0].task_flow_name} />
            </div>
          */}

            <div>
              <p htmlFor="exampleEmail">Task Flow Name</p>
              {/* ================================================================================== */}

              <Input type="select" name="taskflowlist" bsSize="sm" className="taskflowlist" style={{ marginBottom: "3%" }} onChange={(e) => handleTaskFlowId(e)}>
                <option defaultValue>select</option>
                {taskFlowList?.length > 0 && taskFlowList.map((val, index) =>
                  <option id={val?.task_flow_id} key={val?.task_flow_id}>{val?.task_flow_name} </option>
                )
                }
              </Input>
              {/* ==================================================================================== */}
              {/* <Input bsSize="sm" type="text" defaultValue={featchData?.data[0].task_flow_name} onChange={e => setTaskFlowName(e.target.value)} /> */}
              <Input bsSize="sm" type="text" value={taskFlow} onChange={e => setTaskFlow(e.target.value)} />
            </div>
            <Card style={{ marginTop: "15px" }}>
              <CardBody style={{ overflowY: "scroll", height: "50vh" }}>

                <Stepper orientation="vertical" style={{ padding: "0" }}>

                  {/* {taskFlowsStep?.length >= 1 && taskFlowsStep.map((val, index) =>
                    <Step key={index}>
                      <StepLabel StepIconComponent={QontoStepIcon}>{val.Label}</StepLabel>
                    </Step>
                  )
                  } */}

                  {/* ==============================================
                   */}

                  {taskFlowsStep?.length >= 1 && taskFlowsStep.map((val, index) =>
                    <Step key={index}>
                      <StepLabel StepIconComponent={QontoStepIcon}>{val.Label}</StepLabel>
                    </Step>
                  )
                  }

                </Stepper>

              </CardBody>
            </Card>
          </Col>
          <Col md={9} sm={12}>
            <Card>
              <CardBody style={{ padding: "3%", textAlign: "start" }}>
                <div className="d-flex bd-highlight" style={{ marginTop: "-2%" }}>
                  <div className="p-2 flex-fill bd-highlight" style={{ display: "inline-flex", alignItems: "center" }}>
                    {/* <FormGroup check>
                      <Label check>
                        <Input type="radio" name="radio1" value={false} onClick={showDefalut} defaultChecked />
                        Default
                      </Label>
                    </FormGroup> */}

                    {/* <FormGroup style={{ marginLeft: "10px" }} check>
                      <Label check>
                        <Input type="radio" name="radio1" value={false} onClick={showCoustmize} />
                        Customized
                      </Label>
                    </FormGroup> */}
                    {/* {coustmize ? */}
                    <FormGroup>
                      {/* <Label for="template" >Template</Label> */}
                      <p>Template</p>
                    </FormGroup>
                    <div className=" w-50 bd-highlight" style={{ marginLeft: "15px" }}>

                      <Select
                        options={optionsSize}
                        onChange={handleChange}
                        issearchable
                        bsSize="sm"
                      />

                    </div>
                    {/* : null} */}
                  </div>
                  {/* <div className=" flex-fill bd-highlight" style={{ display: "flex", marginLeft: "15px", justifyContent: "end", alignItems: "center", paddingTop: "16px" }}>
                    <Button outline bsSize="sm" onClick={() => goEditPage(template)}>Edit</Button>
                  </div> */}
                </div>

                <div className="d-flex bd-highlight" style={{ marginTop: "5%" }}>
                  <div className="flex-grow-1 bd-highlight">

                  </div>
                  {/* <div className="bd-highlight">
                    <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                      <label style={{ marginRight: "2%" }}>Estimated time: </label>
                      <Input type="number" style={{ width: "18%", alignItems: "center" }} onChange={(e) => setTime(e.target.value)} />
                      <label style={{ marginLeft: "2%" }}> Hours</label>
                    </div>
                  </div> */}
                </div>

                <form id="fb-render" className="fb-render"></form>

                {/*
                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <h2 className="page-title">Default Template</h2>
                                    <div>
                                        <label>Estimated time:<span className="text-muted"> 8.00 </span> Hours</label>
                                        <br></br>
                                        <label>Total time</label>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <CardText style={{ marginBottom: "15px" }}><strong> Description</strong></CardText>
                                </div>
                                <CardText style={{ marginTop: "-2%" }} className="text-muted">Many novice writers tend to make a sharp distinction between content and style, thinking that a paper can be strong in one and weak in the other, but focusing on organization shows how content and style converge in deliberative academic writing. Your professors will view even the most elegant prose as rambling and tedious if there isn’t a careful, coherent argument to give the text meaning. Paragraphs are the “stuff ” of academic writing and, thus, worth our attention here.</CardText>
                                <CardText tag="h5" style={{ marginTop: '10px' }} ><strong>Remarks</strong></CardText>
                                <Input rows="3" type="textarea" style={{ marginTop: "3%" }} />
*/}

                <h5 style={{ marginTop: "5%" }}>Worked By</h5>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ marginTop: "1%", display: "flex", justifyContent: "start" }}>
                    <CardImg bsSize="sm" src={userAuth?.Image} style={{ width: '53px', height: "53px", borderRadius: "50%", marginTop: '2%' }} />
                    <div>
                      <CardText style={{ padding: "5px" }}><p>{data?.first_name} {data?.last_name}</p></CardText>
                      {/* <label className="text-muted">role</label><br></br> */}
                      <label style={{ margin: "0" }}>10/08/2021<label style={{ marginLeft: "15px" }}>12.00PM</label></label>
                    </div>
                  </div>
                  {/* <div style={{ display: "inline-flex", width: "auto", position: "relative", float: "right" }}>
                    <Button style={{ marginRight: "5px", width: "120px", height: "35px" }} color="secondary" bsSize="sm">start timer</Button>
                    <h3>00 : 00</h3>
                    <Button style={{ marginLeft: "5px", width: "120px", height: "35px" }} color="secondary" bsSize="sm">stop timer</Button>
                  </div> */}

                  <div className="bd-highlight">
                    <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                      <label style={{ marginRight: "2%" }}>Estimated time: </label>
                      <Input type="number" style={{ width: "18%", alignItems: "center" }} onChange={(e) => setTime(e.target.value)} />
                      <label style={{ marginLeft: "2%" }}> Hours</label>
                    </div>
                  </div>
                </div>


                {
                  taskFlow.length ?
                    <ButtonToolbar style={{ display: "inline-flex", width: "auto", position: "relative", float: "right" }}>
                      <Button color="success" onClick={handelSubmit}>Save</Button>
                    </ButtonToolbar> : <ButtonToolbar style={{ display: "inline-flex", width: "auto", position: "relative", float: "right" }}>
                      <Button color="success" disabled>Save</Button>
                    </ButtonToolbar>
                }

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <Toast />
    </>
  )
}

export default AddNewTaskFlow
