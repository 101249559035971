import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import axios from 'axios';
import config from '../../../../config/index';
import { toast } from 'react-toastify';
import { showError, showSuccess } from "../../../../shared/components/Toast/Toast";
import 'react-toastify/dist/ReactToastify.css';



const AddImprint = ({ isOpen, newImp }) => {
    const [imprintName, setImprintName] = useState('');
    const [imprintError, setImpringError] = useState("");
    const usernameRegex = /^[a-zA-Z ]*$/;
    let userInfo = localStorage.getItem("token");

    const handlesave = () => {
        if (imprintName.length === 0) {
            setImpringError("imprint Should Not Be Blank");
        } else if (!usernameRegex.test(imprintName)) {
            setImpringError("imprint name accept only character");
        } else if (imprintName.length > 1) {
            setImpringError("");
        }
        if (!imprintError && imprintName) {
            try {
                var data = JSON.stringify({
                    "imprints": imprintName
                });
                var confi = {
                    method: 'post',
                    url: `${config.apiBaseUrl}imprint_list`,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + userInfo
                    },
                    data: data
                };
                axios(confi)
                    .then((response) => {
                        if (response.data.status === 1) {
                            showSuccess('imprint Added Successfully', toast);
                            newImp()
                        } else if (response.data.status === 0) {
                            showError('This imprint name already exists', toast);
                            newImp()
                        }
                    })
            } catch (error) {
                console.log(error);
            }
        }
    }


    return (
        <>
            <Modal className="model-content" isOpen={isOpen} >
                <div className="modal-content" style={{ display: "flex", alignItems: "flex-end" }}>
                    <CloseCircleIcon style={{ color: "#8D9CA6" }} onClick={newImp} />
                </div>
                <ModalHeader style={{ borderBottom: "0" }} className="d-flex flex-row bd-highlight" >ADD NEW IMPRINTS</ModalHeader>
                <ModalBody>
                    <label style={{ display: "flex", alignItems: "flex-start" }}>Enter Imprint</label>
                    <Input type="text"
                        onChange={(e) => {
                            setImprintName(e.target.value);
                            if (e.target.value.length === 0) {
                                setImpringError("imprint Should Not Be Blank");
                            } else if (!usernameRegex.test(e.target.value)) {
                                setImpringError("imprint name accept only character");
                            } else if (e.target.value.length > 1) {
                                setImpringError("");
                            }
                        }}
                    />
                    <p style={{ color: 'red' }}>{imprintError}</p>
                </ModalBody>
                <div className="d-flex flex-row-reverse bd-highlight">
                    <Button style={{ width: "20%", marginRight: "3%", backgroundColor: "#76CA4C" }} color="success" size="sm" onClick={handlesave}>Save</Button>
                </div>
            </Modal>
        </>
    )
}

export default AddImprint;
