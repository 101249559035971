import React, { useState, useEffect } from 'react';
import {
    Card, CardBody,
    CardTitle, Col, Badge
} from 'reactstrap';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import config from '../../../../../config/index';

const NewCard = () => {

    const [newCardData, setNewCardData] = useState([])

    const history = useHistory();
    const showProposal = (proposal_id) => {
        history.push({
            pathname: '/proposal_details',
            search: "?" + new URLSearchParams({ proposal_id: proposal_id }).toString(),
            state: { proposal_id }
        })
    }


    useEffect(() => {
        var confi = {
            method: 'get',
            url: `${config.apiBaseUrl}get/newproposal`,
            headers: {}
        };

        axios(confi)
            .then(async (response) => {
                setNewCardData(response.data?.data.map((response) => {
                    let val = {};
                    try {
                        val = JSON.parse(response.praposal_data);
                        let date = response.created_date ? new Date(response.created_date) : "";
                        let mainDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
                        val["date"] = mainDate;
                        val["time"] = response.time;
                        val["proposal_id"] = response.proposal_id
                        return val
                    } catch (e) {
                        return {}
                    }
                }));
            })
            .catch(function (error) {
                console.log(error);
            });
    }, []);

    return (
        <>{newCardData.length > 0 ? newCardData.map((val, index) =>

            <Col key={index} sm="3" style={{ marginTop: "5%" }}>
                <Card style={{ backgroundColor: "#FFEDED", cursor: "pointer" }} onClick={() => showProposal(val.proposal_id)}>
                    <CardBody style={{ backgroundColor: "#FFEDED", height: "33%" }}>
                        <div className="d-flex justify-content-between mb-1">
                            <div>
                                <span className="mr-1">{val.imprint_style}</span>
                            </div>
                            <Badge color="success" className="p-1">New</Badge>
                        </div>
                        <CardTitle tag="h5" className="font-weight-bold">Sacred Actions</CardTitle>
                        <div className="d-flex flex-column mb-2">
                            <label className="text-muted mb-0">Author</label>
                            <span className="font-weight-bold"></span><b>{val.name}</b>
                        </div>
                        <div className="d-flex flex-column mb-2">
                            <label className="text-muted mb-0">Current Status</label>
                            <span className="font-weight-bold">Check In</span>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                            <div className="d-flex flex-column">
                                <label className="text-muted mb-0">Date</label>
                                <b>{val.date}</b>
                            </div>
                            <div className="d-flex flex-column">
                                <label className="text-muted mb-0">Time</label>
                                <b>{val.time}</b>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        ) :
            <h4 style={{ paddingTop: "20px" }}>No Data Found</h4>}</>
    )
}

export default NewCard
