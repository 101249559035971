const config = {};
config.apiBaseUrl = 'https://www.schifferintranet.com:3000/api/'
// config.apiBaseUrl = 'https://c46a-103-239-87-226.ngrok.io/api/'
config.ENDPOINT = 'https://192.168.1.203:8000';

// ************ CLIENT SIDE IP'S ***************//
// config.apiBaseUrl='https://192.168.1.203:3000/api/';
// config.ENDPOINT = 'https://192.168.1.203:8000';
// **********************************************

export default config;