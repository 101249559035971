import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import AnnouncementCreate from "./components/AnnouncementCreate"

const Announcements = () => {

    return (
        <Container>
            <Row>
                <Col>
                    <AnnouncementCreate />
                </Col>
            </Row>
        </Container>
    );
};

export default Announcements;