import React, { useState } from 'react';
import {
    Card, CardBody, Row, Col, Container, FormGroup, Button, CardImg, CardText, Input
} from 'reactstrap';
import FormBuilder from './jQuery';
import jwt_decode from "jwt-decode";

const CreateNewteamplates = () => {


    const [time, setTime] = useState('');
    let userInfo = localStorage.getItem("token");
    var JWTtoken = userInfo;
    var decoded = jwt_decode(JWTtoken);
    let userFullInfo = (decoded);
    let allInfo = [userFullInfo];
    let data = allInfo[0].id
    let user_Info = localStorage.getItem("authuser");

    let userAuth = JSON.parse(user_Info);


    return (
        <>
            <div>
                <Container className="container">
                    <Card>
                        <CardBody>
                            <Col md={12} lg={12}>
                                <FormGroup>
                                    <div style={{ width: "auto", position: "relative", float: "left" }}>
                                        <span className="text-muted" style={{ fontWeight: "bold", fontSize: "15px" }}>Select template type: Default</span>
                                    </div>
                                    <div style={{ display: "inline-flex", width: "auto", position: "relative", float: "right" }}>
                                        {/* <span className="text-muted">Estimates Time 00 : 00 Hours</span><br></br> */}

                                    </div>
                                </FormGroup>
                                <FormBuilder />
                                <Row />
                                <FormGroup className="d-flex">
                                    {/*<Col md={3} sm={12}>
                                        <Input placeholder="Enter Template Title" rows="3" type="textarea" />
                                        <br></br>
                                        <Input placeholder="Enter Template Description" rows="5" type="textarea" />
                                    </Col>*/}
                                </FormGroup>
                                <Row />
                                {/* <span className="text-muted"><h5>Worked By</h5></span> */}
                                <FormGroup style={{ marginTop: "39px" }}>
                                    {/* <div style={{ width: "auto", position: "relative", float: "left" }}>
                                        <div style={{ display: "inline-flex" }}>
                                            <AccountCircleIcon style={{ height: "48px", width: "48px" }} />

                                            <span className="text-muted"><h4>
                                                {data?.first_name} {data?.last_name}</h4></span>
                                        </div>
                                    </div> */}

                                    {/* <div style={{ display:"inline-flex", width: "auto", position: "relative", float: "right"}}>
                                        <Button style={{marginRight:"5px"}} color="secondary" size="sm">start timer</Button>
                                        <h3>00 : 00</h3>
                                        <Button style={{marginLeft:"5px"}} color="secondary" size="sm">stop timer</Button>
                                    </div> */}

                                    {/* ===================================================================== */}
                                    <h5 style={{ marginTop: "5%" }}>Worked By</h5>
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <div style={{ marginTop: "1%", display: "flex", justifyContent: "start" }}>
                                            <CardImg bssize="sm" src={userAuth?.Image} style={{ width: '53px', height: "53px", borderRadius: "50%", marginTop: '2%' }} />
                                            <div>
                                                <CardText style={{ padding: "5px" }}><h4>{data?.first_name} {data?.last_name}</h4></CardText>
                                                {/* <label className="text-muted">role</label><br></br> */}
                                                <label style={{ margin: "0" }}>10/08/2021<label style={{ marginLeft: "15px" }}>12.00PM</label></label>
                                            </div>
                                        </div>
                                        {/* 
                                        <div style={{ display: "inline-flex", width: "auto", position: "relative", float: "right" }}>
                                            <Button style={{ marginRight: "5px", width: "120px", height: "35px" }} color="secondary" bsSize="sm">start timer</Button>
                                            <h3>00 : 00</h3>
                                            <Button style={{ marginLeft: "5px", width: "120px", height: "35px" }} color="secondary" bsSize="sm">stop timer</Button>
                                        </div> 
                                    */}

                                        <div className="bd-highlight">
                                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                <label style={{ marginRight: "2%" }}>Estimated time: </label>
                                                <Input type="number" style={{ width: "18%", alignItems: "center" }} onChange={(e) => setTime(e.target.value)} />
                                                <label style={{ marginLeft: "2%" }}> Hours</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* ========================================================= */}
                                </FormGroup>
                            </Col>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </>
    )
}

export default CreateNewteamplates
