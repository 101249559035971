import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import AccountIcon from "mdi-react/AccountIcon";
import BullhornOutlineIcon from "mdi-react/BullhornOutlineIcon";
import NoteTextIcon from "mdi-react/NoteTextIcon";
import ClipboardFlowOutlineIcon from "mdi-react/ClipboardFlowOutlineIcon";
import { useHistory } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";

const SettingsMenu = () => {
  let history = useHistory();

  return (
    <>
      <div className="btn-group--icons d-flex align-items-center">
        <IconButton aria-label="backward">
          <ArrowLeftIcon onClick={() => history.goBack()} />
        </IconButton>
        <h4 className="ml-2 font-weight-bold">SETTINGS
        </h4>
      </div>
      <Card>
        <CardBody>
          <Container style={{ textAlign: "center" }}>
            <Row>
              <Col md={3}>
                <NoteTextIcon
                  size={48}
                  onClick={() => history.push("/templates")}
                />

                <h6>Templates</h6>
              </Col>
              <Col md={3}>
                <ClipboardFlowOutlineIcon
                  size={48}
                  onClick={() => history.push("/task-flows")}
                />
                <h6>Task Flows</h6>
              </Col>
              <Col md={3}>
                <AccountIcon
                  size={48}
                  onClick={() => history.push("/user_management")}
                />

                <h6>User Management</h6>
              </Col>
              <Col md={3}>
                <BullhornOutlineIcon
                  size={48}
                  onClick={() => history.push("/announcements")}
                />
                <h6>Announcements</h6>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </>
  );
};

export default SettingsMenu;
