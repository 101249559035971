import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from 'i18next';
import 'bootstrap/dist/css/bootstrap.css';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import '../../scss/app.scss';
import { LoadScript } from '@react-google-maps/api';
import firebase from 'firebase/app';
import 'firebase/auth';
import Auth0Provider from '@/shared/components/auth/withAuth0';
import Router from './Router';
import store from './store';
import ScrollToTop from './ScrollToTop';
import { config as i18nextConfig } from '../../translations';
import firebaseConfig from '../../config/firebase';
import auth0Config from '../../config/auth0';
import io from "socket.io-client";
import config from '../../config';

i18n.init(i18nextConfig);

const ThemeComponent = ({ children, themeName }) => {
  const theme = createTheme({
    palette: {
      type: themeName === 'theme-dark' ? 'dark' : 'light',
    },
  });

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

ThemeComponent.propTypes = {
  children: PropTypes.shape().isRequired,
  themeName: PropTypes.string.isRequired,
};

const ConnectedThemeComponent = connect(state => ({ themeName: state.theme.className }))(ThemeComponent);
const Announcement = createContext();
const App = () => {
 
  const [announcement, setAnnouncement] = useState([]);
  const ENDPOINT = config.ENDPOINT;
  const socket = io(ENDPOINT);

  const handleNotify = () => {
    console.log("socket===>", socket);
    socket.emit("keymessage", {
      id: "",
      name: "",
      discription: "",
      role_no: "",
      start_date: "",
      end_date: ""
    });
  }


  useEffect(() => {
    firebase.initializeApp(firebaseConfig);
    handleNotify();
    socket.on("keymessage", (payload) => {
      // console.log("notification===>");
      payload.map((items) => {
        console.log("payload", items);
        let date = items.start_date ? new Date(items.start_date) : ""
        if (date !== "") {
          let StartDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
          items['StartDate'] = StartDate;
        }
        let end = items.end_date ? new Date(items.end_date) : "";
        if (end !== "") {
          let EndDate = ((end.getMonth() > 8) ? (end.getMonth() + 1) : ('0' + (end.getMonth() + 1))) + '/' + ((end.getDate() > 9) ? end.getDate() : ('0' + end.getDate())) + '/' + end.getFullYear()
          items['EndDate'] = EndDate;
        }
      })
      setAnnouncement(payload)
      console.log("payload", payload);
      // setNotification(payload.reverse())
    });
  }, []);


  const onRedirectCallbackAuth0 = (appState) => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname,
    );
  };

  return (
    <Provider store={store}>
      <Auth0Provider
        domain={auth0Config.domain}
        clientId={auth0Config.clientId}
        redirectUri={`${window.location.origin}/schiffer/online_marketing_dashboard`}
        returnTo={`${window.location.origin}/schiffer/online_marketing_dashboard`}
        onRedirectCallback={onRedirectCallbackAuth0}
      >
        <Announcement.Provider value={announcement}>
          <HashRouter basename="/schiffer">
            <I18nextProvider i18n={i18n}>
              <LoadScript
                googleMapsApiKey="" /* Paste your Google Maps Api Key here */
              >
                <ScrollToTop>
                  <ConnectedThemeComponent>
                    <Router />
                  </ConnectedThemeComponent>
                </ScrollToTop>
              </LoadScript>
            </I18nextProvider>
          </HashRouter>
        </Announcement.Provider>
      </Auth0Provider>
    </Provider>
  );
};

export default App;
export { Announcement };