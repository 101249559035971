import React, { useState, useEffect, useContext } from "react";
import ArrowLeftIcon from "mdi-react/ArrowLeftIcon";
import { useHistory } from "react-router-dom";
import { Announcement } from "../../../../containers/App/App";
import axios from 'axios';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Checkbox from '@mui/material/Checkbox';
import DatePicker from "react-datepicker";
import IconButton from "@material-ui/core/IconButton";
import config from '../../../../config/index';
import io from "socket.io-client";

let socket;

const AnnouncementCreate = () => {
  let history = useHistory();
  const [name, setName] = useState('');
  const [discription, setDiscription] = useState('');
  const [endDate, setEndDate] = useState(new Date());
  const announceData = useContext(Announcement);
  const Auth = localStorage.getItem("authuser");
  const UserData = JSON.parse(Auth);
  console.log(UserData);


  const ENDPOINT = config.ENDPOINT;
  socket = io(ENDPOINT);
  const startDate = new Date();
  const [rolesData, setrolesData] = useState([]);
  const [notification, setNotification] = useState('');
  let [roleList, setRoleList] = useState([]);
  let userInfo = localStorage.getItem("token");

  //const ENDPOINT = config.ENDPOINT;
  //socket = io(ENDPOINT);



  useEffect(() => {
    //handleNotify()
    //socket = io(ENDPOINT);
    roleAdd()
    // socket.on("keymessage", (payload) => {
    //   console.log(socket);
    //   payload.map((items) => {
    //     let date = items.start_date ? new Date(items.start_date) : ""
    //     if (date !== "") {
    //       let StartDate = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
    //       items['StartDate'] = StartDate;
    //     }
    //     let end = items.end_date ? new Date(items.end_date) : "";
    //     if (end !== "") {
    //       let EndDate = ((end.getMonth() > 8) ? (end.getMonth() + 1) : ('0' + (end.getMonth() + 1))) + '/' + ((end.getDate() > 9) ? end.getDate() : ('0' + end.getDate())) + '/' + end.getFullYear()
    //       items['EndDate'] = EndDate;
    //     }
    //   })
    //   console.log("payload",payload);
    //   setNotification(payload.reverse())
    // });
  }, []);



  const roleAdd = () => {
    try {
      var conf = {
        method: 'get',
        url: `${config.apiBaseUrl}get/all_role_list`,
        headers: {
          'Authorization': "Bearer " + userInfo
        }
      };
      axios(conf)
        .then(async (response) => {
          let temp = {
            role_id: "All",
            role_name: "All"
          }
          response.data.Data.unshift(temp);
          let mainData = response.data.Data.map((items) => {
            items['ischecked'] = false;
            return items;
          })
          await setrolesData(mainData);
        })
    } catch (error) {
      console.log(error);
    };
  }


  const Changechecked = async (event) => {
    if (event.target.id === 'All' && event.target.checked) {
      let AllMainData = rolesData.map((items) => {
        items['ischecked'] = true;
        roleList.push(items.role_id)
        return items;
      })
      setrolesData(AllMainData)
      rolesData.map((items) => {
        return items.role_id
      }).filter((items) => items !== 'All');
    } else if (event.target.id === 'All' && !event.target.checked) {
      let UnmainData = rolesData.map((items) => {
        items['ischecked'] = false;
        setRoleList([])
        return items;
      })
      setrolesData(UnmainData)
    } else {
      let UnmainData = rolesData.map((items) => {
        return items;
      })
      setrolesData(UnmainData)
    }
    if (event.target.id !== 'All') {
      rolesData.forEach((items) => {
        if (items.role_id == event.target.id && event.target.checked === true) {
          rolesData[items.role_id].ischecked = true;
          roleList.push(items.role_id)
        } else if (items.role_id == event.target.id && event.target.checked === false) {
          rolesData[items.role_id].ischecked = false;
          setRoleList(roleList.filter(item => item != items.role_id))
        }
      })
    }
    // console.log('rolesData :- ', rolesData)
    // console.log("roleList", roleList)
    roleList.filter(item => item.role_id === 'All')
    // console.log("roleListShift", roleList)
  }


  const handleNotify = () => {
    //console.log("socket===>", UserData?.user_id, "name===>", name, "discription===>", discription, "roleList===>", roleList, "startDate===>", startDate, "endDate===>", endDate);
    socket.emit("keymessage", {
      id: UserData?.user_id,
      name: name,
      discription: discription,
      role_no: roleList,
      start_date: startDate,
      end_date: endDate
    });
  }



  return (
    <>
      <div className="btn-group--icons d-flex align-items-center">
        <IconButton aria-label="backward">
          <ArrowLeftIcon onClick={() => history.goBack()} />
        </IconButton>
        <h3 className="font-weight-bold">ANNOUNCEMENTS</h3>
      </div>
      <div>
        <Container>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h5 style={{ marginBottom: "5%" }} className="font-weight-bold">ANNOUNCEMENT DETAIL</h5>
                  <Form>
                    <FormGroup>
                      <Label for="exampleEmail">Name</Label>
                      <Input
                        onChange={e => setName(e.target.value)}
                        bsSize="sm"
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Name"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="exampleText">Title Description</Label>
                      <Input
                        onChange={e => setDiscription(e.target.value)}
                        bsSize="sm"
                        rows="4"
                        type="textarea"
                        name="description"
                        id="description"
                        placeholder="Title Description"
                      />
                    </FormGroup>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label for="exampleText">Start date</Label>
                          <div className="text-muted">{startDate.toLocaleDateString('en-US')}</div>
                        </FormGroup>
                      </Col>
                      <Col>
                        <FormGroup>
                          <Label for="exampleText">End Date</Label>
                          <div>
                            <DatePicker
                              selected={endDate}
                              onChange={(date) => setEndDate(date)}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <h5 className="font-weight-bold">ANNOUNCEMENT VISIBLE TO</h5>
                    <Row>
                      {rolesData.length === 0 ? <h5 style={{ textAlign: "center" }}> No Data fonund </h5> : rolesData?.length && rolesData.map((val, index) =>
                        <Col >
                          <div key={index}>
                            <label style={{ width: "12vw" }} key={index}>{val.ischecked} {val.role_name}</label>
                            <Checkbox name="isCurrent" id={val.role_id} color="default" checked={val.ischecked} onChange={Changechecked} />
                          </div>
                        </Col>
                      )}
                    </Row>
                    {/* { discription.length === 0 || roleList.length === 0 || name.length === 0 ?  
                    <Button style={{ float: "right" }} size="sm" disabled>Post</Button>
                  :  <Button style={{ float: "right" }} className="btn btn-success" onClick={handleNotify} size="sm" >Post</Button>
                  } */}
                    <Button style={{ float: "right" }} className="btn " color="success" onClick={handleNotify} size="sm" >Post</Button>
                  </Form>
                </CardBody>
              </Card>
            </Col>
            <Col>
              <Card>
                <CardBody style={{ overflowY: "scroll", height: "50vh" }}>
                  <h5 style={{ marginBottom: "8%" }} className="font-weight-bold">
                    CURRENT ANNOUNCEMENTS
                  </h5>
                  {/* <div className={"chat_contact"}> */}
                  {announceData.length >= 1 && announceData.map((val, index) => (
                    <div className="border-bottom" key={index}>
                      <div>
                        {val.title_description}
                      </div>
                      <div key={index} className="d-flex justify-content-between laign-items-center">
                        <div className="d-flex" >
                          <div className="chat__contact-avatar" style={{ marginLeft: "0" }}>
                            <img
                              src="https://previews.aspirity.com/easydev/img/11.png"
                              alt="ava"
                            />
                          </div>
                          <div className="text-muted">{val.name}</div>
                        </div>

                        <span className="text-muted d-flex flex-column ">
                          <span>{val.StartDate}</span>
                          <span>{val.EndDate}</span>
                        </span>
                      </div>
                    </div>
                  ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default AnnouncementCreate;
