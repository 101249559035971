import React, { useState } from 'react';
import axios from 'axios';
import { NavLink, useHistory } from 'react-router-dom';
import Recaptcha from 'react-recaptcha';
import { Field, reduxForm, Form } from 'redux-form';
import { connect } from 'react-redux';
import EyeIcon from 'mdi-react/EyeIcon';
import KeyVariantIcon from 'mdi-react/KeyVariantIcon';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import config from '../../../config/index';
import { toast } from 'react-toastify';
import { Toast, showError, showSuccess } from '../../../shared/components/Toast/Toast'
import 'react-toastify/dist/ReactToastify.css';


const LogInForm = ({
  errorMessage, errorMsg, fieldUser, typeFieldUser
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [auth, setAuth] = useState(false);
  const [valid, setValid] = useState(false);
  const history = useHistory();

  async function handleSubmit(event) {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };
      let data = {
        email: email,
        password: password
      };
      event.preventDefault();
      await axios.post(`${config.apiBaseUrl}userlogin`, JSON.stringify(data),
        { headers: headers })
        .then(responce => {
          if (responce?.data.status === 1) {
            localStorage.setItem("token", responce.data.token);
            localStorage.setItem("authuser", JSON.stringify(responce.data));
            setValid(true);
            showSuccess('Login Successfully', toast);
            setTimeout(() => { history.push("/app_dashboard"); }, 1000);
          } else if (responce?.data.status === 0) {
            showError('Login Failed', toast);
          }
        });
    } catch (error) {
      showError(`${error.response?.data?.message}`, toast);
    }
  }



  const recaptchaLoaded = () => {
    //console.log("capchaloaded")
  }


  const recaptchaVerify = () => {
    setAuth(true);
    // console.log({auth})
  }


  const showPasswordToggle = () => {
    setShowPassword(!showPassword);
  };
  return (
    <Form className="form login-form" onSubmit={handleSubmit} autoComplete="off">
      <Alert
        color="danger"
        isOpen={!!errorMessage || !!errorMsg}
      >
        {errorMessage}
        {errorMsg}
      </Alert>
      <div className="form__form-group">
        <span className="form__form-group-label">{fieldUser}</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <AccountOutlineIcon />
          </div>
          <Field
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            autoComplete="off"
            name="username"
            component="input"
            type={typeFieldUser}
            placeholder={fieldUser}
            required
            className="input-without-border-radius"
          />
        </div>
      </div>
      <div className="form__form-group">
        <span className="form__form-group-label">Password</span>
        <div className="form__form-group-field">
          <div className="form__form-group-icon">
            <KeyVariantIcon />
          </div>
          <Field
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            component="input"
            type={showPassword ? 'text' : 'password'}
            placeholder="Password"
            required
            className="input-without-border-radius"
          />
          <button
            type="button"
            className={`form__form-group-button${showPassword ? ' active' : ''}`}
            onClick={showPasswordToggle}
          ><EyeIcon />
          </button>
          <div className="account__forgot-password">
            {/*<NavLink to="/reset_password">Forgot a password?</NavLink>*/}
          </div>
        </div>
      </div>
      {/* <div className="form__form-group">
        <div className="form__form-group form__form-group-field">
          <Field
            name={`remember_me-${form}`}
            component={renderCheckBoxField}
            label="Remember me"
          />
        </div>
      </div>*/}



      <Recaptcha
        style={{ marginTop: '10%' }}
        sitekey="6LcD7AkkAAAAAO65ch7P81iMt-GGJi0oRBwe9ghy"
        render="explicit"
        verifyCallback={recaptchaVerify}
        onloadCallback={recaptchaLoaded}
      />
      <div className="account__btns">
        {
          valid === false
            ? <Button className="account__btn" type="submit" color="primary">Sign In</Button>
            : (
              <NavLink className="account__btn btn btn-primary" to="/booking_dashboard">
                Sign In
              </NavLink>
            )
        }
        {/* <Button className="account__btn" type="submit" color="primary">Sign In</Button>*/}
        <NavLink className="btn btn-outline-primary account__btn" to="/register">Create
          Account
        </NavLink>
      </div>
      <Toast />
    </Form>
  );
};

LogInForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  form: PropTypes.string.isRequired,
};

LogInForm.defaultProps = {
  errorMessage: '',
  errorMsg: '',
  fieldUser: 'Username',
  typeFieldUser: 'text',
};

export default connect(state => ({
  errorMsg: state.user.error,
}))(reduxForm()(LogInForm));
