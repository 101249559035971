import React, { useState, useEffect } from 'react';
import config from '../../../../../config/index';
import { Card, CardBody, Col, Button, CardImg, ButtonToolbar, Container, Row } from 'reactstrap';
import Remark from './Remark';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import ProposalPdf from './ProposalPdf';
import axios from 'axios';
import { useHistory, useLocation } from "react-router-dom";
import Pdfimg from '../../../../../assets/Pdfimg.png';
import DownPdf from '../ProposalDetelis/DemoPdf';
import '../../style/ProposalDetelis.css';

const IndexProposal = (props) => {
    const [show, setShow] = useState(false);
    const [apiData, setApiData] = useState([]);
    let history = useHistory();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const proposal_id = params.get('proposal_id');
    let userInfo = localStorage.getItem("token");

    const backBtnHandle = (e) => {
        e.preventDefault();
        history.goBack();
    }


    useEffect(() => {
        try {
            let data = JSON.stringify({
                "proposal_id": proposal_id
            });
            let confi = {
                method: 'post',
                url: `${config.apiBaseUrl}get/singleproposalget`,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + userInfo
                },
                data: data
            };
            axios(confi)
                .then(async response => setApiData(await response.data.data))
        } catch (err) {
            console.log(err)
        }
    }, []);

    const praposalSingleData = apiData.map((res) => {
        let val = JSON.parse(res.praposal_data)
        val["date"] = res.dt1
        return val
    })



    const downLoadAll = () => {
        const file = praposalSingleData[0].attached[0]
        for (let i = 0; i < file.length; i++) {
            download(file[i], 'index.html')
        }
    }
    const download = (url, filename) => {
        fetch(url).then(function (t) {
            return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", filename);
                a.click();
            }
            );
        });
    }

    return (
        <>
            <div className="btn-group--icons">
                <ArrowLeftIcon style={{ width: "25px", borderRadius: "50%", backgroundColor: "#ffffff" }} onClick={backBtnHandle} />
                <span className="ml-2 font-weight-bold" style={{ color: " #3D3D3D", width: "138px", height: "32px" }}>NEW PROPOSALS {">"} {praposalSingleData[0]?.title}</span>
            </div>
            {apiData.length >= 1 ?
                <>
                    <Card key={proposal_id}>
                        <Container style={{ backgroundColor: "#FFFF", height: "10%", alignItems: 'center' }}>
                            <Row>
                                <Col md={6} lg={3} >
                                    <Card >
                                        <CardBody>
                                            <div className="typography-card" style={{ padding: "center" }}>

                                                <CardImg bsSize="sm" outline src={Pdfimg} style={{ flexshrink: '0', width: '60%', marginLeft: '20%', marginTop: "12%" }} />
                                                <ButtonToolbar>
                                                    <Button className="btn_hover" bsSize="sm" outline color="secondary" onClick={() => setShow(!show)}>{show ? " Exit PDF Preview " : "View PDF Preview"}</Button>
                                                </ButtonToolbar>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col md={6} lg={3}>
                                    <Card>
                                        <CardBody>
                                            <div className="typography-card" style={{ marginTop: "20%" }}>
                                                <h4>PROPOSAL</h4>
                                                <h4 className="subhead">Title Name: {praposalSingleData[0]?.title} </h4>
                                                <h4 className="subhead">Author Name: {praposalSingleData[0]?.name}</h4>
                                                <h4 className="subhead">Imprint: {praposalSingleData[0]?.imprint_style}</h4>
                                                <h4 className="subhead">Date: {apiData[0]?.dt1}</h4>

                                                <DownPdf data={praposalSingleData[0]} />

                                            </div>
                                            {praposalSingleData[0]?.attached?.length >= 1 ?
                                                <ButtonToolbar>
                                                    <Button bsSize="sm" outline color="secondary" onClick={downLoadAll}>Flie Download</Button>
                                                </ButtonToolbar>
                                                : null}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Remark proposal_id={proposal_id} />
                            </Row>
                        </Container>
                    </Card>
                    <Card>
                        {show ? <ProposalPdf data={praposalSingleData[0]} /> : null}
                    </Card>
                </>
                : null}
        </>
    )
}

export default IndexProposal
