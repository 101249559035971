import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, Input } from 'reactstrap';
import CloseCircleIcon from "mdi-react/CloseCircleIcon";
import axios from 'axios';
import config from '../../../../config/index';



const ModalView = ({ isOpen, newRole }) => {
  const [roleName, setRoleName] = useState('')
  const [roleError, setRoleError] = useState("");
  const usernameRegex = /^[a-zA-Z ]*$/;
  let userInfo = localStorage.getItem("token");

  const handlesave = () => {
    if (roleName.length === 0) {
      setRoleError("Role Should Not Be Blank");
    } else if (!usernameRegex.test(roleName)) {
      setRoleError("Role name accept only character");
    } else if (roleName.length > 1) {
      setRoleError("");
    }
    if (!roleError && roleName) {
      try {
        var data = JSON.stringify({
          "role_name": roleName
        });
        var confi = {
          method: 'post',
          url: `${config.apiBaseUrl}role_list`,
          headers: {
            'Content-Type': 'application/json',
            'Authorization': "Bearer " + userInfo
          },
          data: data
        };
        axios(confi)
          .then((response) => {
            newRole()
          })
      } catch (error) {
        console.log(error);
      }
    }

  }




  return (
    <>

      <Modal className="model-content" isOpen={isOpen} >
        <div className="modal-content" style={{ display: "flex", alignItems: "flex-end" }}>
          <CloseCircleIcon style={{ color: "#8D9CA6" }} onClick={newRole} />
        </div>
        <ModalHeader style={{ borderBottom: "0" }} className="d-flex flex-row bd-highlight" >ADD NEW ROLE</ModalHeader>
        <ModalBody>
          <label style={{ display: "flex", alignItems: "flex-start" }}>Enter Role</label>
          <Input type="text"
            onChange={(e) => {
              setRoleName(e.target.value);
              if (e.target.value.length === 0) {
                setRoleError("Role Should Not Be Blank");
              } else if (!usernameRegex.test(e.target.value)) {
                setRoleError("Role name accept only character");
              } else if (e.target.value.length > 1) {
                setRoleError("");
              }
            }}
          />
          <p style={{ color: 'red' }}>{roleError}</p>
        </ModalBody>
        <div className="d-flex flex-row-reverse bd-highlight">
          <Button style={{ width: "20%", marginRight: "3%", backgroundColor: "#76CA4C" }} color="success" size="sm" onClick={handlesave}>Save</Button>
        </div>
      </Modal>

    </>
  )
}

export default ModalView;
