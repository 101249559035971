import React, { useState, useEffect } from 'react';
// import TitleDetelis from './TitleDetelis';
// import SelectTaskFlow from './SelectTaskFlow';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from "@material-ui/core/IconButton";
import config from '../../../../../config/index';
import { toast } from 'react-toastify';
import { Toast, showError, showSuccess } from "../../../../../shared/components/Toast/Toast";
import 'react-toastify/dist/ReactToastify.css';
import {
  Container,
  Button,
  ButtonToolbar,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import ModalView from './Modal';
import './NewProjectFrom.css';
import jwt_decode from "jwt-decode";
import UserData from '../../../../../config/UserInfo';

const IndexCreateNewProject = (props) => {

  const projet_name = props.location.state?.projectName;

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [modal, setModal] = useState(false);
  const [taskFlowId, setTaskFlowId] = useState("1");
  const [taskFlowList, setTaskFlowList] = useState("");
  const [stepperList, setStepperList] = useState("");
  const [projectName, setProjectName] = useState(projet_name);
  const [titleName, setTitlename] = useState("");
  const [ownerList, setOwnerList] = useState("");
  const [owner, setOwner] = useState("");
  const [imprintList, setImprintList] = useState("");
  const [imprint, setImprint] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [titleDescription, setTitleDescription] = useState("");
  const [selectedList, setSelectedList] = useState([]);
  const [RespEan, setRespEan] = useState("");
  const [EanError, setEanError] = useState(null);
  const [EanErrorMsg, setEanErrorMsg] = useState("");
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [EanStatus, setEsnStatus] = useState("")


  // ----------------------------------------------------------
  let userInfo = localStorage.getItem("token");


  // const [eanList, seteanList] = useState("");
  const [eanno, setEanno] = useState("");


  if (userInfo) {
    var decoded = jwt_decode(userInfo);
  }
  let userFullInfo = (decoded);
  let allInfo = [userFullInfo]
  const DataUser = allInfo[0]?.id


  // ----------------------------------------------------------
  let history = useHistory();

  useEffect(() => {

    fetchStepper(taskFlowId)

    var confi = {
      method: 'get',
      url: (`${config.apiBaseUrl}get/owner_list`),
      headers: {
        'Authorization': "Bearer " + userInfo,
      }
    };

    axios(confi)
      .then(async (response) => {

        await setOwnerList(response.data.Data)
        if (ownerList !== undefined) {
          var conf = {
            method: 'get',
            url: (`${config.apiBaseUrl}get/all_imprint_list`),
            headers: {
              'Authorization': "Bearer " + userInfo
            }
          };
          axios(conf)
            .then(async (response) => {
              await setImprintList(response.data.Data)
              if (imprintList !== undefined) {
                selectTaskFlow()
              }
            })
            .catch(function (error) {
              console.log(error);
              if (error.response.status === 401) {
                showError('Invalid user', toast);
              }
            });
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }, [taskFlowId])


  const selectTaskFlow = () => {
    var con = {
      method: 'get',
      url: (`${config.apiBaseUrl}get/task_flow_list`),
      headers: {
        'Authorization': "Bearer " + userInfo
      }
    };
    axios(con)
      .then(async (response) => {
        await setTaskFlowList(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const fetchStepper = (taskFlowId) => {
    var data = JSON.stringify({
      "task_flow_id": taskFlowId
    });
    var cnfig = {
      method: 'post',
      url: (`${config.apiBaseUrl}get/task_flow_steps`),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + userInfo
      },
      data: data
    };
    axios(cnfig)
      .then(async response => {
        setStepperList(await response.data.Data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  const handleChangeEAN = (e) => {
    setEanno(e.target.value);

    var data = JSON.stringify({
      "ean": e.target.value
    });

    var configEan = {
      method: 'post',
      url: (`${config.apiBaseUrl}get/checkean`),
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(configEan)
      .then(function (response) {
        setRespEan(response.data?.message);
        // console.log(response.data.message.length)
        // console.log(response.data.message)
        if (response.data?.message == "[]") {
          setEanError(true);
        }
        if (response.data?.message?.length) {
          setEanError(false);
        }
      })
      .catch(function (error) {
        console.log(error);
      });



    // ========================================================



  }

  const validate = (projectName, eanno, titleName, owner, imprint, startDate, endDate, titleDescription, selectedList, EanStatus) => {
    const errors = {};

    if (!projectName) {
      errors.projectName = "Project name is required!";
    }

    if (!eanno) {
      errors.eanno = "select ENA number is required!";
    }

    if (!titleName) {
      errors.titleName = "Title Name is required!";
    }

    if (!owner) {
      errors.owner = "Select owner is required!";
    }

    if (!imprint) {
      errors.imprint = "Select imprint is required!";
    }

    if (!startDate) {
      errors.startDate = "Select startDate is required!";
    }

    if (!endDate) {
      errors.endDate = "Select endDate is required!";
    }

    if (!titleDescription) {
      errors.titleDescription = "titleDescription is required!";
    }

    if (!selectedList.length) {
      errors.selectedList = "select task flows is required!";
    }

    if (!EanStatus.length || EanStatus === 'select') {
      errors.EanStatus = "select EAN status is required!";
    }

    return errors;
  };

  useEffect(() => {
    if (RespEan != "[]" && EanError === false) {
      showError('This EAN no is already exist', toast)
      console.log("call")
    } else if (Object.keys(formErrors).length === 0 && isSubmit && RespEan == "[]") {
      // saveENA()
      create_project()
    }
  }, [formErrors]);

  //create project
  const create_project = () => {
    let List = selectedList?.toString().replaceAll(/[\[\]']+/g, "");
    var data = JSON.stringify({
      "ean_number": eanno,
      "task_flow_id": taskFlowId,
      "task_flowtemplate_ids": List,
      "project_name": projectName,
      "title_name": titleName,
      "owner": owner,
      "imprint": imprint,
      "start_date": startDate,
      "end_date": endDate,
      "title_description": titleDescription,
      "create_by": DataUser?.id
    });

    var newProjectSave = {
      method: 'post',
      url: (`${config.apiBaseUrl}post/createproject`),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + userInfo
      },
      data: data
    };

    axios(newProjectSave)
      .then(async response => {
        if (response.data.status === 1) {
          saveENA(response.data.data?.insertId)
        } else if (response.data.status === 0) {
          showError(response.data.message, toast);
        }
      })
      .catch(function (error) {
        console.log(JSON.stringify(error));
        console.log(error.response.status);
        if (error.response.status === 401) {
          showError('Invalid user', toast);
        }
      });
  }

  //save ENA for the intranet
  const saveENA = (insertId) => {
    // var data = JSON.stringify({
    //   "Title": titleName,
    //   "EAN": eanno
    // });
    // var config = {
    //   method: 'post',
    //   url: 'http://schifferintranet.igibeta.com/PPS/Books.ashx',
    //   headers: {
    //     'Authorization': 'Bearer 325afc5c-3cc9-4089-9c8c-bcb4add2d94e',
    //   },
    //   data: data
    // };
    // axios(config)
    //   .then(function (response) {
    //     if (response.data === "Success") {
    //       showSuccess('Project Created Successfully', toast);
    //       setTimeout(() => history.push({
    //         pathname: '/select_roles',
    //         search: "?" + new URLSearchParams({ Project_id: insertId }).toString()
    //       }), 1500);
    //     }
    //     if (response.data.includes("not unique")) {
    //       alert("EAN is not unique.")
    //     }
    //   })
    //   .catch(function (error) {
    //     console.log(error.response);
    //   });


    var data = JSON.stringify({
      "title": titleName,
      "ean": eanno,
      PrePostProdStatus: EanStatus
    });

    var configaddena = {
      method: 'post',
      url: (`${config.apiBaseUrl}post/addean`),
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    axios(configaddena)
      .then(function (response) {
        console.log(response.data);
        if (response.data?.message === "Success") {
          showSuccess('Project Created Successfully', toast);
          setTimeout(() => history.push({
            pathname: '/select_roles',
            search: "?" + new URLSearchParams({ Project_id: insertId }).toString()
          }), 1500);
        }
        if (response.data.message.includes("not unique")) {
          // alert("EAN is not unique.")
        }
      })
      .catch(function (error) {
        console.log(error);
      });


  }


  const handleSubmit = () => {
    setFormErrors(validate(projectName, eanno, titleName, owner, imprint, startDate, endDate, titleDescription, selectedList, EanStatus));
    setIsSubmit(true);

    // if (RespEan.length) {
    //   // alert("ean exist");
    // } else {
    // var data = JSON.stringify({
    //   "Title": "New Project Test1",
    //   "EAN": eanno
    // });
    // var config = {
    //   method: 'post',
    //   url: 'http://schifferintranet.igibeta.com/PPS/Books.ashx',
    //   headers: { 
    //     'Authorization': 'Bearer 325afc5c-3cc9-4089-9c8c-bcb4add2d94e', 
    //     'Content-Type': 'application/json'
    //   },
    //   data : data
    // };
    // axios(config)
    // .then(function (response) {
    //   console.log(JSON.stringify(response.data));
    // })
    // .catch(function (error) {
    //   console.log(error);
    // });
    // =============================================
    //====================>>>>>>>>>>>>>>>>>>>>>>
  }


  //console.log(owner);

  const handlechange = (e) => {
    setTaskFlowId(e.target.value);
    fetchStepper(e.target.value);
  }

  const handleAllChecked = (e) => {
    let stepper = stepperList
    stepper?.forEach(stepper => stepper.isChecked = e.target.checked)
    setStepperList(stepper)
  }

  const handleSelectAll = () => {
    setSelectedList(stepperList?.map((li) => li.task_flowtemplate_id));
    setIsCheckAll(!isCheckAll);
    setStepperList(stepperList?.map((val) => ({ ...val, active: !isCheckAll })));
  }


  const handleCheck = (id, e) => {
    stepperList.forEach((items) => {
      if (items.task_flowtemplate_id == e.target.id && e.target.checked === true) {
        selectedList.push(items.task_flowtemplate_id)
      } else if (items.task_flowtemplate_id == e.target.id && e.target.checked === false) {
        setSelectedList(selectedList.filter(item => item !== items.task_flowtemplate_id))
      }
    })


    const chekList = [...stepperList];
    // Find the checkbox that was clicked
    const stateToUpdate = chekList.find((val) => val.task_flowtemplate_id === id);
    stateToUpdate.active = !stateToUpdate.active;
    if (!stateToUpdate.active && isCheckAll) {
      handleSelectAll();
    }
    // Set the state
    setStepperList(chekList);

    // Logic for selectAll
    // 1. If ALL items are active (selected), selectAll -> true
    // 2. If even ONE item is NOT active (selected), selectAll -> false
    let flag = true;
    for (let i = 0; i < stepperList.length; i++) {
      flag = flag && stepperList[i].active;
    }
    if (flag && !isCheckAll) {
      handleSelectAll();
    }
  }

  const newTemplate = () => {
    setModal(!modal);
    selectTaskFlow()
    fetchStepper()
  }

  const changeOwner = (e) => {
    setOwner(e.target.value)
  }

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };


  const disableEndDate = () => {
    const today = new Date();
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0");
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <>
      {modal === true ? <ModalView style={{ borderRadius: "1%" }} isOpen={modal} newTemplate={newTemplate} list={selectedList} /> : null}
      <Container>
        <div className="btn-group--icons" style={{ display: "flex", justifyContent: "space-between" }}>
          <div className="btn-group--icons d-flex align-items-center">
            <IconButton aria-label="backward">
              <ArrowLeftIcon onClick={() => history.goBack()} />
            </IconButton>
            <h4 className="ml-2 font-weight-bold">CREATE NEW PROJECT</h4>
          </div>
          {/*
          <ButtonToolbar>
          <Button color="success" outline size="sm">Add New Title</Button>
        </ButtonToolbar>
         */}
        </div>
        <Row>
          <Col md={12}>
            <h5 style={{ marginTop: "-2%" }}>Project Name</h5>
            <Input style={{ width: "30%" }} size="sm" type="text" name="project_name" id="projectname" value={projet_name} onChange={(e) => setProjectName(e.target.value)} />
            <p className='text-danger'>{formErrors.projectName}</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={12} lg={12} xl={6}>
            <Form>
              <Card>
                <CardBody style={{ height: "100vh" }}>
                  <div className="card__title">
                    <h5 className="bold-text">TITLE DETAILS</h5>
                    <h5 className="subhead">
                    </h5>
                  </div>
                  {/* ----------------------------EAN NO Starts--------------------------------------------- */}
                  {/* <FormGroup>
                    <div>
                      <Label for="ean_no">EAN No</Label>
                      <Select
                        options={handleOptionsSize}
                        onChange={e => handleChangeEAN(e)}
                        bsSize="sm"
                        issearchable
                      />

                    </div>
                  </FormGroup> */}
                  {/* ======================================================================================== */}
                  <FormGroup>
                    <div>
                      <Label for="ean_no">EAN No</Label>
                      <Input
                        // value={eanno}
                        onChange={(e) => handleChangeEAN(e)}
                        type="text"
                        name="eanno"
                        id="eanno"
                        // placeholder="Enter EAN no"
                        size="sm"
                      />

                    </div>
                    <p className='text-danger'>{formErrors.eanno}</p>
                  </FormGroup>
                  <div style={{ width: "45%" }}>
                    <Label for="exampleSelect">EAN Status</Label>
                    <Input type="select" name="imprint" id="exampleSelect" size="sm" className="imprint_input" value={EanStatus} onChange={(e) => setEsnStatus(e.target.value)}>
                      <option defaultValue value={"select"}>select</option>
                      <option value={"PROPOSAL"}>PROPOSAL</option>
                      <option value={"ACQ"}>ACQ</option>
                      <option value={"CONTRACT"}>CONTRACT</option>
                      <option value={"ProjPlan"}>ProjPlan</option>
                      <option value={"AEHand"}>AEHand</option>
                    </Input>
                    <p className='text-danger'>{formErrors.EanStatus}</p>
                  </div>
                  {/* -------------------------------EAN NO Ends----------------------------------------------- */}
                  <FormGroup>
                    <Label for="titlename">Title Name</Label>
                    <Input size='sm' name="titlename" value={titleName} onChange={(e) => setTitlename(e.target.value)} />
                    <p className='text-danger'>{formErrors.titleName}</p>
                  </FormGroup>
                  <FormGroup className="d-flex" style={{ display: "flex", justifyContent: 'space-between' }}>
                    <div style={{ width: "45%" }}>
                      <Label for="exampleSelect">Owner</Label>
                      <Input type="select" name="id" bsSize="sm" placeholder="Select" className="owner_input" value={owner} onChange={changeOwner}>
                        <option defaultValue>select</option>
                        {ownerList?.length >= 1 && ownerList?.map((val, index) =>
                          <option key={index} value={val.id} >{val.first_name}</option>
                        )
                        }
                      </Input>
                      <p className='text-danger'>{formErrors.owner}</p>
                    </div>
                    <div style={{ width: "45%" }}>
                      <Label for="exampleSelect">Imprint</Label>
                      <Input type="select" name="imprint" id="exampleSelect" size="sm" className="imprint_input" value={imprint} onChange={(e) => setImprint(e.target.value)}>
                        <option defaultValue>select</option>
                        {imprintList?.length >= 1 && imprintList?.map((val, index) =>
                          <option key={index}>{val.imprints}</option>
                        )
                        }
                      </Input>
                      <p className='text-danger'>{formErrors.imprint}</p>
                    </div>
                  </FormGroup>
                  <FormGroup className="d-flex" style={{ display: "flex", justifyContent: 'space-between' }}>
                    <div style={{ width: "45%" }}>
                      <Label for="exampleDate">Start Date</Label>
                      <Input
                        value={startDate}
                        onChange={e => setStartDate(e.target.value)}
                        type="date"
                        name="date"
                        id="exampleDate"
                        placeholder="date placeholder"
                        size="sm"
                        min={disablePastDate()}
                      />
                      <p className='text-danger'>{formErrors.startDate}</p>
                    </div>
                    <div style={{ width: "45%" }}>
                      <Label for="exampleDate">End Date</Label>
                      <Input
                        value={endDate}
                        onChange={e => setEndDate(e.target.value)}
                        type="date"
                        name="date"
                        id="exampleDate"
                        placeholder="date placeholder"
                        size="sm"
                        min={disableEndDate()}
                      />
                      <p className='text-danger'>{formErrors.endDate}</p>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label for="exampleDate">Title Description</Label>
                    <Input type="textarea" rows="4" name="text" id="exampleText" value={titleDescription} onChange={e => setTitleDescription(e.target.value)} />
                    <p className='text-danger'>{formErrors.titleDescription}</p>
                  </FormGroup>
                </CardBody>
              </Card>
            </Form>
          </Col>
          <Col xs={12} md={12} lg={12} xl={6}>
            <Form>
              <Card>
                <CardBody style={{ height: "100vh" }} >
                  <FormGroup className="d-flex" style={{ display: "flex", justifyContent: 'space-between' }}>
                    <div className="card__title">
                      <h5 className="bold-text">SELECT TASK FLOW FOR THIS TITLE</h5>
                    </div>
                    <div>
                      <ButtonToolbar>
                        <Button className="newTemplateBtn" outline size="sm" onClick={newTemplate} >Save as New Template</Button>
                      </ButtonToolbar>
                    </div>
                  </FormGroup>
                  <FormGroup>
                    <Label for="titlename">Select Task Flow</Label>
                    <Input type="select" size='sm' onChange={e => handlechange(e)} onClick={handleAllChecked} >
                      <option value="" defaultValue>Schiffer Default Template</option>
                      {taskFlowList?.length >= 1 && taskFlowList?.map((val, index) =>
                        <option key={index} value={val.task_flow_id} >{val.task_flow_name}</option>
                      )
                      }
                    </Input>
                    <FormGroup className="d-flex flex-column" style={{ overflowY: "scroll", height: "52vh" }} >
                      <FormControlLabel
                        className="text-muted"
                        key="24"
                        control={
                          <Checkbox
                            color="default"
                            size="medium"
                            id="selectAll"
                            name="Select All"
                            onChange={handleSelectAll}
                            checked={isCheckAll === true ? true : false}
                          />
                        }
                        label="Select All"
                      />
                      {stepperList?.length >= 1 && stepperList?.map((val, index) =>
                        <FormControlLabel
                          className="text-muted"
                          key={index}
                          control={
                            <Checkbox
                              color="default"
                              size="medium"
                              id={val.task_flowtemplate_id}
                              name={val.Label}
                              // onClick={console.log()}
                              onClick={(e) => handleCheck(val.task_flowtemplate_id, e)}
                              checked={val?.active ? true : false}
                            />
                          }
                          label={val.Label}
                        />
                      )
                      }
                    </FormGroup>
                    <p className='text-danger'>{formErrors.selectedList}</p>
                  </FormGroup>
                  <ButtonToolbar className="justify-content-end">
                    <Button color="success" size="sm" onClick={handleSubmit}>Submit</Button>
                  </ButtonToolbar>
                </CardBody>
              </Card>
            </Form>
          </Col>
        </Row>
      </Container>
      <Toast />
    </>
  )
}

export default IndexCreateNewProject